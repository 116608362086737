import React from "react"
import {Title, TitleSizes} from "@patternfly/react-core"

import {DataList} from "@components/DataList"
import {Chip} from "@components/Chip"
import {RemotoInterfacesStatusBadge} from "./RemotoInterfacesStatusBadge"
import {useSiteManagementInterface} from "@hooks/useSiteManagementInterface"
import type {DataListItem} from "@components/DataList"

export interface RemotoInterfacesDynamicWanProps {
  siteId   : string;
  ifaceName: "wan1" | "wan2";
}

export function RemotoInterfacesDynamicWan({siteId, ifaceName}: RemotoInterfacesDynamicWanProps) {
  const iface = useSiteManagementInterface(siteId, ifaceName)

  if (iface === undefined || iface.usingStaticIp === true) return null

  return (
    <>
      <Title className="DataListTitle" headingLevel="h5" size={TitleSizes.lg}>{"WAN " + parseInt(ifaceName.replace(/[a-zA-Z]/g, ""))}</Title>
      <DataList items={toDataList(iface)} />
    </>
  )
}

function toDataList(item: any = {}): DataListItem[] {
  return [
    {key: "Estado", value: <RemotoInterfacesStatusBadge status={item?.status || "offline"} />},
    {key: "Modo", value: "IP Dinámica"},
    {key: "IP", value: item?.ip},
    {key: "IP Pública", value: item?.publicIp},
    {key: "Gateway", value: item?.gateway},
    {key: "Servidores DNS Estáticos", value: <>{item?.primaryDns && <Chip>{item?.primaryDns}</Chip>}{item?.secondaryDns && <Chip>{item?.secondaryDns}</Chip>}</>},
  ]
}