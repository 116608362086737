import React from "react"
import {useDispatch} from "react-redux"
import {Link, useParams} from "react-router-dom"
import {Button} from "@patternfly/react-core"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {ClienteLayout} from "@pages/Clientes/:id/ClienteLayout"
import {ClienteTabs} from "@pages/Clientes/:id/ClienteTabs"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {UsuariosTable} from "@pages/Usuarios/UsuariosTable"
import {clientsRead, clientsListUsers, clientsUpdateUsers} from "@redux/api"
import {useClient} from "@hooks/useClient"
import {useFuse} from "@hooks/useFuse"

const fuseOptions = {keys: ["name", "lastName", "email"]}

export const ClienteUsuarios: React.FC<{}> = () => {
  const [filter, setFilter]             = React.useState<string>("")
  const [removeUserId, setRemoveUserId] = React.useState<string|undefined>(undefined)
  const {id}                            = useParams<{id: string}>()
  const client                          = useClient(id)
  const clientsListUsersFlag            = useFlag("clientsListUsers")
  const clientsReadError                = useError("clientsRead")
  const clientsUpdateUsersFlag          = useFlag("clientsUpdateUsers")
  const dispatch                        = useDispatch()
  const fuse                            = useFuse(client && client.users ? client.users : [], filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (clientsReadError !== undefined) {
    return (
      <EmptyState title="Cliente no encontrado">
        <div>
          <p>No se ha encontrado un Cliente con el id {id}.</p>
          <br/>
          <Link to="/clientes">{"<< Volver a la lista de Clientes"}</Link>
        </div>
      </EmptyState>
    )
  }

  if (client === undefined) {
    return <Loader content="Obteniendo información del cliente..." />
  }

  return (
    <ClienteLayout item={client}>
      <ClienteTabs activeKey={"usuarios"}>
        {client.users === undefined
          ? <Loader content={`Actualizando la lista de Usuarios del Cliente: ${id}`} />
          : <>
              {removeUserId !== undefined &&
                <SmallModal onAccept={handleRemoveUser} onCancel={toggleRemoveUserModal}>
                  <p>¿Esta seguro que desea eliminar el acceso de este usuario al cliente <b>{client.name}</b>?</p>
                  <br/>
                  <small>El usuario no podrá volver a acceder a este cliente hasta que se restablezcan sus privilegios.</small>
                </SmallModal>
              }
              <Toolbar onChange={setFilter} value={filter}>
                <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={clientsListUsersFlag || clientsUpdateUsersFlag} style={{marginRight: "1rem"}}>
                  {clientsListUsersFlag || clientsUpdateUsersFlag ? "Actualizando..." : "Actualizar" }
                </Button>
                <Link to={`/clientes/${id}/agregar-usuarios`}>
                  <Button icon={<PlusIcon />} type="button" variant="primary">Agregar Usuarios</Button>
                </Link>
              </Toolbar>
              <UsuariosTable
                loading={client.users.length === 0 && clientsListUsersFlag}
                items={filter === "" ? client.users : fuse}
                onDelete={toggleRemoveUserModal}
              />
            </>
        }
      </ClienteTabs>
    </ClienteLayout>
  )
  /**
   * Actualiza la `network` y lista de `sites`.
   */
  function handleRefresh() {
    dispatch(clientsRead({id}))
    dispatch(clientsListUsers({id}))
  }
  /**
   * Abre o cierra el `modal` para confirmar la eliminación del `user` del `client`.
   */
  function toggleRemoveUserModal(id?: string) {
    setRemoveUserId(id)
  }
  /**
   * Envía el `request` para eliminar el `user` del `client`.
   */
  function handleRemoveUser() {
    if (client !== undefined && client.userIds !== undefined) {
      dispatch(clientsUpdateUsers({id: client.id, users: client.userIds.filter(userId => userId !== removeUserId)}))
    }
    toggleRemoveUserModal()
  }
}