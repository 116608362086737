import React from "react"
import {Link} from "react-router-dom"
import LinkIcon from "@patternfly/react-icons/dist/js/icons/link-icon"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Divider,
  Grid,
  GridItem,
  Flex,
  FlexItem,
} from "@patternfly/react-core";
import get from "lodash/get"

import "./RemotoLayout.css"

import type {ISite} from "@models"
import {RemotoStatusBadge} from "@pages/Remotos/RemotoStatusBadge";
import {useHasSingleNetwork} from "@hooks/useHasSingleNetwork"

export interface RemotoProps {
  item: ISite;
  className?: string;
}

export const RemotoLayout: React.FC<RemotoProps> = ({item, className = "", children}) => {
  const hasSingleNetwork = useHasSingleNetwork()

  return (
    <Grid hasGutter className={`Page RemotoLayout ${className}`}>
      <GridItem span={3} rowSpan={2}>
        <Card>
          <CardTitle>
            <Flex alignItems={{default: "alignItemsCenter"}}>
              <FlexItem className="RemotoLayout__title">{item.name || item.id}</FlexItem>
              <FlexItem><RemotoStatusBadge status={item.status || ""} /></FlexItem>
            </Flex>
          </CardTitle>
          <CardBody>
            <dt>ID de la Red:</dt>
            <dd><Link to={hasSingleNetwork ? "/redes" : `/redes/${item.networkId}`}>{item.networkId}</Link></dd>
            <dt>Meraki Net. ID:</dt>
            <dd>{item.merakiNetworkId}</dd>
            <dt>Modelo:</dt>
            <dd>{item.model}</dd>
            <dt>Dirección:</dt>
            <dd>{item.address}</dd>
            <dt>Número de Serie:</dt>
            <dd>{item.serial}</dd>
            <dt>MAC:</dt>
            <dd>{item.mac}</dd>
            <dt>Última modificación</dt>
            <dd>{get(item, "updatedAt") || get(item, "createdAt")}</dd>
            <Divider />
            <Button type="button" variant="link" icon={<LinkIcon />} iconPosition="left" style={{padding: 0}}>
              <a target="_blanck" href={`https://api.meraki.com/manage/dashboard/show?mac=${item.mac}`}>Meraki Dashboard</a>
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem span={9}>
      <Card>
        <CardBody style={{padding: 0}}>
          {children}
        </CardBody>
      </Card>
    </GridItem>
    </Grid>
  )
}

export default RemotoLayout