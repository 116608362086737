import React from "react"
import {Link} from "react-router-dom"
import {Button, Divider} from "@patternfly/react-core"
import WarningTriangleIcon from '@patternfly/react-icons/dist/js/icons/warning-triangle-icon';

import {EmptyState} from "@components/EmptyState"

const API_RATE_LIMIT_EXCEEDED_FOR_ORGANIZATION = "API rate limit exceeded for organization"

export interface ErrorStateProps {
  error: {type: string, message: string},
  title?: string;
  href?: string;
  linkText?: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({
  error,
  href,
  linkText = "<< Volver",
  title = "Error",
}) => {
  let {type, message} = error;

  if (message === API_RATE_LIMIT_EXCEEDED_FOR_ORGANIZATION) {
    type = "Límite de consultas a la API excedido"
    message = `
Se ha excedido la cantidad de consultas que se pueden realizar a la API.
Espere unos minutos y refresque la página.

Gracias por su comprensión.
`
  }

  return (
    <EmptyState title={title} icon={WarningTriangleIcon}>
      <div>
        <p><b>{type}</b></p>
        <p style={{fontFamily: "monospace"}}>
          {message}
        </p>
        <br/>
        {href !== undefined && <Link to={href}>{linkText}</Link>}
        <Divider />
        <Button type="button" variant="primary" onClick={handleReload}>Refrescar la página</Button>
      </div>
    </EmptyState>
  )
}

function handleReload() {
  window.location.reload()
}