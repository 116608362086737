import React from "react"
import {useSelector} from "react-redux"
import {Bullseye, Button} from "@patternfly/react-core"
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';

import {AdminRoot} from "./AdminRoot"
import {EmptyState} from "@components/EmptyState"
import {MultiNetworkRoot} from "./MultiNetworkRoot"
import {SingleNetworkRoot} from "./SingleNetworkRoot"
import {useIsAdmin} from "@hooks/useIsAdmin"
import {useUserNetworks} from "@hooks/useUserNetworks"

export const Root: React.FC<{}> = () => {
  const isAdmin = useIsAdmin()
  const networks  = useUserNetworks()

  // Si el usuario tiene el rol de administrador, le damos
  // acceso a todo el sitio.
  if (isAdmin) {
    return <AdminRoot />
  }

  if (networks.length === 1 ) {
    return <SingleNetworkRoot />
  }

  if (networks.length > 1) {
    return <MultiNetworkRoot />
  }

  return (
    <Bullseye>
      <EmptyState
        icon={ExclamationCircleIcon}
        title="Cuenta Inactiva">
          <p>No cuenta con redes cargados en su perfil.</p>
          <p>Contacte a su administrador de sistemas si cree que esto es un error</p>
          <br />
          <Button type="button" onClick={() => window.k.logout()}>Cerrar Sesión</Button>
      </EmptyState>
    </Bullseye>
  )
}