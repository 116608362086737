import _union from "lodash/union"
import {createSlice, createSelector, AnyAction} from "@reduxjs/toolkit"
import type {SliceCaseReducers} from "@reduxjs/toolkit"

import {merge, networksSelector as entitiesSelector} from "@redux/state/entities"
import {sitesSelector} from "@redux/state/sites"
import type {MetaPayloadAction} from "@redux/types"

const slice = createSlice<string[], SliceCaseReducers<string[]>>({
  name: "networks",
  initialState: [],
  reducers: {
    union(state, action) {
      return _union(state, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(merge, (state, action) => {
        if (action.payload && action.payload.entities && action.payload.entities.networks) {
          return _union(state, Array.isArray(action.payload.result) ? action.payload.result : [action.payload.result])
        }
        return state
      })
      .addMatcher(isNetworkDestroySuccessAction, (state, action) => {
        return state.filter(id => id !== action.meta.id)
      })
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {union} = slice.actions
/**
 * SELECTORS
 */
// Selecciona la lista de `network ids`.
export const idsSelector = (state: {networks: string[]}) => state.networks
// Selecciona la lista de `networks`.
export const networksSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ids.map(id => entities[id])
)
// Selecciona una única `network id`
export const networkIdSelector = (_: any, networkId: string) => networkId
// Selecciona una única `network`
export const networkSelector = createSelector(
  networkIdSelector,
  entitiesSelector,
  (networkId, entities) => entities[networkId]
)
// Selecciona los `sites` que corresponden a una `network`
export const networkSitesSelector = createSelector(
  networkIdSelector,
  sitesSelector,
  (networkId, sites) => sites.filter(site => site.networkId === networkId)
)
/**
 * FUNCTIONS
 */
function isNetworkDestroySuccessAction(action: AnyAction): action is MetaPayloadAction<{id: string}> {
  return action.type === "networks/destroy/SUCCESS"
}
