import { combineEpics } from "redux-observable"

import {epic as apiEpic} from "@redux/api"
import {epic as authEpic} from "@redux/state/auth"
import {epic as entitiesEpic} from "@redux/state/entities"
import {epic as errorsEpic} from "@redux/state/errors"
import {epic as flagsEpic} from "@redux/state/flags"
import {epic as helperEpic} from "@redux/helpers"
import {epic as notificationsEpic} from "@redux/state/notifications"

export const rootEpic = combineEpics(
  apiEpic,
  authEpic,
  entitiesEpic,
  errorsEpic,
  flagsEpic,
  helperEpic,
  notificationsEpic,
)