import * as React from 'react'
import Fuse from 'fuse.js'

export type UseFuseOutput<T> = T[]

export const DEFAULT_FUSE_OPTIONS = {
  keys: ["id", "name"],
  threshold: 0.1,
}

export function useFuse<T>(list: T[], filter: string, fuseOptions: Fuse.IFuseOptions<T> = {}): UseFuseOutput<T> {
  const fuse = React.useMemo(() => new Fuse(list, {...DEFAULT_FUSE_OPTIONS, ...fuseOptions}), [list, fuseOptions])
  const items = React.useMemo(() => fuse.search(filter).map(fuseResult => fuseResult.item), [fuse, filter])

  return items
}