import {createSlice} from "@reduxjs/toolkit"
import {map, filter} from "rxjs/operators"
import {normalize, schema} from "normalizr"
import {combineEpics, ofType} from "redux-observable"
import mergeWith from "lodash/mergeWith"
import type {Observable} from "rxjs"
import type {SliceCaseReducers, AnyAction, PayloadAction} from "@reduxjs/toolkit"

import type {IUser, IClient, INetwork, ISite, MerakiStaticRoute, MerakiManagementInterface, MerakiVLAN, MerakiSingleLAN} from "@models"
import type {MetaPayloadAction, NoOpAction} from "../types"

export type IClientDict  = {[key: string]: IClient}
export type INetworkDict = {[key: string]: INetwork}
export type ISiteDict    = {[key: string]: ISite}
export type IUserDict    = {[key: string]: IUser}

export type IEntityDictList = IClientDict | INetworkDict | ISiteDict | IUserDict

export type EntitiesAction = PayloadAction<{entities: Partial<EntityState>, result: string[]}>

export interface EntityState {
  clients : IClientDict;
  networks: INetworkDict;
  sites   : ISiteDict;
  users   : IUserDict;
}

interface SchemasDict {
  [key: string]: schema.Entity,
}

interface SpecialTasksDict {
  [key: string]: schema.Entity | schema.Entity[];
}

const SCHEMAS: SchemasDict = {
  users   : new schema.Entity<IUser>("users"),
  clients : new schema.Entity<IClient>("clients"),
  networks: new schema.Entity<INetwork>("networks"),
  sites   : new schema.Entity<ISite>("sites"),
}

const TASKS = ["list", "read", "create", "update"]

const SPECIAL_TASKS: SpecialTasksDict = {
  "listNetworks": [SCHEMAS.networks],
  "listSites"   : [SCHEMAS.sites],
  "listUsers"   : [SCHEMAS.users],
  "updateUsers" : [SCHEMAS.users],
}

const slice = createSlice<EntityState, SliceCaseReducers<EntityState>>({
  name: "entities",
  initialState: {
    clients : {},
    networks: {},
    sites   : {},
    users   : {},
  },
  reducers: {
    merge(state, {payload}) {
      return mergeWith(state, payload.entities, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue
        }
      })
    },
  },
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {merge} = slice.actions
/**
 * SELECTORS
 */
export const clientsSelector  = (state: {entities: EntityState}): IClientDict => state.entities.clients
export const networksSelector = (state: {entities: EntityState}): INetworkDict => state.entities.networks
export const sitesSelector    = (state: {entities: EntityState}): ISiteDict => state.entities.sites
export const usersSelector    = (state: {entities: EntityState}): IUserDict => state.entities.users
/**
 * EPICS
 */
/**
 * Filtra todas las acciones de éxito más comúnes de la API y parsea el cuerpo
 * para que pueda ser almacenado en el objeto `entities.
 */
export const commonSuccessActions = (action$: Observable<MetaPayloadAction>): Observable<MetaPayloadAction|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    filter(isSuccessAction),
    map((action) => {
      const [entity, task] = action.type.split("/")
      const entitySchema = SCHEMAS[entity]
      // Si no hay un `schema` definido para la entidad identificada en la acción no hacemos nada.
      if (entitySchema === undefined) {
        return {type: "NO_OP", payload: undefined}
      }
      // Si la `task` identificada es una de las `tasks` comúnes (`read`, `list`, `create`, `update`, o `destroy`)
      // normalizamos los datos y actualizamos el estado de `entities`.
      if (TASKS.includes(task)) {
        const normalizedData = normalize(
          task === "update" ? action.meta : action.payload,
          task === "list" ? [entitySchema] : entitySchema)
        return merge(normalizedData)
      }
      // Si la `task` es identificada como una `special task`, normalizamos los datos y actualizamos el estado
      // de `entities`.
      if (SPECIAL_TASKS[task]) {
        const normalizedData = normalize(action.payload, SPECIAL_TASKS[task])
        return merge(normalizedData)
      }
      // Si nínguna de las condiciones anteriores se cumple, no hacemos nada.
      return {type: "NO_OP", payload: undefined}
    }),
    // Filtramos todas las acciones de tipo `NO_OP` para no poluir el stream de `actions`.
    filter(isNotNoOp)
  )
}
/**
 * Filtra solo el caso de `sites/listPorts` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesListPortsSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>>): Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/listPorts/SUCCESS", "sites/updatePort/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, ports: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}

export const sitesListFiltersSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>>): Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/listFilters/SUCCESS", "sites/updateFilter/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, filters: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/readSingleLan` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesSingleLanSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>>): Observable<MetaPayloadAction<{id: string}, MerakiSingleLAN>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/readSingleLan/SUCCESS", "sites/updateSingleLan/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, singleLan: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/listVLANs` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesVLANsSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiVLAN[]>>): Observable<MetaPayloadAction<{id: string}, MerakiVLAN[]>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/listVLANs/SUCCESS", "sites/updateVLANs/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, vlans: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/readVLANsSettings` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesVLANsSettingsSuccess = (action$: Observable<MetaPayloadAction<{id: string}, {vlansEnabled: boolean}>>): Observable<MetaPayloadAction<{id: string}, {vlansEnabled: boolean}>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/readVLANsSettings/SUCCESS", "sites/updateVLANsSettings/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, vlansEnabled: payload.vlansEnabled}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/listStaticRoutes` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesStaticRoutesSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiStaticRoute[]>>): Observable<MetaPayloadAction<{id: string}, MerakiStaticRoute[]>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/listStaticRoutes/SUCCESS", "sites/updateStaticRoutes/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, staticRoutes: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/readManagementInterfaces` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const sitesManagementInterfacesSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiManagementInterface>>): Observable<MetaPayloadAction<{id: string}, MerakiManagementInterface>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/readManagementInterfaces/SUCCESS", "sites/updateManagementInterfaces/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, managementInterfaces: payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Aplica los cambios de estado al realizar un request para modificar el valor de `snmpEnabled` cuando es exitoso.
 */
 export const sitesSnmpEnabledSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiManagementInterface>>): Observable<MetaPayloadAction<{id: string}, {id: string, snmpEnabled: boolean}>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/updateSnmpEnabled/REQUEST", "sites/updateSnmpEnabled/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({...meta, ...payload}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Aplica los cambios de estado al realizar un request para modificar el valor de `snmpEnabled` cuando falla.
 */
 export const sitesSnmpEnabledFailure = (action$: Observable<MetaPayloadAction<{id: string, snmpEnabled: boolean}, {id: string, snmpEnabled: boolean}>>): Observable<MetaPayloadAction<{id: string, snmpEnabled: boolean}, {id: string, snmpEnabled: boolean}>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/updateSnmpEnabled/FAILURE"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, snmpEnabled: !meta.snmpEnabled}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `sites/readManagementInterfacesUsage` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
 export const sitesManagementInterfacesUsageSuccess = (action$: Observable<MetaPayloadAction<{id: string}, MerakiManagementInterface>>): Observable<MetaPayloadAction<{id: string}, MerakiManagementInterface>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("sites/readManagementInterfacesUsage/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, managementInterfaces: {usage: payload}}, SCHEMAS.sites)
      return merge(normalizedData)
    })
  )
}
/**
 * Filtra solo el caso de `clients/listUsers` y parsea el cuerpo de la respuesta para actualizar
 * al usuario identificado según la metadata del `request`.
 */
export const clientsUsersSuccess = (action$: Observable<MetaPayloadAction<{id: string}, IUser[]>>): Observable<MetaPayloadAction<{id: string}, IUser[]>|EntitiesAction|NoOpAction> => {
  return action$.pipe(
    ofType("clients/listUsers/SUCCESS", "clients/updateUsers/SUCCESS"),
    map(({payload, meta}) => {
      const normalizedData = normalize({id: meta.id, userIds: payload.map((user: IUser) => user.id)}, SCHEMAS.clients)
      return merge(normalizedData)
    })
  )
}
/**
 * Export Epic
 */
export const epic = combineEpics(
  commonSuccessActions,
  clientsUsersSuccess,
  sitesManagementInterfacesSuccess,
  sitesManagementInterfacesUsageSuccess,
  sitesStaticRoutesSuccess,
  sitesVLANsSettingsSuccess,
  sitesVLANsSuccess,
  sitesSingleLanSuccess,
  sitesListPortsSuccess,
  sitesListFiltersSuccess,
  sitesSnmpEnabledSuccess,
  sitesSnmpEnabledFailure,
)
/**
 * FUNCTIONS
 */
/**
 * Verífica que la acción es de tipo `SUCCESS`.
 */
function isSuccessAction(action: AnyAction): action is MetaPayloadAction {
  return action.type.endsWith('SUCCESS')
}
/**
 * Verifica que la acción es de tipo `NO_OP`.
 */
function isNotNoOp(action: AnyAction): action is MetaPayloadAction {
  return action.type !== "NO_OP"
}