import React from "react"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"
import {Title, TitleSizes, Button, Flex, FlexItem} from "@patternfly/react-core"

import "./RemotoTableHeader.css"

export interface RemotoTableHeaderProps {
  addButtonText?: string;
  isLoading?: boolean;
  onAdd: (e: React.SyntheticEvent) => void;
  onRefresh?: (e: React.SyntheticEvent) => void;
  title?: string;
}

export const RemotoTableHeader: React.FC<RemotoTableHeaderProps> = ({
  addButtonText = "Agregar VLAN",
  isLoading,
  onAdd,
  onRefresh,
  title = "Subredes",
}) => {
  return (
    <Flex justifyContent={{default: "justifyContentSpaceBetween"}} alignItems={{default: "alignItemsCenter"}} className="RemotoTableHeader">
      <FlexItem>
        <Title className="DataListTitle" headingLevel="h5" size={TitleSizes.lg} style={{margin: "0"}}>{title}</Title>
      </FlexItem>
      <FlexItem>
        {onRefresh && <Button type="button" variant="secondary" onClick={onRefresh} isLoading={isLoading} style={{marginRight: "1rem"}}>
          {isLoading ? "Actualizando..." : "Actualizar" }
        </Button>}
        <Button icon={<PlusIcon />} type="button" variant="primary" onClick={onAdd}>{addButtonText}</Button>
      </FlexItem>
    </Flex>
  )
}