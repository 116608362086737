import React from "react"
import {Button} from "@patternfly/react-core"
import {Link} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {Loader} from "@components/Loader"
import {networksList, networksDestroy} from "@redux/api"
import {networksSelector} from "@redux/state/networks"
import {RedesTable} from "@pages/Redes/RedesTable"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useFlag} from "@hooks/useFlag"
import {useFuse} from "@hooks/useFuse"
import {useIsAdmin} from "@hooks/useIsAdmin"

const fuseOptions = {keys: ["id", "clientId", "status"]}

export const Redes: React.FC<{}> = () => {
  const [destroyId, setDestroyId] = React.useState<string|undefined>()
  const [filter, setFilter]       = React.useState<string>("")
  const dispatch                  = useDispatch()
  const isAdmin                   = useIsAdmin()
  const networks                  = useSelector(networksSelector)
  const networksCreateFlag        = useFlag("networksCreate")
  const networksDestroyFlag       = useFlag("networksDestroy")
  const networksListFlag          = useFlag("networksList")
  const networksUpdateFlag        = useFlag("networksUpdate")
  const fuse                      = useFuse(networks, filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (networks === undefined) {
    return <Loader content="Obteniendo la lista de Redes..."/>
  }

  return (
    <>
      {destroyId &&
        <SmallModal
          onAccept={handleDelete}
          onCancel={toggleModal}
          onAcceptButtonText="Si, quiero eliminar a la Red"
        >
          <p>¿Esta seguro que quiere eliminar esta Red?</p>
        </SmallModal>
      }
      <Toolbar onChange={setFilter} value={filter}>
        <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={networksListFlag} style={{marginRight: "1rem"}}>
          {networksListFlag ? "Actualizando..." : "Actualizar" }
        </Button>
        {isAdmin &&
          <Link to="/redes/nueva">
            <Button icon={<PlusIcon />} iconPosition="left" type="button" variant="primary">
              Nueva Red
            </Button>
          </Link>
        }
      </Toolbar>
      {networksDestroyFlag || networksCreateFlag || networksUpdateFlag
        ? <Loader content="Actualizando la lista de Redes..." />
        : <RedesTable
            showClientId={true}
            loading={networks.length === 0 && networksListFlag}
            items={filter === "" ? networks : fuse}
            onDelete={toggleModal}
          />
      }
    </>
  )
  /**
   * Actualiza la lista de `networks`.
   */
  function handleRefresh() {
    dispatch(networksList())
  }
  /**
   * Lanza el `request` para eliminar la `network` y cierra el `modal`.
   */
  function handleDelete() {
    if (destroyId !== undefined) {
      dispatch(networksDestroy({id: destroyId}))
    }
    setDestroyId(undefined)
  }
  /**
   * Abre o cierra el `modal` para obtener la confirmación del usuario
   * previo a eliminar la `network`.
   * @param id - Identificador único de la `network`.
   */
  function toggleModal(id?: string) {
    setDestroyId(id)
  }
}