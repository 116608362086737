import React from "react"
import {Badge} from "@patternfly/react-core"

export const RemotoStatusBadge: React.FC<{status: string}> = ({status}) => {
  switch (status) {
    case "ok": return <Badge className="green">Ok</Badge>
    case "error": return <Badge className="red">Error</Badge>
    case "missing": return <Badge className="purple">Missing</Badge>
    default: return <Badge className="grey">Offline</Badge>
  }
}