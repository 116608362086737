import React from "react"
import {Link} from "react-router-dom"
import {textCenter, sortable} from "@patternfly/react-table"
import {Badge} from "@patternfly/react-core"
import get from "lodash/get"

import {Table} from "@components/Table"
import {useIsAdmin} from "@hooks/useIsAdmin"
import type {INetwork} from "@models"

export const COLUMNS = [
  {key: "id", title: "Id", transforms: [sortable]},
  {key: "clientName", title: "Nombre Cliente", transforms: [sortable]},
  {key: "clientId", title: "ID del Cliente", transforms: [sortable]},
  {key: "status", title: "Estado", transforms: [sortable]},
  {key: "lastModified", title: "Última Modificación", transforms: [sortable]},
]

export interface RedesTableProps {
  items         : INetwork[];
  loading?      : boolean;
  onDelete?     : (id: string) => void;
  showClientId? : boolean;
  transformRows?: (items: INetwork[]) => {cells: any}[];
}

export const RedesTable: React.FC<RedesTableProps> = ({
  items = [],
  loading = false,
  onDelete,
  showClientId,
  transformRows,
}) => {
  const isAdmin = useIsAdmin()
  const columns = showClientId ? COLUMNS : COLUMNS.filter(column => column.key !== "clientId").filter(column => column.key !== "clientName")

  return (
    <Table
      columns={columns}
      items={items}
      transformRows={transformRows || defaultTransformRows}
      onDelete={isAdmin ? onDelete : undefined}
      loading={loading}
    />
  )

  function defaultTransformRows(items: INetwork[]): {cells: any}[] {
    if (items === undefined) return []
    return items.map((item: INetwork) => ({
      cells: columns.map((column) => {
        if (column.key === "id") {
          return {title: <Link to={`/redes/${item.id}`} key={item.id}>{item.id}</Link>, }
        }
        if (isAdmin && column.key === "clientId") {
          return {title: <Link to={`/clientes/${item.clientId}`} key={item.clientId}>{item.clientId}</Link>, }
        }
        if (column.key === "lastModified") {
          return get(item, "updatedAt") || get(item, "createdAt")
        }
        if (column.key === "status") {
          switch (item.status) {
            case "ok"   : return {title: <Badge className="green">Ok</Badge>}
            case "error": return {title: <Badge className="red">Error</Badge>}
            case "missing": return {title: <Badge className="purple">Missing</Badge>}
                 default: return {title: <Badge className="grey">Offline</Badge>}
          }
        }
        return get(item, column.key)
      })
    }))
  }
}