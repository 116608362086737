import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button
} from '@patternfly/react-core';
import get from "lodash/get"

import history from "@history"
import type {ISite} from "@models"

export interface RemotoData extends ISite {
  isNew: boolean;
}

interface RemotosFormProps {
  id?: string;
  networkId?: string;
  data?: ISite;
  onCreate?: (data: RemotoData) => void;
  onEdit?: (data: RemotoData) => void;
  isLoading?: boolean;
  isNew?: boolean;
}

export const RemotosForm: React.FC<RemotosFormProps> = ({
  data,
  isLoading,
  isNew,
  networkId,
  onCreate,
  onEdit,
}) => {
  return (
    <Form isHorizontal style={{padding: "1rem"}} onSubmit={handleSubmit}>
      <FormGroup
        fieldId="id"
        helperText="ID del remoto en ANTEL"
        isRequired
        label="ID del Remoto"
      >
        <TextInput
          aria-describedby="id-helper"
          defaultValue={get(data, "id", "")}
          id="id"
          isDisabled={isNew === false || isLoading}
          isRequired
          name="id"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="id"
        helperText="ID de la Red en ANTEL"
        isRequired
        label="ID de la Red"
      >
        <TextInput
          aria-describedby="networkId-helper"
          defaultValue={networkId || get(data, "networkId", "")}
          id="networkId"
          isDisabled={networkId !== undefined || isNew === false || isLoading}
          isRequired
          name="networkId"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="name"
        helperText="Nombre del Remoto"
        label="Nombre"
      >
        <TextInput
          aria-describedby="name-helper"
          defaultValue={get(data, "name", "")}
          id="name"
          isDisabled={isLoading}
          isRequired
          name="name"
          type="text"
        />
      </FormGroup>

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={isNew ? "primary" : "warning"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      id: {value: string};
      networkId: {value: string};
      name: {value: string};
    }
    const id = target.id.value;
    const networkId = target.networkId.value;
    const name = target.name.value
    isNew === true
      ? onCreate && onCreate({id, name, networkId, isNew: true})
      : onEdit && onEdit({id, name, networkId, isNew: false})
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    history.go(-1)
  }
}