import React from "react"
import {Title, TitleSizes, Button} from "@patternfly/react-core"
import EditIcon from "@patternfly/react-icons/dist/js/icons/edit-icon"

import "./RemotoInterfacesFixedWan.css"

import {DataList} from "@components/DataList"
import {Chip} from "@components/Chip"
import {RemotoInterfacesStatusBadge} from "./RemotoInterfacesStatusBadge"
import {useSiteManagementInterface} from "@hooks/useSiteManagementInterface"
import type {DataListItem} from "@components/DataList"

export interface RemotoInterfacesFixedWanProps {
  siteId   : string;
  ifaceName: "wan1" | "wan2";
  onEdit   : (wan: string) => void;
}

export function RemotoInterfacesFixedWan({siteId, ifaceName, onEdit}: RemotoInterfacesFixedWanProps) {
  const iface = useSiteManagementInterface(siteId, ifaceName)

  if (iface === undefined || iface.usingStaticIp === false) return null

  return (
    <>
      <Title className="DataListTitle" headingLevel="h5" size={TitleSizes.lg}>{"WAN " + parseInt(ifaceName.replace(/[a-zA-Z]/g, ""))}</Title>
      <DataList items={toDataList(iface)} />
      <Button
        className="RemotoInterfacesFixedWan__button"
        type="button"
        variant="link"
        icon={<EditIcon />}
        iconPosition="left"
        onClick={() => onEdit(ifaceName)
      }>
        Editar {"WAN " + parseInt(ifaceName.replace(/[a-zA-Z]/g, ""))}
      </Button>
    </>
  )
}

function toDataList(item: any = {}): DataListItem[] {
  return [
    {key: "Estado", value: <RemotoInterfacesStatusBadge status={item?.status || "offline"} />},
    {key: "Modo", value: item?.usingStaticIp ? "IP Fija" : "IP Dinámica"},
    {key: "IP Estática", value: item?.staticIp},
    {key: "Máscara de Subred", value: item?.staticSubnetMask},
    {key: "Gateway", value: item?.staticGatewayIp},
    {key: "Servidores DNS Estáticos", value: (item?.staticDns || []).map((dns: string, index: number) => dns === undefined || dns === "" ? null : <Chip key={index}>{dns}</Chip>)},
    {key: "VLAN", value: item?.vlan},
  ]
}