import {applyMiddleware, createStore, compose} from "redux"
import {createEpicMiddleware} from "redux-observable"
import type {Action} from "redux"

import {rootEpic} from "@redux/rootEpic"
import connectHistory from "@redux/history"
import rootReducer from "@redux/rootReducer"
import {createRefreshTokenObservableSubscription} from "@redux/api"
import type {RootState} from "@redux/types"

declare global {
  interface Window {__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any}
}

const composeEnhancers = (process.env.NODE_ENV === "development" && typeof window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export function configureStore() {
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState, any>()
  const middlewareEnhancer = applyMiddleware(epicMiddleware)
  const composedEnhancers = composeEnhancers(middlewareEnhancer)

  const store = createStore(rootReducer, undefined, composedEnhancers)

  epicMiddleware.run(rootEpic)

  return store
}

export default configureStore
export const store = configureStore()
connectHistory(store)
createRefreshTokenObservableSubscription(store, 1000 * 5 * 60)