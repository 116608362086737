import React from "react"
import UsersIcon from "@patternfly/react-icons/dist/js/icons/users-icon"
import NetworkIcon from "@patternfly/react-icons/dist/js/icons/network-icon"
import {
  CardBody,
  Tab,
  Tabs,
  TabTitleIcon,
  TabTitleText,
} from "@patternfly/react-core";

import history from "@history"


export interface ClienteTabsProps {
  activeKey: "redes" | "usuarios"
}

export const ClienteTabs: React.FC<ClienteTabsProps> = ({activeKey, children}) => {
  return (
    <Tabs activeKey={activeKey} isFilled onSelect={handleTabSelect} isBox={true}>
      <Tab eventKey={"redes"} title={<><TabTitleIcon><NetworkIcon /></TabTitleIcon> <TabTitleText>Redes</TabTitleText>  </>}>
        {activeKey === "redes" &&
          <CardBody>
            {children}
          </CardBody>
        }
      </Tab>
      <Tab eventKey={"usuarios"} title={<><TabTitleIcon><UsersIcon /></TabTitleIcon> <TabTitleText>Usuarios</TabTitleText>  </>}>
        {activeKey === "usuarios" &&
          <CardBody>
            {children}
          </CardBody>
        }
      </Tab>
    </Tabs>
  )

  function handleTabSelect(e: React.SyntheticEvent, eventKey: React.ReactText) {
    history.push(history.location.pathname.replace(activeKey, String(eventKey)))
  }
}