import React from "react"
import {Link} from "react-router-dom"
import EditIcon from "@patternfly/react-icons/dist/js/icons/edit-icon"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Divider,
  Grid,
  GridItem,
} from "@patternfly/react-core";
import get from "lodash/get"

import "./ClienteLayout.css"

import type {IClient} from "@models"

export interface ClienteProps {
  item: IClient;
}

export const ClienteLayout: React.FC<ClienteProps> = ({item, children}) => {
  return (
    <Grid hasGutter className="Page ClienteLayout">
      <GridItem span={3} rowSpan={2}>
        <Card>
          <CardTitle>{item.name}</CardTitle>
          <CardBody>
            <dt>ID:</dt>
            <dd>{item.id}</dd>
            <dt>Keycloak Group ID:</dt>
            <dd>{item.keycloakGroupId?.split("-").reverse()[0]}</dd>
            <dt>Última modificación</dt>
            <dd>{get(item, "updatedAt") || get(item, "createdAt")}</dd>
            <Divider />
            <Button type="button" variant="link" icon={<EditIcon />} iconPosition="left" style={{padding: 0}}>
              <Link to={`/clientes/${item.id}/editar`}>Editar</Link>
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem span={9}>
      <Card>
        {children}
      </Card>
    </GridItem>
    </Grid>
  )
}

export default ClienteLayout