import React from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  PageHeaderTools,
  PageHeaderToolsGroup,
  PageHeaderToolsItem,
} from "@patternfly/react-core"
import get from "lodash/get"
import {logout, getUserInfo } from '@keycloak'

export const PageHeaderToolbar: React.FC<{}> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  return (
    <PageHeaderTools>
      <PageHeaderToolsGroup>
        <PageHeaderToolsItem>
          <Dropdown
            isPlain
            position="right"
            onSelect={onDropdownToggle}
            isOpen={isDropdownOpen}
            toggle={
              <DropdownToggle onToggle={onDropdownToggle}>
                {get(getUserInfo(), "name", "Usuario")}
              </DropdownToggle>
            }
            dropdownItems={[
              <DropdownItem key="1" onClick={() => logout()} component="button">
                Cerrar sesion
              </DropdownItem>,
              <DropdownItem key="2" onClick={()=> window.open("/auth/realms/sdwan/account", "_blank")} component="button">
                Mi Cuenta
              </DropdownItem>
            ]}
          />
        </PageHeaderToolsItem>
      </PageHeaderToolsGroup>
    </PageHeaderTools>
  )

  function onDropdownToggle() {
    setIsDropdownOpen(!isDropdownOpen)
  }
}