import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotoPortFormProps} from "./RemotoPortForm"
import {RemotoPortForm} from "./RemotoPortForm"

export interface RemotoPortModalFormProps extends RemotoPortFormProps {
  onClose: () => void;
  title?: string;
}

export const RemotoPortModalForm: React.FC<RemotoPortModalFormProps> = ({
  onClose,
  onCancel,
  title = "Edición de Puerto",
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title={title}
      showClose={true}
      onClose={onClose}
    >
      <RemotoPortForm {...props} onCancel={onCancel || onClose} />
    </Modal>
  )
}