import React from "react"
import {Route, Redirect, Switch} from "react-router-dom"

import {PageLayoutWithRouter as PageLayout} from "@components/PageLayout"
import {Notifications} from "@pages/Notifications"
import {NotFound} from "@pages/NotFound"
// Clientes
import {Cliente} from "@pages/Clientes/:id"
import {ClienteAgregarUsuarios} from "@pages/Clientes/:id/AgregarUsuarios"
import {ClienteNuevaRed} from "@pages/Clientes/:id/NuevaRed"
import {ClienteRedes} from "@pages/Clientes/:id/Redes"
import {Clientes} from "@pages/Clientes"
import {ClientesEditar} from "@pages/Clientes/:id/Editar"
import {ClientesNuevo} from "@pages/Clientes/Nuevo"
import {ClienteUsuarios} from "@pages/Clientes/:id/Usuarios"
// Usuarios
import {Usuarios} from "@pages/Usuarios"
import {UsuariosNuevo} from "@pages/Usuarios/Nuevo"
// Redes
import {Redes} from "@pages/Redes"
import {RedesNueva} from "@pages/Redes/Nueva"
import {Red} from "@pages/Redes/:id"
import {RedNuevoRemoto} from "@pages/Redes/:id/NuevoRemoto"
// Remotos
import {Remotos} from "@pages/Remotos"
import {RemotosNuevo} from "@pages/Remotos/Nuevo"
import {Remoto} from "@pages/Remotos/:id"
import {RemotoInterfaces} from "@pages/Remotos/:id/Interfaces"
import {RemotoVLANs} from "@pages/Remotos/:id/VLANs"
import {RemotoRutasEstaticas} from "@pages/Remotos/:id/RutasEstaticas"
import {RemotoPuertos} from "@pages/Remotos/:id/Puertos"
import {RemotosFiltros} from "@pages/Remotos/:id/Filtros/RemotosFiltros"

export const AdminRoot: React.FC<{}> = () => {
  return (
    <>
      <Notifications />
      <PageLayout>
        <Switch>
          {/* HOME */}
          <Route exact path="/" render={() => <Redirect to="/clientes" />} />
          {/* Remotos */}
          <Route path="/remotos/nuevo" component={RemotosNuevo} />
          <Route path="/remotos/:id/interfaces" component={RemotoInterfaces} />
          <Route path="/remotos/:id/vlans" component={RemotoVLANs} />
          <Route path="/remotos/:id/puertos" component={RemotoPuertos} />
          <Route path="/remotos/:id/Filtros" component={RemotosFiltros} />
          <Route path="/remotos/:id/rutas-estaticas" component={RemotoRutasEstaticas} />
          <Route path="/remotos/:id" component={Remoto} />
          <Route path="/remotos" component={Remotos} />
          {/* Redes */}
          <Route path="/redes/nueva" component={RedesNueva} />
          <Route path="/redes/:id/nuevo-remoto" component={RedNuevoRemoto} />
          <Route path="/redes/:id" component={Red} />
          <Route path="/redes" component={Redes} />
          {/* Usuarios */}
          <Route path="/usuarios/nuevo" component={UsuariosNuevo} />
          <Route path="/usuarios" component={Usuarios} />
          {/* Clientes */}
          <Route path="/clientes/nuevo" component={ClientesNuevo} />
          <Route path="/clientes/:id/editar" component={ClientesEditar} />
          <Route path="/clientes/:id/redes" component={ClienteRedes} />
          <Route path="/clientes/:id/usuarios" component={ClienteUsuarios} />
          <Route path="/clientes/:id/agregar-usuarios" component={ClienteAgregarUsuarios} />
          <Route path="/clientes/:id/nueva-red" component={ClienteNuevaRed} />
          <Route path="/clientes/:id" component={Cliente} />
          <Route path="/clientes" component={Clientes} />
          {/* 404 */}
          <Route path="/" component={NotFound} />
        </Switch>
      </PageLayout>
    </>
  )
}