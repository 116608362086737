import React from "react"
import {Link} from "react-router-dom"
import {Breadcrumb, BreadcrumbItem, BreadcrumbHeading} from "@patternfly/react-core"

import toTitleCase from "../../utils/general/toTitleCase"

interface PageBreadcrumbProps {
  location: Location;
}

export const PageBreadcrumb: React.FC<PageBreadcrumbProps> = ({location}) => {
  const pathname: String = window.decodeURI(location.pathname)
  if (pathname === "" || pathname === "/") return null
  let url = ''
  const crumbs = pathname.split('/').map((crumb, index, list) => {
    url = url + crumb + '/'
    return index === list.length - 1
      ? <BreadcrumbHeading key={index}>{toTitleCase(crumb)}</BreadcrumbHeading>
      : <BreadcrumbItem key={index}><Link to={url.slice(0, -1)}>{toTitleCase(crumb)}</Link></BreadcrumbItem>
  })
  return (
    <Breadcrumb style={{alignItems: "center"}}>
      {crumbs}
    </Breadcrumb>
  )
}