import React from "react"
import {Link, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"

import {ClientesForm} from "@pages/Clientes/ClientesForm"
import {clientsRead, clientsUpdate} from "@redux/api"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {useClient} from "@hooks/useClient"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import type {IClient} from "@models"

export const ClientesEditar: React.FC<{}> = () => {
  const dispatch          = useDispatch()
  const {id}              = useParams<{id: string}>()
  const client            = useClient(id)
  const clientsUpdateFlag = useFlag("clientsUpdate")
  const clientsReadFlag   = useFlag("clientsRead")
  const clientsReadError  = useError("clientsRead")

  React.useEffect(() => {
    if (client === undefined) {
      dispatch(clientsRead({id}))
    }
  }, [])

  if (clientsReadError !== undefined) {
    return (
      <EmptyState title="Cliente no encontrado">
        <div>
          <p>No se ha encontrado un Cliente con el id {id}.</p>
          <br/>
          <Link to="/clientes">{"<< Volver a la lista de Clientes"}</Link>
        </div>
      </EmptyState>
    )
  }

  if (clientsReadFlag || client === undefined) {
    return <Loader content="Obteniendo información del cliente" />
  }

  return <ClientesForm
    data={client}
    isNew={false}
    onEdit={handleEdit}
    isLoading={clientsUpdateFlag}
  />

  function handleEdit(body: Partial<IClient> & {isNew: boolean}) {
    dispatch(clientsUpdate(body))
  }
}