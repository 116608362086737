import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotoVLANFormProps} from "./RemotoVLANForm"
import {RemotoVLANForm} from "./RemotoVLANForm"

export interface RemotoVLANModalFormProps extends RemotoVLANFormProps {
  onClose: () => void;
  title?: string;
}

export const RemotoVLANModalForm: React.FC<RemotoVLANModalFormProps> = ({
  title = "Nueva VLAN",
  onClose,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title={title}
      showClose={true}
      onClose={onClose}
    >
      <RemotoVLANForm {...props} onCancel={onCancel || onClose} />
    </Modal>
  )
}