import React from "react"
import {Button, Flex, CardBody} from "@patternfly/react-core"
import {Link, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {ClienteLayout} from "@pages/Clientes/:id/ClienteLayout"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {Toolbar} from "@components/Toolbar"
import {useClient} from "@hooks/useClient"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {usersList, clientsRead, clientsListUsers, clientsUpdateUsers} from "@redux/api"
import {usersSelector} from "@redux/state/users"
import {UsuariosTable} from "@pages/Usuarios/UsuariosTable"
import {useFuse} from "@hooks/useFuse"

const fuseOptions = {keys: ["name", "lastName", "email"]}

export const ClienteAgregarUsuarios: React.FC<{}> = () => {
  const [filter, setFilter]               = React.useState<string>("")
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([])
  const {id}                              = useParams<{id: string}>()
  const client                            = useClient(id)
  const clientsListUsersFlag              = useFlag("clientsListUsers")
  const clientsReadError                  = useError("clientsRead")
  const clientsUpdateUsersFlag            = useFlag("clientsUpdateUsers")
  const dispatch                          = useDispatch()
  const users                             = useSelector(usersSelector)
  const usersListFlag                     = useFlag("usersList")
  const fuse                              = useFuse(users ? users : [], filter, fuseOptions)

  React.useEffect(() => {
    dispatch(usersList())
    dispatch(clientsRead({id}))
    dispatch(clientsListUsers({id}))
  }, [])

  if (clientsReadError !== undefined) {
    return (
      <EmptyState title="Cliente no encontrado">
        <div>
          <p>No se ha encontrado un Cliente con el id {id}.</p>
          <br/>
          <Link to="/clientes">{"<< Volver a la lista de Clientes"}</Link>
        </div>
      </EmptyState>
    )
  }

  if (client === undefined) {
    return <Loader content="Obteniendo información del Cliente..." />
  }

  return (
    <ClienteLayout item={client}>
      <CardBody>
        <Toolbar onChange={setFilter} value={filter}>
          <Link to={`/usuarios/nuevo`}>
            <Button icon={<PlusIcon />} type="button" variant="primary">Nuevo Usuario</Button>
          </Link>
        </Toolbar>
        {clientsListUsersFlag || usersListFlag || client.users === undefined
          ? <Loader content="Actualizando la lista de usuarios..."/>
          : <UsuariosTable
              loading={users.length === 0 && clientsListUsersFlag}
              items={(filter === "" ? users : fuse).filter(user => !client?.userIds?.includes(user.id))}
              onSelect={handleSelectUser}
              selectedUsers={selectedUsers}
            />
        }
        <br/>
        <Flex justifyContent={{default: "justifyContentSpaceBetween"}}>
          <Button variant="link" type="button"><Link to={`/clientes/${id}/usuarios`}>{"<< Volver"}</Link></Button>
          <Button onClick={handleAddUsers} variant="warning" type="button" isLoading={clientsUpdateUsersFlag}>Agregar Usuarios</Button>
        </Flex>
      </CardBody>
    </ClienteLayout>
  )
  /**
   * Agrega o elimina un `id` de usuario a la lista de `selectedUsers`
   * @param id - Identificador único de un `user`.
   * @param value - Bandera que indica si el usuario se debe agregar o eliminar de la lista.
   */
  function handleSelectUser(id: string, value: boolean) {
    if (clientsUpdateUsersFlag === true) return
    setSelectedUsers(value === true ? [...selectedUsers, id] : selectedUsers.filter(userId => userId !== id))
  }
  /**
   * Realiza el `request` para actualizar los cambios en los `users` del `client`.
   */
  function handleAddUsers() {
    const userIds = client?.userIds || []
    dispatch(clientsUpdateUsers({id, users: [...userIds, ...selectedUsers]}))
  }
}