import React from "react"
import {Flex, FlexItem, Title, TitleSizes, Button, Switch} from "@patternfly/react-core"
import {Tabs, Tab} from '@patternfly/react-core';
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"

import "./RemotoInterfaces.css"

import {Chip} from "@components/Chip"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RemotoInterfacesCharts} from "./RemotoInterfacesCharts";
import {RemotoInterfacesFixedWan} from "./RemotoInterfacesFixedWan";
import {RemotoInterfacesDynamicWan} from "./RemotoInterfacesDynamicWan";
import {RemotoLayout} from "@pages/Remotos/:id/RemotoLayout"
import {RemotoManagementInterfaceModalForm} from "@pages/Remotos/:id/Interfaces/RemotoManagementInterfaceModalForm"
import {RemotoTabs} from "@pages/Remotos/:id/RemotoTabs"
import {sitesRead, sitesReadManagementInterfaces, sitesUpdateManagementInterfaces, sitesUpdateSnmpEnabled} from "@redux/api"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useIsAdmin} from "@hooks/useIsAdmin"
import {useSite} from "@hooks/useSite"
import type {DataListItem} from "@components/DataList"
import type {MerakiManagementInterface} from "@models"
import {useSiteManagementInterface} from "@hooks/useSiteManagementInterface";

export const RemotoInterfaces: React.FC<{}> = () => {
  const dispatch                              = useDispatch()
  const isAdmin                               = useIsAdmin()
  const {id}                                  = useParams<{id: string}>()
  const site                                  = useSite(id)
  const sitesReadManagementInterfacesError    = useError("sitesReadManagementInterfaces")
  const sitesReadManagementInterfacesFlag     = useFlag("sitesReadManagementInterfaces")
  const sitesUpdateManagementInterfacesFlag   = useFlag("sitesUpdateManagementInterfaces")
  const [activeInterface, setActiveInterface] = React.useState<string|undefined>(undefined)
  const [activeTabKey, setActiveTabKey]       = React.useState<string>("configuration")
  const [activeSNPM, setActiveSNPM] = React.useState<boolean|undefined>(undefined)


  React.useEffect(() => {
    handleRefresh()
  }, [site])

  if (site === undefined) return <Loader content="Obteniendo la información del remoto" />

  return (
    <RemotoLayout item={site}>
      <RemotoTabs
        className="RemotoInterfaces"
        activeKey="interfaces"
        isLoading={sitesReadManagementInterfacesError === undefined && site.managementInterfaces === undefined}
        content="Obteniendo la configuración de las interfaces..."
      >
        {sitesReadManagementInterfacesError
          ? <EmptyState
              title={sitesReadManagementInterfacesError.type}
              body={sitesReadManagementInterfacesError.message}
              icon={RobotIcon}
            />
          : <>
            <Tabs className="RemotoInterfaces__tabs" activeKey={activeTabKey} onSelect={handleOnTabSelect}>
              <Tab eventKey={"configuration"} title={<Title className="RemotoInterfaces__tabs__title" headingLevel="h4" size={TitleSizes.lg}>Configuración</Title>}>
                <Flex justifyContent={{default: "justifyContentFlexEnd"}}>
                  <FlexItem>
                    <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={sitesReadManagementInterfacesFlag} style={{marginRight: "1rem"}}>
                      {sitesReadManagementInterfacesFlag ? "Actualizando..." : "Actualizar"}
                    </Button>
                  </FlexItem>
                </Flex>
                <Flex grow={{ default: 'grow' }}>
                  <FlexItem flex={{ default: 'flex_1' }}>
                    <RemotoInterfacesFixedWan siteId={id} ifaceName="wan1" onEdit={setActiveInterface} />
                    <RemotoInterfacesDynamicWan siteId={id} ifaceName="wan1" />
                  </FlexItem>
                  <FlexItem flex={{ default: 'flex_1' }}>
                    <RemotoInterfacesFixedWan siteId={id} ifaceName="wan2" onEdit={setActiveInterface} />
                    <RemotoInterfacesDynamicWan siteId={id} ifaceName="wan2" />
                  </FlexItem>
                </Flex>
              </Tab>
              <Tab eventKey={"graph"} title={<Title className="RemotoInterfaces__tabs__title" headingLevel="h4" size={TitleSizes.lg}>Gráficas</Title>}>
                {isAdmin && <Flex className="RemotoInterfaces__tabs__overlap" justifyContent={{default: "justifyContentFlexStart"}}>
                  <FlexItem>
                    <Switch
                      id="snmp-switch"
                      label="Métricas por SNMP Activadas"
                      labelOff="Métricas por SNMP Desactivadas"
                      isChecked={!!site?.snmpTarget}
                      onChange={handleSNMPSwitch}
                    />
                  </FlexItem>
                </Flex>}
                <RemotoInterfacesCharts id={id} />
              </Tab>
            </Tabs>
            </>
        }
      </RemotoTabs>
      {activeInterface && site.managementInterfaces &&
        <RemotoManagementInterfaceModalForm
          data={activeInterface === "wan1" ? site.managementInterfaces.wan1 : site.managementInterfaces.wan2}
          onSubmit={handleSubmit}
          onClose={() => setActiveInterface(undefined)}
          isLoading={sitesUpdateManagementInterfacesFlag}
        />
      }
    </RemotoLayout>
  )
  /**
   * Actualiza el valor de `snmpEnabled` para el `site`.
   * @param isChecked - Nuevo valor de `snmpEnabled`.
   */
  async function handleSNMPSwitch(isChecked: boolean) {
    if (site && site.id) {
      dispatch(sitesUpdateSnmpEnabled({id: site.id, snmpEnabled: isChecked}))
      // dispatch(sitesRead({id:site.id}))
    }
  }
  /**
   * Modifica la `tab` activa.
   * @param e - Evento de React
   * @param tabIndex - El identificador de la `tab` seleccionada.
   */
  function handleOnTabSelect(e: React.SyntheticEvent, tabIndex: string|number) {
    setActiveTabKey(String(tabIndex))
  }
  /**
   * Actualiza el `site` y sus `managementIntrfaces`.
   */
  function handleRefresh() {
    // if (site === undefined) {
    //   dispatch(sitesRead({id}))
    // }
    dispatch(sitesRead({id}))
    dispatch(sitesReadManagementInterfaces({id}))
    // dispatch(sitesRead({id}))
  }
  /**
   * Envía el `request` para actualizar la Configuración de Red del `site`.
   */
  function handleSubmit(data: MerakiManagementInterface) {
    if (activeInterface === undefined || site === undefined || site.managementInterfaces === undefined) return;
    dispatch(sitesUpdateManagementInterfaces({id, ...site.managementInterfaces, [activeInterface]: data}))
  }
}

function toDataList(item: any = {}): DataListItem[] {
  if (item?.usingStaticIp === false) return [
    {key: "Estado", value: item?.wanEnabled === "enabled" ? "Habilitada" : "Deshabilitada"},
    {key: "Modo", value: "IP Dinámica"},
    {key: "VLAN", value: item?.vlan},
  ]

  return [
    {key: "Estado", value: item?.wanEnabled === "enabled" ? "Habilitada" : "Deshabilitada"},
    {key: "Modo", value: "IP Dinámica"},
    {key: "IP Estática", value: item?.staticIp},
    {key: "Máscara de Subred", value: item?.staticSubnetMask},
    {key: "Default Gateway", value: item?.staticGatewayIp},
    {key: "Servidores DNS Estáticos", value: (item?.staticDns || []).map((dns: string, index: number) => <Chip key={index}>{dns}</Chip>)},
    {key: "VLAN", value: item?.vlan},
  ]
}