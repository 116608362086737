import React from "react"
import {useSelector} from "react-redux"

import {clientSelector, clientNetworksSelector, clientUsersSelector} from "@redux/state/clients"
import type {IClient} from "@models"

export const useClient = (clientId: string) => {
  const clientCallback         = React.useCallback(state => clientSelector(state, clientId), [clientId])
  const clientNetworksCallback = React.useCallback(state => clientNetworksSelector(state, clientId), [clientId])
  const clientUsersCallback    = React.useCallback(state => clientUsersSelector(state, clientId), [clientId])
  const client                 = useSelector(clientCallback)
  const networks               = useSelector(clientNetworksCallback)
  const users                  = useSelector(clientUsersCallback)
  const [result, setResult]    = React.useState<IClient|undefined>(client)

  React.useEffect(() => {
    if (client !== undefined) {
      setResult({...client, networks, users})
    }
  }, [client, networks, users])

  return result;
}

