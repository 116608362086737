import React, { useReducer } from "react"
import {useSelector} from "react-redux"

import {siteStaticRoutesSelector} from "@redux/state/sites"
import type {MerakiStaticRoute} from "@models"

export interface UseSiteStatiRoutesOutput {
  add         : (staticRoute: MerakiStaticRoute) => void;
  remove      : (staticRouteId: string) => void;
  update      : (staticRoute: MerakiStaticRoute) => void;
  staticRoutes: MerakiStaticRoute[];
}

interface Action {
  type: string;
  id?: string;
  staticRoute?: MerakiStaticRoute;
  staticRoutes?: MerakiStaticRoute[];
}

const sorter = (a: MerakiStaticRoute, b: MerakiStaticRoute) => a.id > b.id ? 1 : -1

const reducer = (state: MerakiStaticRoute[], action: Action): MerakiStaticRoute[] => {
  /**
   * Agregamos la nueva ruta estática.
   */
  if (action.type === "add" && action.staticRoute !== undefined) {
    state = [...state, action.staticRoute]
  }
  /**
   * Modificamos una ruta estática
   */
  if (action.type === "update" && action.staticRoute !== undefined) {
    state = [...state.filter(staticRoute => staticRoute.id !== action.staticRoute?.id), action.staticRoute]
  }
  /**
   * Eliminamos una ruta estática según su id.
   */
  if (action.type === "remove" && action.id !== undefined) {
    state = state.filter(staticRoute => staticRoute.id !== action.id)
  }
  /**
   * Reemplazamos la lista de rutas estáticas.
   */
  if (action.type === "refresh" && action.staticRoutes !== undefined) {
    state = [...action.staticRoutes]
  }
  /**
   * Devolvemos el estado actual como acción por defecto.
   */
  return state
}

export const useSiteStaticRoutes = (siteId: string): UseSiteStatiRoutesOutput => {
  const siteStaticRoutesCallback = React.useCallback((state) => siteStaticRoutesSelector(state, siteId), [siteId])
  const siteStaticRoutes = useSelector(siteStaticRoutesCallback)
  const [staticRoutes, dispatch] = useReducer(reducer, [])
  /**
   * Actualizamos el valor de las rutas estáticas si el
   * `site` es actualizado.
   */
  React.useEffect(() => {
    if (staticRoutes !== undefined) {
      dispatch({type: "refresh", staticRoutes: siteStaticRoutes})
    }
  }, [siteStaticRoutes])

  return {staticRoutes, remove, add, update};
  /**
   * Elimina una ruta estática de la lista según su `id`.
   * @param id - Identificador de la ruta estática.
   */
  function remove(id: string) {
    dispatch({type: "remove", id})
  }
  /**
   * Agrega una ruta estática a la lista.
   * @param staticRoute - Nueva ruta estática.
   */
  function add(staticRoute: MerakiStaticRoute) {
    dispatch({type: "add", staticRoute})
  }
  /**
   * Actualiza una ruta estática de la lista.
   * @param staticRoute - Ruta estática modificada.
   */
  function update(staticRoute: MerakiStaticRoute) {
    dispatch({type: "update", staticRoute})
  }
}