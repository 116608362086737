import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {Loader} from "@components/Loader"
import {init, isAuthenticatedSelector} from "@redux/state/auth"

export const Keycloak: React.FC<{message?: string}> = ({
  message = "Espere por favor...",
  children
}) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(isAuthenticatedSelector)

  React.useEffect(() => {
    dispatch(init())
  }, [])

  return isAuthenticated
    ? children as React.ReactElement
    : <Loader content={message} />
}