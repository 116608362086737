import React from "react"

import "./Chip.css"

export interface ChipProps {
  variant?: "green" | "red"
}

export const Chip: React.FC<ChipProps> = ({variant, children}) => {
  return (
    <div className={`pf-c-chip Chip Chip__variant-${variant}`}>
      <span className="pf-c-chip__text">{children}</span>
    </div>
  )
}