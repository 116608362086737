import React from "react"
import {Switch} from "@patternfly/react-core"

import "./RemotoVLANsSwitch.css"

export interface RemotoVLANsSwitchProps {
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

export const RemotoVLANsSwitch: React.FC<RemotoVLANsSwitchProps> = ({
  isChecked,
  onChange,
}) => {
  return (
    <div className="RemotoVLANsSwitch">
      <Switch
        id="vlans-switch"
        label="VLANs Activadas"
        labelOff="VLANs Desactivadas"
        isChecked={isChecked}
        onChange={onChange}
      />
    </div>
  )
}