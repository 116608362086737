import React from "react"
import {
  Table as PatternflyTable,
  TableVariant,
  TableHeader,
  TableBody,
  IActions,
  textCenter,
} from "@patternfly/react-table"
import {Badge} from "@patternfly/react-core"
import get from "lodash/get"

import type {MerakiPort} from "@models"
import {EmptyState} from "@components/EmptyState"
import {defaultRowKey} from "@components/Table"

export const COLUMNS = [
  {key: "number", title: "Puerto"},
  {key: "type", title: "Tipo", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "vlan", title: "VLAN", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "enabled", title: "Habilitado", transforms: [textCenter], cellTransforms: [textCenter]},
]

export interface RemotoPortsTableProps {
  items: MerakiPort[];
  onDelete?: (number: number) => void;
  onEdit?: (number: number) => void;
}

export const RemotoPortsTable: React.FC<RemotoPortsTableProps> = ({onDelete, onEdit, items = []}) => {
  const actions: IActions = []

  if (onEdit !== undefined) {
    actions.push({title: "Editar", onClick: (_, __, rowData) => onEdit(rowData["puerto"].title)})
  }

  if (onDelete !== undefined) {
    actions.push({title: "Eliminar", onClick: (_, __, rowData) => onDelete(rowData["puerto"].title)})
  }

  return (
    <>
      <PatternflyTable
        aria-label="table"
        cells={COLUMNS}
        rows={transformRows(items)}
        variant={TableVariant.compact}
        {...(actions.length > 0 && { actions })}
      >
        <TableHeader />
        <TableBody
          rowKey={defaultRowKey}
        />
      </PatternflyTable>
      {items.length === 0 && <EmptyState title="Tabla vacía" body="No existen Puertos configurados en este Remoto"/>}
    </>
  )

  function transformRows(items: MerakiPort[]): {cells: any}[] {
    if (items == undefined) return []
    return items.map((item: MerakiPort) => ({
      cells: COLUMNS.map((column) => {
        const value =  get(item, column.key)

        if (column.key === "type") {
          return {title: value === "trunk" ? <Badge>Trunk</Badge> : <Badge className="grey">Access</Badge>}
        }
        
        if (column.key === "enabled") {
          return {title: value === true ? <Badge className="green">Si</Badge> : <Badge className="red">No</Badge>}
        }

        return value
      })
    }))
  }
}