import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  Switch,
} from '@patternfly/react-core';
import {ulid} from "ulid"
import get from "lodash/get"

import type {MerakiStaticRoute} from "@models"

export interface RemotoStaticRouteFormProps {
  data?: MerakiStaticRoute,
  isLoading: boolean,
  onSubmit: (data: MerakiStaticRoute) => void,
  onCancel?: () => void,
}

export const RemotoStaticRouteForm: React.FC<RemotoStaticRouteFormProps> = ({
  data,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const [enabled, setEnabled] = React.useState<boolean>(!!data?.enabled)
  const [name, setName] = React.useState<string>(get(data, "name", ""))

  return (
    <Form isHorizontal onSubmit={handleSubmit}>
      {data?.id &&
        <>
          <FormGroup
            fieldId="id"
            helperText="Identificador de la Red. Generado automáticamente."
            label="ID"
            style={{display: "none"}}
          >
            <TextInput
              aria-describedby="id-helper"
              defaultValue={get(data, "id", "")}
              id="id"
              name="id"
              isDisabled={true}
              type="text"
            />
          </FormGroup>

          <FormGroup fieldId="enabled" label="Estado" isRequired>
            <Switch
              id="enabled"
              label="Habilitado"
              labelOff="Deshabilitado"
              isChecked={enabled}
              onChange={setEnabled}
            />
          </FormGroup>
        </>
      }
      <FormGroup
        fieldId="name"
        helperText="Nombre descriptivo de la ruta. Solo puede contener letras, números, espacios, y estos cáracteres: . @ # _ - ."
        label="Nombre"
      >
        <TextInput
          aria-describedby="name-helper"
          id="name"
          name="name"
          type="text"
          onChange={replaceTildes}
          value={name}
          autoFocus={true}
        />
      </FormGroup>

      <FormGroup
        fieldId="subnet"
        helperText="Ejemplo: 192.168.1.0/24"
        label="Subred"
      >
        <TextInput
          aria-describedby="subnet-helper"
          defaultValue={get(data, "subnet", "")}
          id="subnet"
          name="subnet"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="gatewayIp"
        helperText={"El \"siguiente salto\" de la ruta. Ejemplo: 192.168.1.1"}
        label="IP del Gateway"
      >
        <TextInput
          aria-describedby="gatewayIp-helper"
          defaultValue={get(data, "gatewayIp", "")}
          id="gatewayIp"
          name="gatewayIp"
          type="text"
        />
      </FormGroup>

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={"primary"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: {value: string};
      subnet: {value: string};
      gatewayIp: {value: string};
    }
    const name = target.name.value
    const subnet = target.subnet.value
    const gatewayIp = target.gatewayIp.value
    onSubmit({
      id: data !== undefined && data.id ? data.id : ulid(),
      enabled: data !== undefined && data.id ? enabled : true,
      name,
      subnet,
      gatewayIp,
    })
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    onCancel && onCancel()
  }

  function replaceTildes(value: string) {
    setName(value
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/Á/g, 'A')
      .replace(/É/g, 'E')
      .replace(/Í/g, 'I')
      .replace(/Ó/g, 'O')
      .replace(/Ú/g, 'U')
    )
  }
}