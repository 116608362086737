import React from "react"
import {useSelector} from "react-redux"

import {networkSelector, networkSitesSelector} from "@redux/state/networks"
import type {INetwork} from "@models"

export const useNetwork = (networkId: string) => {
  const networkCallback      = React.useCallback(state => networkSelector(state, networkId), [networkId])
  const networkSitesCallback = React.useCallback(state => networkSitesSelector(state, networkId), [networkId])
  const network              = useSelector(networkCallback)
  const sites                = useSelector(networkSitesCallback)
  const [result, setResult]  = React.useState<INetwork|undefined>(network)

  React.useEffect(() => {
    if (network !== undefined) {
      setResult({...network, sites})
    }
  }, [network, sites])

  return result;
}

