import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotosFiltrosFormProps} from "./RemotosFiltrosForm"
import {RemotosFiltrosForm} from "./RemotosFiltrosForm"


export interface RemotosFiltrosModalFormProps extends RemotosFiltrosFormProps {
  onClose: () => void;
  title?: string;
}

export const RemotosFiltrosModalForm: React.FC<RemotosFiltrosModalFormProps> = ({
  onClose,
  title = "Filtros",
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title={title}
      showClose={true}
      onClose={onClose}
    >
      <RemotosFiltrosForm {...props} onClose={onClose} />
    </Modal>
  )
}