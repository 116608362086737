import React from "react"
import {Button} from "@patternfly/react-core"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"

import "./RemotoRutasEstaticas.css"

import {collectionsAreEqual} from "@utils/general/collectionsAreEqual"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RemotoLayout} from "@pages/Remotos/:id/RemotoLayout"
import {RemotoStaticRouteModalForm} from "@pages/Remotos/:id/RutasEstaticas/RemotoStaticRouteModalForm"
import {RemotoStaticRoutesTable} from "@pages/Remotos/:id/RutasEstaticas/RemotoStaticRoutesTable"
import {RemotoTableHeader} from "@pages/Remotos/:id/RemotoTableHeader"
import {RemotoTabs} from "@pages/Remotos/:id/RemotoTabs"
import {sitesRead, sitesListStaticRoutes, sitesUpdateStaticRoutes} from "@redux/api"
import {SmallModal} from "@components/SmallModal"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useHandleList} from "@hooks/useHandleList"
import {useModal} from "@hooks/useModal"
import {useSite} from "@hooks/useSite"
import {useSiteStaticRoutes} from "@hooks/useSiteStaticRoutes"
import type {MerakiStaticRoute} from "@models"

export const RemotoRutasEstaticas: React.FC<{}> = () => {
  const {id}                                      = useParams<{id: string}>()
  const {isOpen, openModal, closeModal}           = useModal(false)
  const dispatch                                  = useDispatch()
  const site                                      = useSite(id)
  const {staticRoutes, add, remove, update}       = useSiteStaticRoutes(id)
  const sitesListStaticRoutesError                = useError("sitesListStaticRoutes")
  const sitesListStaticRoutesFlag                 = useFlag("sitesListStaticRoutes")
  const sitesUpdateStaticRoutesFlag               = useFlag("sitesUpdateStaticRoutes")
  const [activeStaticRoute, setActiveStaticRoute] = React.useState<MerakiStaticRoute|undefined>(undefined)
  const {
    isOpen: isDestroyModalOpen,
    openModal: openDestroyModal,
    closeModal: closeDestroyModal
  } = useModal(false)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (site === undefined) {
    return <Loader content="Obteniendo información del Remoto..."/>
  }

  return (
    <RemotoLayout item={site} className="RemotoRutasEstaticas">
      <RemotoTabs
        activeKey="rutas-estaticas"
        isLoading={sitesListStaticRoutesError === undefined && staticRoutes === undefined}
        content="Obteniendo información de las Rutas Estáticas..."
      >
        {sitesListStaticRoutesError
          ? <EmptyState
              title={sitesListStaticRoutesError.type}
              body={sitesListStaticRoutesError.message}
              icon={RobotIcon}
            />
          : <>
            <RemotoTableHeader
              title="Tabla de Rutas Estáticas"
              isLoading={sitesListStaticRoutesFlag || sitesUpdateStaticRoutesFlag}
              onRefresh={handleRefresh}
              addButtonText="Agregar Ruta"
              onAdd={handleOnOpenModal}
            />
            <RemotoStaticRoutesTable
              items={staticRoutes}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
            {hasChanges() && !sitesUpdateStaticRoutesFlag &&
              <Button isBlock={true} type="button" variant="primary" onClick={handleSaveChanges}>Guardar Cambios</Button>
            }
            </>
        }
      </RemotoTabs>
      {isOpen &&
        <RemotoStaticRouteModalForm
          title={activeStaticRoute === undefined ? "Nueva Ruta Estática" : "Editar Ruta Estática"}
          data={activeStaticRoute}
          onSubmit={handleSubmit}
          onClose={handleClose}
          isLoading={false}
        />
      }
      {isDestroyModalOpen &&
        <SmallModal
          title="Atención"
          onAcceptButtonText="Si, quiero eliminar la Ruta"
          onAccept={() => activeStaticRoute !== undefined && remove(activeStaticRoute.id)}
          onCancel={handleClose}
        >
          <div>
            <p>¿Esta seguro que desea eliminar esta Ruta?</p>
            <br/>
            <p><small><b>* Los cambios no se aplicarán hasta que guarde los cambios.</b></small></p>
            <p><small><b>** Puede refrescar la página para deshacer esta acción.</b></small></p>
          </div>
        </SmallModal>
      }

    </RemotoLayout>
  )
  /**
   * Actualiza el `site` y sus `managementIntrfaces`.
   */
  function handleRefresh() {
    if (site === undefined) {
      dispatch(sitesRead({id}))
    }
    dispatch(sitesListStaticRoutes({id}))
    setActiveStaticRoute(undefined)
  }
  /**
   * Gestiona el proceso de submit del formulario.
   * @param staticRoute - Ruta estática.
   */
  function handleSubmit(staticRoute: MerakiStaticRoute) {
    if (activeStaticRoute !== undefined) {
      update(staticRoute)
    } else {
      add(staticRoute)
    }
    closeModal()
  }
  /**
   * Verífica que existan cambios en la lista de Rutas Estáticas.
   */
  function hasChanges() {
    return collectionsAreEqual<MerakiStaticRoute>(staticRoutes, site?.staticRoutes) === false
  }
  /**
   * Lanza los `requests` para actualizar las Rutas Estáticas.
   */
  function handleSaveChanges() {
    if (staticRoutes !== undefined) {
      dispatch(sitesUpdateStaticRoutes({id, staticRoutes}))
    }
  }
  /**
   * Cierra el `modal` para la confirmación de la eliminación de la Ruta Estática.
   */
  function handleClose() {
    closeModal()
    closeDestroyModal()
    setActiveStaticRoute(undefined)
  }
  /**
   * Abre el `modal` para la confirmación de la eliminación de la Ruta Estática.
   * @param id - Identificados de la ruta estática.
   */
  function handleDelete(id: string) {
    setActiveStaticRoute(staticRoutes.find(staticRoute => staticRoute.id === id))
    openDestroyModal()
  }
  /**
   * Abre el `modal` para la edición de la Ruta Estática.
   * @param id - Identificador de la ruta estática.
   */
  function handleEdit(id: string) {
    setActiveStaticRoute(staticRoutes.find(staticRoute => staticRoute.id === id))
    openModal()
  }

  function handleOnOpenModal() {
    setActiveStaticRoute(undefined)
    openModal()
  }
}