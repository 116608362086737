import React from "react"
import {
  DataList as PatternflyDataList,
  DataListItem as PatternflyDataListItem,
  DataListItemRow,
  DataListItemCells,
  DataListCell,
} from "@patternfly/react-core";

import "./DataList.css"

export interface DataListItem {
  [key: string]: React.ReactNode | React.ReactText;
}

export interface DataListProps {
  items: DataListItem[];
  isCompact?: boolean;
}

export const DataList: React.FC<DataListProps> = ({
  items = [],
  isCompact,
}) => {
  return (
    <PatternflyDataList aria-label="Data List" isCompact className={isCompact ? "DataList DataList__compact" : "DataList"}>
      {items.map((item, index) => (
        <PatternflyDataListItem key={index} aria-labelledby="simple-item1">
          <DataListItemRow>
            <DataListItemCells
              dataListCells={Object.entries(item).map(([key, value]) => (
                <DataListCell key={key}>{value}</DataListCell>
              ))}
            />
          </DataListItemRow>
        </PatternflyDataListItem>
      ))}
    </PatternflyDataList>
  )
}