import type {Store} from "redux"

import history from "@history"

export default (store: Store) => {
  return history.listen((location) => {
    const {
      pathname,
      search,
      state,
      hash,
      key,
    } = location
    store.dispatch({
      type: "@history",
      payload: {
        pathname,
        search,
        state,
        hash,
        key,
      }
    })
  })
}