import _union from "lodash/union"
import {createSlice, createSelector, AnyAction} from "@reduxjs/toolkit"
import type {SliceCaseReducers} from "@reduxjs/toolkit"

import {merge, usersSelector as entitiesSelector} from "@redux/state/entities"
import type {MetaPayloadAction} from "@redux/types"

const slice = createSlice<string[], SliceCaseReducers<string[]>>({
  name: "users",
  initialState: [],
  reducers: {
    union(state, action) {
      return _union(state, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(merge, (state, action) => {
        if (action.payload && action.payload.entities && action.payload.entities.users) {
          return _union(state, Array.isArray(action.payload.result) ? action.payload.result : [action.payload.result])
        }
        return state
      })
      .addMatcher(isUserDestroySuccessAction, (state, action) => {
        return state.filter(id => id !== action.meta.id)
      })
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {union} = slice.actions
/**
 * SELECTORS
 */
export const idsSelector = (state: {users: string[]}) => state.users
export const usersSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ids.map(id => entities[id])
)
export const userIdSelector = (_: any, userId: string) => userId
export const userSelector = createSelector(
  userIdSelector,
  entitiesSelector,
  (userId, entities) => entities[userId]
)
/**
 * FUNCTIONS
 */
function isUserDestroySuccessAction(action: AnyAction): action is MetaPayloadAction<{id: string}> {
  return action.type === "users/destroy/SUCCESS"
}
