import React from "react"
import IntercomIcon from "@patternfly/react-icons/dist/js/icons/intercom-icon"
import NetworkIcon from "@patternfly/react-icons/dist/js/icons/network-icon"
import RoadIcon from "@patternfly/react-icons/dist/js/icons/road-icon"
import PortIcon from "@patternfly/react-icons/dist/js/icons/port-icon"
import {
  CardBody,
  Tab,
  Tabs,
  TabTitleIcon,
  TabTitleText,
} from "@patternfly/react-core";

import history from "@history"
import {Loader} from "@components/Loader"
import { FilterIcon } from "@patternfly/react-icons"

export type RemotoActiveKeys = "interfaces" | "vlans" | "rutas-estaticas" | "puertos" | "filtros"  

export interface RemotoTabsProps {
  activeKey: RemotoActiveKeys;
  className?: string;
  isLoading?: boolean;
  content?: string;
}

export const RemotoTabs: React.FC<RemotoTabsProps> = ({activeKey, isLoading, content, className, children}) => {
  return (
    <Tabs activeKey={activeKey} isFilled onSelect={handleTabSelect} isBox={true}>
      <Tab eventKey="interfaces" title={<><TabTitleIcon><IntercomIcon /></TabTitleIcon> <TabTitleText>Interfaces WAN</TabTitleText>  </>}>
        {activeKey === "interfaces" && <Loader content={content} isLoading={isLoading}><CardBody className={className}>{children}</CardBody></Loader>}
      </Tab>
      <Tab eventKey="vlans" title={<><TabTitleIcon><NetworkIcon /></TabTitleIcon> <TabTitleText>VLANs</TabTitleText>  </>}>
        {activeKey === "vlans" && <Loader content={content} isLoading={isLoading}><CardBody className={className}>{children}</CardBody></Loader>}
      </Tab>
      <Tab eventKey="rutas-estaticas" title={<><TabTitleIcon><RoadIcon /></TabTitleIcon> <TabTitleText>Rutas Estáticas</TabTitleText>  </>}>
        {activeKey === "rutas-estaticas" && <Loader content={content} isLoading={isLoading}><CardBody className={className}>{children}</CardBody></Loader>}
      </Tab>
      <Tab eventKey="puertos" title={<><TabTitleIcon><PortIcon /></TabTitleIcon> <TabTitleText>Puertos</TabTitleText>  </>}>
        {activeKey === "puertos" && <Loader content={content} isLoading={isLoading}><CardBody className={className}>{children}</CardBody></Loader>}
      </Tab>
      <Tab eventKey="filtros" title={<><TabTitleIcon><FilterIcon /></TabTitleIcon> <TabTitleText>Filtros</TabTitleText>  </>}>
        {activeKey === "filtros" && <Loader content={content} isLoading={isLoading}><CardBody className={className}>{children}</CardBody></Loader>}
      </Tab>
    </Tabs>
  )
  /**
   * Modifica la URL de la página de forma programatica para emular el comportamiento
   * de un link.
   * @param e - Evento de React
   * @param eventKey - La llave correspondiente a la `tab` seleccionada.
   */
  function handleTabSelect(e: React.SyntheticEvent, eventKey: React.ReactText) {
    history.push(history.location.pathname.replace(activeKey, String(eventKey)))
  }
}