import React from "react"
import {Title, TitleSizes, Flex, FlexItem, Button} from "@patternfly/react-core"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"

import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RemotoLayout} from "@pages/Remotos/:id/RemotoLayout"
import {RemotoPortModalForm} from "@pages/Remotos/:id/Puertos/RemotoPortModalForm"
import {RemotoPortsTable} from "@pages/Remotos/:id/Puertos/RemotoPortsTable"
import {RemotoTabs} from "@pages/Remotos/:id/RemotoTabs"
import {sitesRead, sitesListPorts, sitesUpdatePort} from "@redux/api"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useHandleList} from "@hooks/useHandleList"
import {useModal} from "@hooks/useModal"
import {useSite} from "@hooks/useSite"
import type {MerakiPort} from "@models"


export const RemotoPuertos: React.FC<{}> = () => {
  const dispatch                                                    = useDispatch()
  const {id}                                                        = useParams<{id: string}>()
  const {isOpen, openModal, closeModal}                             = useModal(false)
  const site                                                        = useSite(id)
  const sitesListPortsError                                         = useError("sitesListPorts")
  const sitesListPortsFlag                                          = useFlag("sitesListPorts")
  const sitesUpdatePortFlag                                         = useFlag("sitesUpdatePort")
  const {list, item, setList, upsertItem, selectItem, deselectItem} = useHandleList<number, "number", MerakiPort>({
    initial: undefined,
    idKey: "number",
    sortByKey: "number",
    onUpsert: closeModal,
  })

  React.useEffect(() => {
    handleRefresh()
  }, [])

  React.useEffect(() => {
    if (list === undefined && site?.ports !== undefined) {
      setList(site?.ports)
    }
  }, [site])

  if (site === undefined) {
    return <Loader content="Obteniendo información del Remoto..."/>
  }

  return (
    <RemotoLayout item={site} className="RemotoPuertos">
      <RemotoTabs
        activeKey="puertos"
        isLoading={sitesListPortsError === undefined && list === undefined}
        content="Obteniendo información de los Puertos..."
      >
        {sitesListPortsError
          ? <EmptyState
              title={sitesListPortsError.type}
              body={sitesListPortsError.message}
              icon={RobotIcon}
            />
          : <>
            <Flex justifyContent={{default: "justifyContentSpaceBetween"}}>
              <FlexItem>
                <Title className="DataListTitle" headingLevel="h5" size={TitleSizes.lg}>Tabla de Puertos</Title>
              </FlexItem>
              <FlexItem>
                <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={sitesListPortsFlag || sitesUpdatePortFlag} style={{marginRight: "1rem"}}>
                  {sitesListPortsFlag || sitesUpdatePortFlag ? "Actualizando..." : "Actualizar" }
                </Button>
              </FlexItem>
            </Flex>
            <RemotoPortsTable
              items={list === undefined ? [] : list}
              onEdit={handleEdit}
            />
            </>
        }
      </RemotoTabs>
      {isOpen &&
        <RemotoPortModalForm
          title={"Editar Puerto"}
          data={item as MerakiPort}
          onSubmit={handleSubmit}
          onClose={handleClose}
          isLoading={false}
        />
      }

    </RemotoLayout>
  )
  /**
   * Actualiza el `site` y sus `managementIntrfaces`.
   */
  function handleRefresh() {
    if (site === undefined) {
      dispatch(sitesRead({id}))
    }
    dispatch(sitesListPorts({id}))
  }
  /**
   * Cierra el `modal` de Configuración de Puerto
   */
  function handleClose() {
    closeModal()
    deselectItem()
  }
  /**
   * Abre el `modal` de Configuración de Puerto
   */
  function handleEdit(number: number) {
    selectItem(number)
    openModal()
  }
  /**
   * Envía el `request` para actualizar la Configuración del Puerto.
   */
  function handleSubmit(data: MerakiPort) {
    upsertItem(data)
    dispatch(sitesUpdatePort({...data, id}))
  }
}