import React from "react"
import {Button, Flex, FlexItem} from "@patternfly/react-core"
import {useDispatch} from "react-redux"
import EditIcon from "@patternfly/react-icons/dist/js/icons/edit-icon"
import isEqual from "lodash/isEqual"

import "./RemotoSingleLanView.css"

import {DataList} from "@components/DataList"
import {Loader} from "@components/Loader"
import {RemotoSingleLanModalForm} from "@pages/Remotos/:id/VLANs/RemotoSingleLanModalForm"
import {requestWaterfall} from "@redux/helpers"
import {sitesReadSingleLan, sitesUpdateSingleLan, sitesUpdateVLANsSettings} from "@redux/api"
import {useFlag} from "@hooks/useFlag"
import {useModal} from "@hooks/useModal"
import type {DataListItem} from "@components/DataList"
import type {MerakiSingleLAN, ISite} from "@models"

export const RemotoSingleLanView: React.FC<{site: ISite, vlansEnabled: boolean}> = ({site, vlansEnabled}) => {
  const dispatch                        = useDispatch()
  const {isOpen, openModal, closeModal} = useModal(false)
  const sitesUpdateVLANsSettingsFlag    = useFlag("sitesUpdateVLANsSettings")
  const sitesReadSingleLanFlag          = useFlag("sitesReadSingleLan")
  const sitesUpdateSingleLanFlag        = useFlag("sitesUpdateSingleLan")
  const [singleLan, setSingleLan]       = React.useState<MerakiSingleLAN|undefined>(undefined)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  React.useEffect(() => {
    if (singleLan === undefined && site.singleLan !== undefined) {
      setSingleLan(site.singleLan)
    }
  }, [site])

  if (singleLan === undefined) {
    return <Loader content="Obteniendo la Configuración de la Red..." />
  }

  return (
    <div className="RemotoSingleLanView">
      <Flex justifyContent={{default: "justifyContentFlexEnd"}}>
        <FlexItem>
          <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={sitesReadSingleLanFlag || sitesUpdateSingleLanFlag || sitesUpdateVLANsSettingsFlag} style={{marginRight: "1rem"}}>
            {sitesReadSingleLanFlag || sitesUpdateSingleLanFlag || sitesUpdateVLANsSettingsFlag ? "Actualizando..." : "Actualizar" }
          </Button>
        </FlexItem>
      </Flex>
      <br />
      <DataList items={toDataList(singleLan)} />
      <Button type="button" variant="link" icon={<EditIcon />} iconPosition="left" onClick={openModal}>
        Editar Red
      </Button>
      {isOpen && singleLan &&
        <RemotoSingleLanModalForm
          data={singleLan}
          onSubmit={handleSubmit}
          onClose={closeModal}
          isLoading={sitesUpdateSingleLanFlag}
        />
      }
      {hasChanges() && !(sitesReadSingleLanFlag || sitesUpdateSingleLanFlag || sitesUpdateVLANsSettingsFlag) &&
        <Button
          isBlock={true}
          type="button"
          variant="primary"
          onClick={handleSaveChanges}
        >
          Guardar Cambios
        </Button>
      }
    </div>
  )
  /**
   * Refresca la Configuración de Red de el `site`.
   */
  function handleRefresh() {
    dispatch(sitesReadSingleLan({id: site.id}))
  }
  /**
   * Verífica que se hayan hecho modificaciones en la Configuración de la Red.
   */
  function hasChanges(): boolean {
    return site.vlansEnabled !== vlansEnabled || isEqual(singleLan, site.singleLan) === false
  }
  /**
   * Aplica los cambios.
   */
  function handleSaveChanges() {
    let actions: any[] = []
    if (vlansEnabled !== site.vlansEnabled) {
      actions.push(sitesUpdateVLANsSettings({id: site.id, vlansEnabled}))
    }
    if (isEqual(singleLan, site.singleLan) === false && singleLan?.applianceIp !== undefined && singleLan?.subnet !== undefined) {
      actions.push(sitesUpdateSingleLan({id: site.id, applianceIp: singleLan.applianceIp, subnet: singleLan.subnet}))
    }
    dispatch(requestWaterfall(actions))
  }
  /**
   * Cierra el `modal` de actualización de la Configuración de Red.
   */
  function handleSubmit(data: MerakiSingleLAN) {
    setSingleLan(data)
    closeModal()
  }
}
/**
 * Transforma un objeto en una lista de `DataListItem`.
 */
function toDataList(item: any = {}): DataListItem[] {
  return [
    {key: "Subred", value: item?.subnet},
    {key: "IP del Remoto", value: item?.applianceIp},
    {key: "Nombre", value: "Single LAN"},
  ]
}