import React from "react"
import {PageSidebar as PatternflyPageSidebar, Nav, NavList, NavItem} from "@patternfly/react-core"
import {Link} from "react-router-dom"
import {Location} from "history"
import {useIsAdmin} from "@hooks/useIsAdmin"

interface PageSidebarProps {
  isNavOpen: boolean;
  location: Location;
}

export const PageSidebar: React.FC<PageSidebarProps> = ({isNavOpen, location}) => {
  const isAdmin = useIsAdmin()
  const crumbs = location.pathname.split("/").slice(1, Infinity)

  return (
    <PatternflyPageSidebar theme="dark" isNavOpen={isNavOpen} nav={
      <Nav aria-label="Nav" theme="dark">
        <NavList>
          {isAdmin &&
            <NavItem itemId={1} isActive={
              crumbs[0] === "clientes" && crumbs.length <= 2
            }>
              <Link to="/clientes">Clientes</Link>
            </NavItem>
          }
          <NavItem itemId={1} isActive={
            crumbs[0] === "redes" && crumbs.length <= 2
          }>
            <Link to="/redes">Redes</Link>
          </NavItem>
          <NavItem itemId={1} isActive={
            (crumbs[0] === "remotos" && crumbs.length <= 2) ||
            (crumbs[2] === "remotos" && crumbs.length <= 4)
          }>
            <Link to="/remotos">Remotos</Link>
          </NavItem>
          {isAdmin &&
            <NavItem itemId={1} isActive={
              crumbs[0] === "usuarios" && crumbs.length <= 2
            }>
              <Link to="/usuarios">Usuarios</Link>
            </NavItem>
          }
          
          {/* <NavItem hidden={true} itemId={1} onClick={()=> window.open("/auth/realms/sdwan/account", "_blank")}>
             {"Mi Cuenta"}  
          </NavItem> */}
        </NavList>
        
      </Nav>
      
    }/>
  )
}
