import React from "react"
import SearchIcon from "@patternfly/react-icons/dist/js/icons/search-icon"
import {
  Toolbar as PatternflyToolbar,
  ToolbarItem,
  ToolbarContent,
  InputGroup,
  TextInput,
  Button,
} from "@patternfly/react-core";

export interface ToolbarProps {
  alignment?: "alignRight" | "alignLeft"
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  value?: string;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  alignment = "alignRight",
  children,
  value = "",
  onChange = () => {},
  onSubmit = () => {},
}) => {
  return (
    <PatternflyToolbar>
      <ToolbarContent>
        <ToolbarItem>
          <form onSubmit={handleSubmit}>
            <InputGroup>
              <TextInput value={value} onChange={onChange} placeholder="Buscar" type="search" id="searchTerm" />
              <Button type="submit" variant="control">
                <SearchIcon />
              </Button>
            </InputGroup>
          </form>
        </ToolbarItem>
        <ToolbarItem alignment={{default: alignment}}>
          {children}
        </ToolbarItem>
      </ToolbarContent>
    </PatternflyToolbar>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    onSubmit(value)
  }
}