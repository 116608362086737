import React from "react"

import {useSiteManagementInterfaces} from "@hooks/useSiteManagementInterfaces";
import type {MerakiManagementInterface} from "@models"

export const useSiteManagementInterface = (siteId: string, interfaceName: "wan1" | "wan2") => {
  const siteManagementInterfaces = useSiteManagementInterfaces(siteId)
  const [result, setResult] = React.useState<MerakiManagementInterface|undefined>(undefined)

  React.useEffect(() => {
    if (siteManagementInterfaces !== undefined) {
      setResult({...siteManagementInterfaces[interfaceName]})
    }
  }, [siteManagementInterfaces])

  return result;
}