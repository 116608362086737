import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {siteSelector} from "@redux/state/sites"
import {sitesReadManagementInterfacesUsage} from "@redux/api"
import type {MerakiManagementInterfaceUsage} from "@models"

export const useSiteUsage = (siteId: string) => {
  const dispatch            = useDispatch()
  const siteCallback        = React.useCallback(state => siteSelector(state, siteId), [siteId])
  const site                = useSelector(siteCallback)
  const [result, setResult] = React.useState<MerakiManagementInterfaceUsage[]|undefined>(undefined)

  React.useEffect(() => {
    dispatch(sitesReadManagementInterfacesUsage({id: siteId}))
    // Actualizamos los valores de consumo cada 5 minutos.
    const intervalId = window.setInterval(() => dispatch(sitesReadManagementInterfacesUsage({id: siteId})), 1000 * 60 * 5)
    return () => clearInterval(intervalId)
  }, [])

  React.useEffect(() => {
    if (site !== undefined && site.managementInterfaces !== undefined && site.managementInterfaces.usage !== undefined) {
      setResult(site.managementInterfaces.usage)
    }
  }, [site])

  return result;
}

