import React from "react"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"
import {RemotoTableHeader} from "@pages/Remotos/:id/RemotoTableHeader"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RemotosFiltrosTable} from "@pages/Remotos/:id/Filtros/RemotosFiltrosTable"
import {RemotoTabs} from "@pages/Remotos/:id/RemotoTabs"
import {sitesRead, sitesListFilters, sitesUpdateFilters} from "@redux/api"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"

import {useModal} from "@hooks/useModal"
import {SmallModal} from "@components/SmallModal"
import {useSite} from "@hooks/useSite"
import type {FilterRules, FilterValue, MerakiFilter} from "@models"
import RemotoLayout from "../RemotoLayout"
import { RemotosFiltrosModalForm } from "./RemotosFiltrosModalForm"
import { useFilterList } from "@hooks/useFilterList"
import type { SitesUpdateFiltersPayload } from "../../../../services/redux/api"


export const RemotosFiltros: React.FC<{}> = () => {
  const dispatch                                                    = useDispatch()
  const {id}                                                        = useParams<{id: string}>()
  const {isOpen, openModal, closeModal}                             = useModal(false)
  const {openModal: openDestroyModal, closeModal: closeDestroyModal} = useModal(false)
  const site                                                        = useSite(id)
  const sitesListFiltersError                                       = useError("sitesListFilters")
  const sitesListFiltersFlag                                        = useFlag("sitesListFilters")
  const sitesUpdateFiltersFlag                                      = useFlag("sitesUpdateFilters")
  const {list, setList}                                          = useFilterList<string|FilterValue ,"value", FilterRules>({
    initial:undefined
  })
  const [destroyId, setDestroyId] = React.useState<string|undefined>()
 
  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (site === undefined) {
    return <Loader content="Obteniendo información del Remoto..."/>
  }
  
  React.useEffect(() => {
     if (list === undefined || //si list es undefined
         list.length == 0 || //o list esta vacia
         (list.length == 1 &&  //o list tiene 1 item, pero su value es vacio
             (typeof list[0].value == 'string' ? list[0].value == "" : list[0].value.name == "")
         && site?.filters !== undefined)) {
       setList(site?.filters?.rules)
     }
  }, [site])

   if (site === undefined) {
     return <Loader content="Obteniendo información del Remoto..."/>
   }

  return (
<RemotoLayout item={site}>
      <RemotoTabs
        activeKey="filtros"
        isLoading={sitesListFiltersError === undefined && list === undefined}
        content="Obteniendo información de los Filtros..."
      >
        {sitesListFiltersError
          ? <EmptyState
              title={sitesListFiltersError.type}
              body={sitesListFiltersError.message}
              icon={RobotIcon}
            />
          : <>
          <RemotoTableHeader
              title="Tabla de Filtros"
              isLoading={sitesListFiltersFlag || sitesUpdateFiltersFlag}
              onRefresh={handleRefresh}
              addButtonText="Agregar"
              onAdd={handleOnOpenModal}
            />
            <RemotosFiltrosTable 
            onDelete={selectFilterForDeletion}
            rules={list === undefined ? [] : list}/>
            </>
        }
      </RemotoTabs>
      {isOpen &&
        <RemotosFiltrosModalForm
          rules={list === undefined ? [] : list }
          title="Nuevo Filtro"
          onSubmit={handleSubmit}
          onClose={handleClose}
          isLoading={false}
        />
      }
      {destroyId &&
        <SmallModal
          onAccept={handleDelete}
          onCancel={closeDestroyModal}
          onAcceptButtonText="Si, quiero eliminar el filtro"
        >
          <p>¿Esta seguro que quiere eliminar este filtro?</p>
        </SmallModal>
      }
      </RemotoLayout>

  )
  /**
   * Actualiza el `site`.
   */
  function handleRefresh() {
    if (site === undefined) {
      dispatch(sitesRead({id}))
    }
    dispatch(sitesListFilters({id}))
  }

  function handleOnOpenModal() {
    openModal()
  }

  function handleClose() {
    closeModal()
  }


  function selectFilterForDeletion(id:string)
  {
    setDestroyId(id)
  } 

  function handleDelete()
  {
    if(list)
    {
      console.log("Delete:" + JSON.stringify(destroyId))
      let aux = list.filter(t => (t.value as FilterValue).id != destroyId);
      console.log("List with deletions:" +JSON.stringify(aux));
      const f:MerakiFilter = {rules:aux}
      const payload: SitesUpdateFiltersPayload = {filters:f, id:id}
      console.log(JSON.stringify(payload));
      dispatch(sitesUpdateFilters(payload))
      closeDestroyModal();
      setDestroyId(undefined);
      console.log(JSON.stringify(destroyId));
    } 
  } 
  
  function handleSubmit(rules: FilterRules[]){
      const f:MerakiFilter = {rules : rules}
      
      const payload: SitesUpdateFiltersPayload = {filters:f, id:id}
      
      dispatch(sitesUpdateFilters(payload))
      closeModal()
  } 

}


