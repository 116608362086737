import React from "react"
import {
  ActionGroup,
  Button,
  Form,
  FormGroup,
  TextInput,
} from '@patternfly/react-core';
import get from "lodash/get"

import history from "@history"
import type {IUser} from "@models"

export interface UsuarioData extends IUser {
  isNew: boolean;
}

interface UsuariosFormProps {
  id?: string;
  data?: IUser;
  onCreate?: (data: UsuarioData) => void;
  onEdit?: (data: UsuarioData) => void;
  isLoading?: boolean;
  isNew?: boolean;
}

export const UsuariosForm: React.FC<UsuariosFormProps> = ({isNew, data, onCreate, onEdit, isLoading}) => {
  return (
    <Form isHorizontal style={{padding: "1rem"}} onSubmit={handleSubmit}>
      <FormGroup label="Email" isRequired fieldId="email">
        <TextInput defaultValue={get(data, "email", "")} isRequired type="text" id="email" aria-describedby="id-helper" name="email" isDisabled={isNew === false || isLoading}/>
      </FormGroup>

      <FormGroup label="Nombre" fieldId="firstName">
        <TextInput defaultValue={get(data, "firstName", "")} type="text" id="firstName" aria-describedby="id-helper" name="firstName" isDisabled={isLoading}/>
      </FormGroup>

      <FormGroup label="Apellido" fieldId="lastName">
        <TextInput defaultValue={get(data, "lastName", "")} type="text" id="lastName" aria-describedby="id-helper" name="lastName" isDisabled={isLoading}/>
      </FormGroup>

      {/* {isNew === true &&
        <FormGroup label="Password" isRequired fieldId="password">
          <TextInput defaultValue={get(data, "password", "")} isRequired type="password" id="password" aria-describedby="id-helper" name="password" isDisabled={isLoading}/>
        </FormGroup>
      } */}

      <ActionGroup>
        <Button type="submit" variant={isNew ? "primary" : "warning"} isDisabled={isLoading} isLoading={isLoading}>Aceptar</Button>
        <Button variant="link" onClick={handleCancel} isDisabled={isLoading}>Cancelar</Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      firstName: {value: string};
      lastName: {value: string};
      email: {value: string};
      password: {value: string};
    }
    const firstName = target.firstName.value
    const lastName = target.lastName.value
    const email = target.email.value
    const password = "1234"
    const body = {
      firstName,
      lastName,
      email,
      password
    } as any
    if (data && data.id) {
      body.id = data.id
    }
    isNew === true
      ? onCreate && onCreate(body)
      : onEdit && onEdit(body)
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    history.go(-1)
  }
}