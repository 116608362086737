import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
} from '@patternfly/react-core';
import get from "lodash/get"

import type {MerakiVLAN} from "@models"

export interface RemotoVLANFormProps {
  data?: MerakiVLAN,
  isLoading: boolean,
  onSubmit: (data: MerakiVLAN) => void,
  onCancel?: () => void,
}

export const RemotoVLANForm: React.FC<RemotoVLANFormProps> = ({
  data,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  return (
    <Form isHorizontal onSubmit={handleSubmit}>
      <FormGroup
        fieldId="id"
        helperText="Valor númerico entre 1 y 4096"
        label="ID"
      >
        <TextInput
          aria-describedby="id-helper"
          defaultValue={get(data, "id", "")}
          id="id"
          name="id"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="name"
        label="Nombre"
      >
        <TextInput
          aria-describedby="name-helper"
          defaultValue={get(data, "name", "")}
          id="name"
          name="name"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="subnet"
        helperText="Ejemplo: 192.168.1.0/24"
        label="Subred"
      >
        <TextInput
          aria-describedby="subnet-helper"
          defaultValue={get(data, "subnet", "")}
          id="subnet"
          name="subnet"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="applianceIp"
        helperText="Ejemplo: 192.168.1.1"
        label="IP del Remoto"
      >
        <TextInput
          aria-describedby="applianceIp-helper"
          defaultValue={get(data, "applianceIp", "")}
          id="applianceIp"
          name="applianceIp"
          type="text"
        />
      </FormGroup>

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={"primary"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      id: {value: string};
      name: {value: string};
      subnet: {value: string};
      applianceIp: {value: string};
    }
    const id = parseInt(target.id.value, 10) || 1
    const name = target.name.value
    const subnet = target.subnet.value
    const applianceIp = target.applianceIp.value
    onSubmit({
      id,
      name,
      subnet,
      applianceIp,
    })
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    onCancel && onCancel()
  }
}