import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  Switch,
  Select,
  SelectOption,
  SelectVariant,
} from '@patternfly/react-core';
import get from "lodash/get"

import type {MerakiPort} from "@models"

export interface RemotoPortFormProps {
  data: MerakiPort,
  isLoading: boolean,
  onSubmit: (data: MerakiPort) => void,
  onCancel?: () => void,
}

export const RemotoPortForm: React.FC<RemotoPortFormProps> = ({
  data,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const [enabled, setEnabled] = React.useState<boolean>(!!data?.enabled)
  const [type, setType] = React.useState<string>(data.type)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)


  return (
    <Form isHorizontal onSubmit={handleSubmit}>
      <FormGroup
        fieldId="number"
        helperText="Número del Puerto"
        label="#"
      >
        <TextInput
          aria-describedby="number-helper"
          defaultValue={get(data, "number", "")}
          id="number"
          name="number"
          isDisabled={true}
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="type"
        helperText="Tipo de acceso del puerto"
        label="IP del Remoto"
      >
        <Select
          variant={SelectVariant.single}
          aria-label="Select Input"
          onSelect={(_, selected) => setType(selected as string)}
          isOpen={isOpen}
          onToggle={() => setIsOpen(!isOpen)}
          selections={type}
          aria-labelledby={"type select"}
          direction="down"
        >
          <SelectOption key={"access"} value="access" />
          <SelectOption key={"trunk"} value="trunk" />
        </Select>
      </FormGroup>

      <FormGroup fieldId="enabled" label="Estado" isRequired>
        <Switch
          id="enabled"
          label="Habilitado"
          labelOff="Deshabilitado"
          isChecked={enabled}
          onChange={setEnabled}
        />
      </FormGroup>

      <FormGroup
        fieldId="vlan"
        helperText="Valor númerico entre 1 y 4096"
        label="VLAN"
      >
        <TextInput
          aria-describedby="vlan-helper"
          defaultValue={get(data, "vlan", 1)}
          id="vlan"
          name="vlan"
          type="text"
        />
      </FormGroup>

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={"primary"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      vlan: {value: string};
    }
    const vlan = parseInt(target.vlan.value, 10)
    onSubmit({
      number: data.number,
      enabled,
      vlan,
      type,
    })
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    onCancel && onCancel()
  }
}