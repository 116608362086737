import { String } from "lodash";

export interface IUser {
  auth_time: Date;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  name: "Administrator User";
  preferred_username: "admin"
  roles: string[];
}

export const updateToken = async (seconds: number) => {
  try {
    return await window.k.updateToken(seconds);
  }
  catch {
    alert("Se ha cerrado su sesión por inactividad.");
  }
}

export const logout = () => {
  window.k.logout();
}

export const getUserRoles = (): String[] => {
  const data = window.k.idTokenParsed
  return (data.roles)
    ? data.roles
    : []
}

export const hasRole = (roleName: string): boolean => {
  const data = window.k.idTokenParsed
  return data.roles && data.roles.includes(roleName)
}


export const getUserInfo = (): IUser => {
  const data = window.k.idTokenParsed
  const roles = (data.roles)
    ? data.roles
    : []
  const groups = (data.groups)
    ? data.groups
    : []
  const reduced: IUser = { ...data, roles, groups }
  return reduced
}


declare global {
  interface Window {
    getUserInfo: any
  }
}

window.getUserInfo = getUserInfo;