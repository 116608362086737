import React from "react"

export interface UseFilterListOptions<U, T> {
  initial?: T[]|undefined;
}

export function useFilterList<V, U extends string, T extends {[key in U]: V}>({
  initial = undefined,
}: UseFilterListOptions<U, T> = {}) {

  const [list, setList] = React.useState<T[]|undefined>(initial)
  const [item, setItem] = React.useState<T|undefined>(undefined)

  return {list, setList, item, setItem}

  
}