import _union from "lodash/union"
import {createSlice, createSelector, AnyAction} from "@reduxjs/toolkit"
import type {SliceCaseReducers} from "@reduxjs/toolkit"

import {merge, sitesSelector as entitiesSelector} from "./entities"
import type {MetaPayloadAction} from "../types"

const slice = createSlice<string[], SliceCaseReducers<string[]>>({
  name: "sites",
  initialState: [],
  reducers: {
    union(state, action) {
      return _union(state, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(merge, (state, action) => {
        if (action.payload && action.payload.entities && action.payload.entities.sites) {
          return _union(state, Array.isArray(action.payload.result) ? action.payload.result : [action.payload.result])
        }
        return state
      })
      .addMatcher(isUserDestroySuccessAction, (state, action) => {
        return state.filter(id => id !== action.meta.id)
      })
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {union} = slice.actions
/**
 * SELECTORS
 */
export const idsSelector = (state: {sites: string[]}) => state.sites
export const sitesSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ids.map(id => entities[id])
)
export const siteIdSelector = (_: any, siteId: string) => siteId
export const siteSelector = createSelector(
  siteIdSelector,
  entitiesSelector,
  (siteId, entities) => entities[siteId]
)
export const siteManagementInterfacesSelector = createSelector(
  siteSelector,
  (site) => site?.managementInterfaces
)
export const siteManagementInterfaceIdSelector = (_: any, managementInterfaceId: "wan1" | "wan2") => managementInterfaceId
export const siteManagementInterfaceSelector = createSelector(
  siteManagementInterfaceIdSelector,
  siteManagementInterfacesSelector,
  (siteManagementInterfaceId, siteManagementInterfaces) => typeof siteManagementInterfaces === "object" ? siteManagementInterfaces[siteManagementInterfaceId] : undefined
)
export const siteStaticRoutesSelector = createSelector(
  siteSelector,
  (site) => site?.staticRoutes
)
export const siteFilterSelector = createSelector(
  siteSelector,
  (site) => site?.filters
)
/**
 * FUNCTIONS
 */
function isUserDestroySuccessAction(action: AnyAction): action is MetaPayloadAction<{id: string}> {
  return action.type === "sites/destroy/SUCCESS"
}
