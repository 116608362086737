import React from "react"

import {ErrorState} from "@components/ErrorState"


export const RedErrorState: React.FC<{error: {type: string, message: string}}> = ({error}) => {
  return (
    <ErrorState
      error={error}
      href="/redes"
      linkText="<< Volver a la lista de Redes"
      title="Error al listar los Remotos"
    />
  )
}