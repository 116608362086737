import {combineEpics, ofType} from "redux-observable"
import {createAction} from "@reduxjs/toolkit"
import {filter, mergeMap, take, mapTo} from "rxjs/operators"
import {of, concat} from "rxjs"
import type {Observable} from "rxjs"
import type {PayloadAction} from "@reduxjs/toolkit"
/**
 * Dentro de este módulo se incluyen funciones que permiten
 * manejar las Redux Actions de forma más sencilla. Por ejemplo:
 * lanzar acciones en paralelo o en cascada.
 */
/**
 * ACTIONS
 */
export const requestWaterfall = createAction<PayloadAction[]>("helpers/requestWaterfall")
/**
 * EPICS
 */
const requestWaterfallEpic = (action$: Observable<PayloadAction<PayloadAction[]>>): Observable<PayloadAction<unknown>|{type: string}> => {
  return action$.pipe(
    filter(requestWaterfall.match),
    mergeMap(({payload}) => {
      const actions = payload.map((action) => {
        const prefix = action.type.split("/").slice(0, 2).join("/")
        /**
         * Este Observable lanza el request y espera recibir un
         * action de tipo `SUCCESS` antes de mandar el siguiente.
         * Si se produce un error, los siguientes requests nunca
         * son emitidos.
         */
        return concat(
          of(action),
          action$.pipe(ofType(`${prefix}/SUCCESS`), take(1), mapTo({type: "NO_OP"})) // Esta acción es filtrada más adelante.
        )
      })
      return concat(...actions)
    }),
    filter(({type}) => type !== "NO_OP")
  )
}

export const epic = combineEpics(requestWaterfallEpic)