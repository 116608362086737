import { combineReducers } from "redux"

import clients from "@redux/state/clients"
import entities from "@redux/state/entities"
import errors from "@redux/state/errors"
import flags from "@redux/state/flags"
import networks from "@redux/state/networks"
import notifications from "@redux/state/notifications"
import sites from "@redux/state/sites"
import users from "@redux/state/users"
import auth from "@redux/state/auth"

export const rootReducer = combineReducers({
  auth,
  clients,
  entities,
  errors,
  flags,
  networks,
  notifications,
  sites,
  users,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
