import React from "react"
import {Link} from "react-router-dom"
import get from "lodash/get"
import {textCenter, sortable} from "@patternfly/react-table"
import {Badge} from "@patternfly/react-core"

import "./RemotosTable.css"

import {DataList} from "@components/DataList"
import {RemotoStatusBadge} from "@pages/Remotos/RemotoStatusBadge"
import {Table} from "@components/Table"
import {useIsAdmin} from "@hooks/useIsAdmin"
import type {DataListItem} from "@components/DataList"
import type {ISite} from "@models"

export const COLUMNS = [
  {key: "id", title: "Id", transforms: [sortable]},
  {key: "clientName", title: "Cliente", transforms: [sortable]},
  {key: "name", title: "Nombre", transforms: [sortable]},
  {key: "address", title: "Dirección", transforms: [sortable]},
  {key: "model", title: "Equipo", transforms: [sortable]},
  {key: "status", title: "Estado", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "lastModified", title: "Última modificación"},
]

export const NETWORK_ID_COLUMN = {key: "networkId", title: "Id de Red"}

export interface RemotosTableProps {
  items         : ISite[];
  loading?      : boolean;
  onDelete?     : (id: string) => void;
  showNetworkId?: boolean;
  showClientName?: boolean;
  transformRows?: (items: ISite[]) => {cells: any}[];
}

export const RemotosTable: React.FC<RemotosTableProps> = ({
  items = [],
  loading = false,
  onDelete,
  showNetworkId = false,
  showClientName = false,
  transformRows,
}) => {
  const isAdmin               = useIsAdmin()
  const [columns, setColumns] = React.useState(COLUMNS)

  React.useEffect(() => {
    setColumns(showNetworkId ? [COLUMNS[0], NETWORK_ID_COLUMN, ...COLUMNS.slice(1)] : COLUMNS)
    setColumns(showClientName ? columns : columns.filter(column => column.key !== "clientName"))
  }, [showNetworkId])

  return (
    <Table
      className="RemotosTable"
      columns={columns}
      items={items}
      loading={loading}
      transformRows={transformRows || defaultTransformRows}
      onDelete={isAdmin ? onDelete : undefined}
    />
  )

  function defaultTransformRows(items: ISite[]): {cells: any}[] {
    if (items === undefined) return []
    return items.map((item: ISite) => ({
      cells: columns.map((column) => {
        if (column.key === "id") {
          return {title: <Link to={`/remotos/${item.id}`} key={item.id}>{item.id}</Link>}
        }

        if (column.key === "lastModified") {
          return get(item, "updatedAt") || get(item, "createdAt")
        }

        if (column.key === "model") {
          return {
            title: item.model === undefined
              ? <Badge className="grey">{"<Sin Equipo>"}</Badge>
              : <DataList isCompact items={toDataList(item)} />
          }
        }

        if (column.key === "status") {
          return {title: <RemotoStatusBadge status={item.status || ""} />}
        }

        if (column.key === "networkId") {
          return {title: <Link to={`/redes/${item.networkId}`} key={item.networkId}>{item.networkId}</Link>}
        }

        return get(item, column.key)
      })
    }))
  }
}

function toDataList(item: any = {}): DataListItem[] {
  return [
    {key: "Modelo", value: item.model},
    {key: "Serie", value: item.serial},
    {key: "MAC", value: item.mac},
  ]
}