import {combineEpics, ofType} from "redux-observable"
import {concat, of} from "rxjs"
import {createSlice, createSelector} from "@reduxjs/toolkit"
import {filter, mergeMap, mapTo, take} from "rxjs/operators"
import camelCase from "lodash/camelCase"
import type {Observable} from "rxjs"
import type {PayloadAction, SliceCaseReducers} from "@reduxjs/toolkit"
/**
 * Este módulo se encarga de gestionar todos los errores
 * que se produzcan en la aplicación. La idea es contar
 * con un solo lugar en donde se disponibilize esta
 * información.
 */
export interface Error {
  type: string;
  message: string;
}

export interface ErrorState {
  [key: string]: Error|undefined;
}

export interface ErrorPayload {
  key: string;
  value: Error;
}

const slice = createSlice<ErrorState, SliceCaseReducers<ErrorState>>({
  name: "errors",
  initialState: {},
  reducers: {
    set(state, action: PayloadAction<ErrorPayload>) {
      const {key, value} = action.payload
      state[key] = value
    },
    acknowledge(state, action: PayloadAction<string>) {
      delete state[action.payload]
    }
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {set, acknowledge} = slice.actions
/**
 * SELECTORS
 */
export const errorsSelector = (state: any) => state.errors
export const errorIdSelector = (_: any, errorId: string) => errorId
export const errorSelector = createSelector(
  errorsSelector,
  errorIdSelector,
  (errors: ErrorState, errorId: string) => errors[errorId])
/**
 * EPICS
 */
const ACTION_REGEXP = /(.+)\/(.+)\/FAILURE$/i
/**
 * Idfentifica todos los `requests` que sufrierón un error
 * y emite una acción para setear el error en el `ErrorReducer`.
 */
export const requestFailure = (action$: Observable<PayloadAction<Error|undefined>>): Observable<PayloadAction<Error|undefined>> => {
  return action$.pipe(
    filter(({type}) => ACTION_REGEXP.test(type)),
    mergeMap(({type, payload}) => {
      const [_, entity, task] = type.match(ACTION_REGEXP) as RegExpMatchArray
      const key = camelCase([entity, task].join(" "))
      return concat(
        of(set({
          key,
          value: payload
        })),
        action$.pipe(
          ofType("@history"),
          take(1),
          mapTo(acknowledge(key))
        )
      )
    })
  )
}

export const epic = combineEpics(requestFailure)