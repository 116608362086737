import {createSlice, createSelector} from "@reduxjs/toolkit"
import {filter, map} from "rxjs/operators"
import camelCase from "lodash/camelCase"
import type {Observable} from "rxjs"
import type {PayloadAction, SliceCaseReducers} from "@reduxjs/toolkit"
/**
 * Genera de forma dínamica flags asociados a distintos
 * eventos que suceden dentro de la aplicación. Por ejemplo, el envío
 * de un `request` a la API esta compuesto por múltiples eventos. Una
 * epica dentro de este módulo captura estos eventos y los convierte
 * en `flags` que puede ser consumidos por el resto de los componentes.
 * Esto evita tener que mantener el estado de cada `request` dentro
 * de cada entidad.
 */
export interface FlagsState {
  [key: string]: boolean;
}

export interface FlagPayload {
  key: string;
  value: boolean;
}

const slice = createSlice<FlagsState, SliceCaseReducers<FlagsState>>({
  name: "flags",
  initialState: {},
  reducers: {
    set(state, action: PayloadAction<FlagPayload>) {
      const {key, value} = action.payload
      state[key] = value
    }
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {set} = slice.actions
/**
 * SELECTORS
 */
export const flagsSelector = (state: any) => state.flags
export const flagIdSelector = (_: any, flagId: string) => flagId
export const flagSelector = createSelector(
  flagsSelector,
  flagIdSelector,
  (flags: FlagsState, flagId: string) => !!flags[flagId])
/**
 * EPICS
 */
const ACTION_REGEXP = /(.+)\/(.+)\/(SENT|SUCCESS|FAILURE)$/i
const STATUS_DICT: FlagsState = {
  "SENT": true,
  "SUCCESS": false,
  "FAILURE": false,
}

export const epic = (action$: Observable<PayloadAction<unknown>>): Observable<PayloadAction<unknown>> => {
  return action$.pipe(
    filter(({type}) => ACTION_REGEXP.test(type)),
    map(({type}) => {
      const [_, entity, task, status] = type.match(ACTION_REGEXP) as RegExpMatchArray
      return set({
        key: camelCase([entity, task].join(" ")),
        value: STATUS_DICT[status]
      })
    })
  )
}

