import React from "react"
import {useDispatch} from "react-redux"

import {RemotosForm} from "@pages/Remotos/RemotosForm"
import {sitesCreate} from "@redux/api"
import {useFlag} from "@hooks/useFlag"
import type {ISite} from "@models"

export const RemotosNuevo: React.FC<{}> = () => {
  const dispatch        = useDispatch()
  const sitesCreateFlag = useFlag("sitesCreate")

  return (
    <RemotosForm
      isNew={true}
      isLoading={sitesCreateFlag}
      onCreate={handleCreate}
    />
  )
  /**
   * Envía el `request` para crear el nuevo `site`.
   */
  function handleCreate(body: Partial<ISite> & {isNew: boolean}) {
    dispatch(sitesCreate(body))
  }
}