import React from "react"
import {
  Table as PatternflyTable,
  TableVariant,
  TableHeader,
  TableBody,
  IActions,
  textCenter,
} from "@patternfly/react-table"
import {Badge} from "@patternfly/react-core"
import get from "lodash/get"
import last from "lodash/last"

import {hidden} from "@utils/general/hidden"
import {defaultRowKey} from "@components/Table"
import {EmptyState} from "@components/EmptyState"
import type {MerakiStaticRoute} from "@models"

export const COLUMNS = [
  {key: "id", title: "ID", transforms: [hidden], cellTransforms: [hidden]},
  {key: "name", title: "Nombre"},
  {key: "subnet", title: "Subred"},
  {key: "gatewayIp", title: "IP de Gateway"},
  {key: "enabled", title: "Habilitada", transforms: [textCenter], cellTransforms: [textCenter]},
]

export interface RemotoStaticRoutesTableProps {
  items?: MerakiStaticRoute[];
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export const RemotoStaticRoutesTable: React.FC<RemotoStaticRoutesTableProps> = ({onDelete, onEdit, items = []}) => {
  const actions: IActions = []

  if (onEdit !== undefined) {
    actions.push({title: "Editar", onClick: (_, __, rowData) => onEdit(rowData["id"].title)})
  }

  if (onDelete !== undefined) {
    actions.push({title: "Eliminar", onClick: (_, __, rowData) => onDelete(rowData["id"].title)})
  }

  return (
    <>
      <PatternflyTable
        aria-label="table"
        cells={COLUMNS}
        rows={transformRows(items)}
        variant={TableVariant.compact}
        {...(actions.length > 0 && { actions })}
      >
        <TableHeader />
        <TableBody
          rowKey={defaultRowKey}
        />
      </PatternflyTable>
      {items.length === 0 && <EmptyState title="Tabla vacía" body="No existen Rutas Estáticas configuradas en este Remoto"/>}
    </>
  )

  function transformRows(items: MerakiStaticRoute[]): {cells: any}[] {
    if (items === undefined) return []
    return items.map((item: MerakiStaticRoute) => ({
      cells: COLUMNS.map((column) => {
        const value =  get(item, column.key)

        if (column.key === "id") {
          return {title: value, formatters: [(id: string) => last(id.split("-")) + "..."]}
        }

        if (column.key === "enabled") {
          return {title: value === true ? <Badge className="green">Si</Badge> : <Badge className="red">No</Badge>}
        }

        return value
      })
    }))
  }
}