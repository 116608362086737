import React from "react"
import {Flex, FlexItem} from "@patternfly/react-core"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"
import ReactApexChart from "react-apexcharts";

import "./RemotoInterfacesCharts.css"

import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {useError} from "@hooks/useError"
import {useSiteUsage} from "@hooks/useSiteUsage"

export interface Props {
  id: string;
}

const TITLES: string[] = [
  "Ancho de banda entrante",
  "Ancho de banda saliente",
]

const COLORS: string[][] = [
  ["rgb(126, 178, 109)", "rgb(234, 184, 57)"],
  ["rgb(87, 148, 242)", "rgb(184, 119, 217)"],
]

const OFFSET = 1000 * 60 * 60 * 3

export function RemotoInterfacesCharts({id}: Props) {
  const usage                                   = useSiteUsage(id)
  const sitesReadManagementInterfacesUsageError = useError("sitesReadManagementInterfacesUsage")

  if (sitesReadManagementInterfacesUsageError) {
    return (
      <EmptyState
        title={sitesReadManagementInterfacesUsageError.type}
        body={sitesReadManagementInterfacesUsageError.message}
        icon={RobotIcon}
      />
    )
  }

  if (usage === undefined || Array.isArray(usage) === false) {
    return <Loader content="Obteniendo datos..." />
  }

  return (
    <Flex direction={{ default: "column" }}>
      {usage.map((metric, index) => (
        <FlexItem key={index} className="RemotoInterfacesCharts__container">
          <ReactApexChart 
            options= {options({title: TITLES[index], colors: COLORS[index]})}
            series={metric.result.map(port => ({
              name: `Puerto ${port.metric.labels.ifIndex}`,
              data: port.values.map(v => [new Date(v.time).getTime() - OFFSET, Number(v.value.toFixed(2))])
            }))}
            type="area"
            height="300"
          />
        </FlexItem>
      ))}
    </Flex>
  )
}

function options({
  title = "Ancho de Banda Entrante",
  colors = ["rgb(87, 148, 242)", "rgb(184, 119, 217)"]
}) {
  return {
    chart: {
      group: "RemotoInterfaces",
      defaultLocale: "es",
      locales: [{
        name: 'es',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
          days: ['Domingo', 'Lunes', 'Marter', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
          shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección de Zoom',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            pan: 'Paneo',
            reset: 'Resetear Zoom',
          }
        }
      }],
      fontFamily: "'RedHatText', 'Overpass', overpass, helvetica, arial, sans-serif",
      type: "area",
      zoom: {
        autoScaleYaxis: false
      }
    },
    dataLabels: {enabled: false},
    stroke: {curve: "straight", width: 1},
    title: {text: title, align: "left"},
    xaxis: {type: "datetime", tooltip: {enabled: true}},
    yaxis: {opposite: false, title: {text: "Mbps"}, forceNiceScale: true, labels: {minWidth: 10}},
    legend: {horizontalAlign: "left"},
    markers: {size: 0, style: "hollow"},
    fill: {type: "gradient", gradient: {shadeIntensity: 1, opacityFrom: 0.1, opacityTo: 0.9, stops: [0, 100]}},
    tooltip: {enabled: true, x: {show: true, format: "dd/MM HH:mm"}, onDatasetHover: {highlightDataSeries: true}},
    colors,
  }
}