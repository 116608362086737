import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotoStaticRouteFormProps} from "./RemotoStaticRouteForm"
import {RemotoStaticRouteForm} from "./RemotoStaticRouteForm"

export interface RemotoStaticRouteModalFormProps extends RemotoStaticRouteFormProps {
  onClose: () => void;
  title?: string;
}

export const RemotoStaticRouteModalForm: React.FC<RemotoStaticRouteModalFormProps> = ({
  title = "Nueva Ruta",
  onClose,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title={title}
      showClose={true}
      onClose={onClose}
    >
      <RemotoStaticRouteForm {...props} onCancel={onCancel || onClose} />
    </Modal>
  )
}