import React from "react"
import {useSelector} from "react-redux"

import {siteSelector} from "@redux/state/sites"
import type {ISite} from "@models"

export const useSite = (siteId: string) => {
  const siteCallback        = React.useCallback(state => siteSelector(state, siteId), [siteId])
  const site                = useSelector(siteCallback)
  const [result, setResult] = React.useState<ISite|undefined>(site)

  React.useEffect(() => {
    if (site !== undefined) {
      setResult({...site})
    }
  }, [site])

  return result;
}

