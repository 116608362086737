import React from "react"
import {Button} from "@patternfly/react-core"
import {Link} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {Loader} from "@components/Loader"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useFlag} from "@hooks/useFlag"
import {UsuariosTable} from "@pages/Usuarios/UsuariosTable"
import {usersList, usersDestroy} from "@redux/api"
import {usersSelector} from "@redux/state/users"
import {useFuse} from "@hooks/useFuse"

const fuseOptions = {keys: ["name", "lastName", "email"]}

export const Usuarios: React.FC<{}> = () => {
  const [destroyId, setDestroyId] = React.useState<string|undefined>()
  const [filter, setFilter]       = React.useState<string>("")
  const dispatch                  = useDispatch()
  const users                     = useSelector(usersSelector)
  const usersCreateFlag           = useFlag("usersCreate")
  const usersDestroyFlag          = useFlag("usersDestroy")
  const usersListFlag             = useFlag("usersList")
  const usersUpdateFlag           = useFlag("usersUpdate")
  const fuse                      = useFuse(users, filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (usersListFlag && users.length === 0) {
    return <Loader content="Actualizando la lista de Usuarios" />
  }

  return (
    <>
      {destroyId &&
        <SmallModal
          onAccept={handleDelete}
          onCancel={toggleModal}
          onAcceptButtonText="Si, quiero eliminar al usuario"
        >
          <p>¿Esta seguro que quiere eliminar a este usuario?</p>
        </SmallModal>
      }
      <Toolbar onChange={setFilter} value={filter}>
        <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={usersListFlag} style={{marginRight: "1rem"}}>
          {usersListFlag ? "Actualizando..." : "Actualizar" }
        </Button>
        <Link to="/usuarios/nuevo">
          <Button icon={<PlusIcon />} iconPosition="left" type="button" variant="primary">
            Nuevo Usuario
          </Button>
        </Link>
      </Toolbar>
      {usersDestroyFlag || usersCreateFlag || usersUpdateFlag
        ? <Loader content="Actualizando la lista de usuarios..." />
        : <UsuariosTable
            loading={users.length === 0 && usersListFlag}
            items={filter === "" ? users : fuse}
            onDelete={toggleModal}
          />
      }
    </>
  )
  /**
   * Actualiza la lista de usuarios.
   */
  function handleRefresh() {
    dispatch(usersList())
  }
  /**
   * Gestiona la eliminación de un usuario.
   */
  function handleDelete() {
    if (destroyId !== undefined) {
      dispatch(usersDestroy({id: destroyId}))
    }
    setDestroyId(undefined)
  }
  /**
   * Abre y cierra el modal de eliminación de usuarios.
   */
  function toggleModal(id?: string) {
    setDestroyId(id)
  }
}