import React from "react"
import {Button} from "@patternfly/react-core"
import {useDispatch} from "react-redux"
import cloneDeep from "lodash/cloneDeep"
import find from "lodash/find"
import isEqual from "lodash/isEqual"
import sortBy from "lodash/sortBy"
import zip from "lodash/zip"

import {Loader} from "@components/Loader"
import {RemotoTableHeader} from "@pages/Remotos/:id/RemotoTableHeader"
import {RemotoVLANModalForm} from "@pages/Remotos/:id/VLANs/RemotoVLANModalForm"
import {RemotoVLANsTable} from "@pages/Remotos/:id/VLANs/RemotoVLANsTable"
import {requestWaterfall} from "@redux/helpers"
import {sitesListVLANs, sitesUpdateVLANsSettings, sitesUpdateVLANs} from "@redux/api"
import {SmallModal} from "@components/SmallModal"
import {useFlag} from "@hooks/useFlag"
import {useModal} from "@hooks/useModal"
import type {MerakiVLAN, ISite} from "@models"

export const RemotoVLANsView: React.FC<{site: ISite, vlansEnabled: boolean}> = ({site, vlansEnabled}) => {
  const dispatch                                                                                 = useDispatch()
  const [vlan, setVlan]                                                                          = React.useState<MerakiVLAN|undefined>(undefined)
  const [vlans, setVlans]                                                                        = React.useState<MerakiVLAN[]|undefined>(undefined)
  const {isOpen, openModal, closeModal}                                                          = useModal(false)
  const {isOpen: isDestroyModalOpen, openModal: openDestroyModal, closeModal: closeDestroyModal} = useModal(false)
  const sitesListVLANsFlag                                                                       = useFlag("sitesListVlaNs")
  const sitesUpdateVLANsFlag                                                                     = useFlag("sitesUpdateVlaNs")
  const sitesUpdateVLANsSettingsFlag                                                             = useFlag("sitesUpdateVlaNsSettings")

  React.useEffect(() => {
    handleRefresh()
  }, [])

  React.useEffect(() => {
    if (vlans === undefined && site.vlans !== undefined) {
      setVlans(cloneDeep(site.vlans))
    }
  }, [site])

  if (vlans === undefined) {
    return <Loader content="Obteniendo la lista de VLANs..."/>
  }

  return (
    <div className="RemotoVLANsView">
      <RemotoTableHeader
        title="Tabla de VLANs"
        isLoading={sitesListVLANsFlag || sitesUpdateVLANsFlag || sitesUpdateVLANsSettingsFlag}
        onRefresh={handleRefresh}
        addButtonText="Agregar VLAN"
        onAdd={openModal}
      />
      <RemotoVLANsTable
        items={sortBy(vlans, "id")}
        onDelete={selectVLANForDeletion}
        onEdit={handleEdit}
      />
      {isOpen && vlans &&
        <RemotoVLANModalForm
          title={vlan === undefined ? "Nueva VLAN" : "Editar VLAN"}
          data={vlan}
          onSubmit={handleSubmit}
          onClose={closeModal}
          isLoading={false}
        />
      }
      {isDestroyModalOpen &&
        <SmallModal
          title="Atención"
          onAcceptButtonText="Si, quiero eliminar la VLAN"
          onAccept={handleDelete}
          onCancel={closeDestroyModal}
        >
          <div>
            <p>¿Esta seguro que desea eliminar esta VLAN?</p>
            <br/>
            <p><small><b>* Los cambios no se aplicarán hasta que guarde los cambios.</b></small></p>
            <p><small><b>** Puede refrescar la página para deshacer esta acción.</b></small></p>
          </div>
        </SmallModal>
      }
      {hasChanges() && !(sitesListVLANsFlag || sitesUpdateVLANsFlag || sitesUpdateVLANsSettingsFlag) &&
        <Button
          isBlock={true}
          type="button"
          variant="primary"
          onClick={handleSaveChanges}
        >
          Guardar Cambios
        </Button>
      }
    </div>
  )
  /**
   * Refresca la Configuración de `vlans` del `site`.
   */
  function handleRefresh() {
    dispatch(sitesListVLANs({id: site.id}))
  }
  /**
   * Seleeciona la VLAN correcta y abre el modal para recibir
   * la confirmación del usuario.
   */
  function selectVLANForDeletion(id: number) {
    setVlan(find(vlans, item => item.id === id))
    openDestroyModal()
  }
  /**
   * Verífica si se ha realizado aglgún cambio en la configuración
   * de las VLANs.
   */
  function hasChanges(): boolean {
    return vlansEnabled !== site.vlansEnabled || vlansAreEqual(vlans, site.vlans) === false
  }
  /**
   * Aplica los cambios de configuración correspondientes.
   */
  function handleSaveChanges() {
    let actions: any[] = []
    if (vlansEnabled !== site.vlansEnabled) {
      actions.push(sitesUpdateVLANsSettings({id: site.id, vlansEnabled}))
    }
    if (vlansAreEqual(vlans, site.vlans) === false && vlans !== undefined) {
      actions.push(sitesUpdateVLANs({id: site.id, vlans}))
    }
    dispatch(requestWaterfall(actions))
  }
  /**
   * Selecciona la VLAN correcta y abre el modal de edición.
   */
  function handleEdit(id: number) {
    setVlan(find(vlans, item => item.id === id))
    openModal()
  }
  /**
   * Elimina la VLAN seleccionada.
   */
  function handleDelete() {
    if (Array.isArray(vlans) && vlan !== undefined) {
      setVlans(vlans.filter(item => item.id !== vlan.id))
    }
  }
  /**
   * Aplica las modificaciones realizadas a la VLAN seleccionada.
   */
  function handleSubmit(data: MerakiVLAN) {
    if (Array.isArray(vlans)) {
      setVlans([...vlans.filter(item => item.id !== vlan?.id), data])
    }
    setVlan(undefined)
    closeModal()
  }
}
/**
 * Verífica que dos listas de `vlans` sean iguales.
 */
function vlansAreEqual(a: MerakiVLAN[] | undefined, b: MerakiVLAN[] | undefined): boolean {
  return zip(sortBy(a, "id"), sortBy(b, "id")).every(([left, right]) => isEqual(left, right))
}