import React from "react"
import {Button} from "@patternfly/react-core"
import {Link, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {Loader} from "@components/Loader"
import {networksRead, networksListSites} from "@redux/api"
import {RedErrorState} from "@pages/Redes/RedErrorState"
import {RedLayout} from "@pages/Redes/:id/RedLayout"
import {RemotosTable} from "@pages/Remotos/RemotosTable"
import {Toolbar} from "@components/Toolbar"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useNetwork} from "@hooks/useNetwork"
import {useIsAdmin} from "@hooks/useIsAdmin"
import {useFuse} from "@hooks/useFuse"
import {SmallModal} from "@components/SmallModal"
import {sitesDestroy} from "@redux/api"


const fuseOptions = {keys: ["id", "networkId", "mac", "model", "serial", "address"]}

export const Red: React.FC<{}> = () => {
  const [remotoDestroyId, setRemotoDestroyId] = React.useState<string|undefined>()
  const [filter, setFilter]    = React.useState<string>("")
  const {id}                   = useParams<{id: string}>()
  const dispatch               = useDispatch()
  const isAdmin                = useIsAdmin()
  const network                = useNetwork(id)
  const networksDestroyFlag    = useFlag("networksDestroy")
  const networksListSitesError = useError("networksListSites")
  const networksListSitesFlag  = useFlag("networksListSites")
  const sitesDestroyFlag          = useFlag("sitesDestroy")
  const fuse                   = useFuse(network && network.sites ? network.sites : [], filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (network === undefined) {
    return <Loader content="Obteniendo información de la Red..." />
  }

  return (
    <RedLayout item={network}>
      {remotoDestroyId &&
        <SmallModal
          onAccept={handleDeleteRemoto}
          onCancel={toggleModal}
          onAcceptButtonText="Si, quiero eliminar al Remoto"
        >
          <p>¿Esta seguro que quiere eliminar este remoto?</p>
        </SmallModal>
      }
      <Toolbar onChange={setFilter} value={filter}>
        <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={networksListSitesFlag || networksDestroyFlag} style={{marginRight: "1rem"}}>
          {networksListSitesFlag || networksDestroyFlag ? "Actualizando..." : "Actualizar" }
        </Button>
        {isAdmin &&
          <Link to={`/redes/${id}/nuevo-remoto`}>
            <Button icon={<PlusIcon />} type="button" variant="primary">Nuevo Remoto</Button>
          </Link>
        }
      </Toolbar>
      {network.sites === undefined || sitesDestroyFlag
        ? <Loader content="Actualizando la lista de Remotos..." />
        : networksListSitesError !== undefined
          ? <RedErrorState error={networksListSitesError} />
          : <RemotosTable
              showNetworkId={false}
              loading={network.sites.length === 0 && networksListSitesFlag}
              items={filter === "" ? network.sites : fuse}
              onDelete={toggleModal}
            />}
    </RedLayout>
  )
  /**
   * Actualiza la `network` y lista de `sites`.
   */

  function handleRefresh() {
    dispatch(networksRead({id}))
    dispatch(networksListSites({id}))
  }
  function handleDeleteRemoto() {
    if (remotoDestroyId !== undefined) {
      dispatch(sitesDestroy({id: remotoDestroyId}))
    }
    setRemotoDestroyId(undefined)
  }
  function toggleModal(id?: string) {
    setRemotoDestroyId(id)
  }
 
}