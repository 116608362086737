import React from "react"
import {useSelector} from "react-redux"

import {siteManagementInterfacesSelector} from "@redux/state/sites"
import type {MerakiManagementInterfaces} from "@models"

export const useSiteManagementInterfaces = (siteId: string) => {
  const siteManagementInterfacesCallback = React.useCallback((state) => siteManagementInterfacesSelector(state, siteId), [siteId])
  const siteManagementInterfaces = useSelector(siteManagementInterfacesCallback)
  const [result, setResult] = React.useState<MerakiManagementInterfaces|undefined>(siteManagementInterfaces)

  React.useEffect(() => {
    if (siteManagementInterfaces !== undefined) {
      setResult({...siteManagementInterfaces})
    }
  }, [siteManagementInterfaces])

  return result;
}