import React from "react"
import {Route, Redirect, Switch} from "react-router-dom"

import {PageLayoutWithRouter as PageLayout} from "@components/PageLayout"
import {Notifications} from "@pages/Notifications"
import {NotFound} from "@pages/NotFound"
// Redes
import {Redes} from "@pages/Redes"
import {Red} from "@pages/Redes/:id"
// Remotos
import {Remotos} from "@pages/Remotos"
import {Remoto} from "@pages/Remotos/:id"
import {RemotoInterfaces} from "@pages/Remotos/:id/Interfaces"
import {RemotoVLANs} from "@pages/Remotos/:id/VLANs"
import {RemotoRutasEstaticas} from "@pages/Remotos/:id/RutasEstaticas"
import {RemotoPuertos} from "@pages/Remotos/:id/Puertos"
import {RemotosFiltros} from "@pages/Remotos/:id/Filtros/RemotosFiltros"

export const MultiNetworkRoot: React.FC<{}> = () => {
  return (
    <>
      <Notifications />
      <PageLayout>
        <Switch>
          {/* HOME */}
          <Route exact path="/" render={() => <Redirect to="/redes" />} />
          {/* Remotos */}
          <Route path="/remotos/nuevo" render={() => <Redirect to="/redes" />} />
          <Route path="/remotos/:id/interfaces" component={RemotoInterfaces} />
          <Route path="/remotos/:id/vlans" component={RemotoVLANs} />
          <Route path="/remotos/:id/puertos" component={RemotoPuertos} />
          <Route path="/remotos/:id/filtros" component={RemotosFiltros} />
          <Route path="/remotos/:id/rutas-estaticas" component={RemotoRutasEstaticas} />
          <Route path="/remotos/:id" component={Remoto} />
          <Route path="/remotos" component={Remotos} />
          {/* Redes */}
          <Route path="/redes/nueva" render={() => <Redirect to="/redes" />} />
          <Route path="/redes/:id/nuevo-remoto" render={() => <Redirect to="/redes" />} />
          <Route path="/redes/:id" component={Red} />
          <Route path="/redes" component={Redes} />
          {/* Usuarios */}
          <Route path="/usuarios/nuevo" render={() => <Redirect to="/redes" />} />
          <Route path="/usuarios" render={() => <Redirect to="/redes" />} />
          {/* Clientes */}
          <Route path="/clientes/nuevo" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id/editar" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id/redes" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id/usuarios" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id/agregar-usuarios" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id/nueva-red" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes/:id" render={() => <Redirect to="/redes" />} />
          <Route path="/clientes" render={() => <Redirect to="/redes" />} />
          {/* 404 */}
          <Route path="/" component={NotFound} />
        </Switch>
      </PageLayout>
    </>
  )
}