import React from "react"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import {Flex, FlexItem, Divider, Title, TitleSizes} from "@patternfly/react-core"
import RobotIcon from "@patternfly/react-icons/dist/js/icons/robot-icon"

import "./RemotoVLANs.css"

import {Loader} from "@components/Loader"
import {EmptyState} from "@components/EmptyState"
import {RemotoLayout} from "@pages/Remotos/:id/RemotoLayout"
import {RemotoSingleLanView} from "@pages/Remotos/:id/VLANs/RemotoSingleLanView"
import {RemotoTabs} from "@pages/Remotos/:id/RemotoTabs"
import {RemotoVLANsSwitch} from "@pages/Remotos/:id/VLANs/RemotoVLANsSwitch"
import {RemotoVLANsView} from "@pages/Remotos/:id/VLANs/RemotoVLANsView"
import {sitesRead, sitesReadVLANsSettings} from "@redux/api"
import {useSite} from "@hooks/useSite"
import {useError} from "@hooks/useError"

export const RemotoVLANs: React.FC<{}> = () => {
  const dispatch                        = useDispatch()
  const {id}                            = useParams<{id: string}>()
  const site                            = useSite(id)
  const sitesReadVlaNsSettingsError     = useError("sitesReadVlaNsSettings")
  const [vlansEnabled, setVlansEnabled] = React.useState<boolean|undefined>(site?.vlansEnabled)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  React.useEffect(() => {
    if (vlansEnabled === undefined && site?.vlansEnabled !== undefined) {
      setVlansEnabled(site?.vlansEnabled)
    }
  }, [site])

  if (site === undefined) return <Loader content="Obteniendo información del Remoto..."/>

  return (
    <RemotoLayout item={site} className="RemotoVLANs">
      <RemotoTabs
        activeKey="vlans"
        isLoading={sitesReadVlaNsSettingsError === undefined && vlansEnabled === undefined}
        content="Obteniendo la configuración de VLANs..."
      >
        {sitesReadVlaNsSettingsError
          ? <EmptyState
              title={sitesReadVlaNsSettingsError.type}
              body={sitesReadVlaNsSettingsError.message}
              icon={RobotIcon}
            />
          : <>
            <Flex justifyContent={{default: "justifyContentSpaceBetween"}}>
              <FlexItem>
                <Title className="DataListTitle" headingLevel="h4" size={TitleSizes.lg}>
                  {vlansEnabled ? "Configuración de VLANs" : "Configuración de Red"}
                </Title>
              </FlexItem>
              <FlexItem>
                <RemotoVLANsSwitch isChecked={!!vlansEnabled} onChange={setVlansEnabled} />
              </FlexItem>
            </Flex>
            <Divider />
            {vlansEnabled
              ? <RemotoVLANsView site={site} vlansEnabled={!!vlansEnabled}/>
              : <RemotoSingleLanView site={site} vlansEnabled={!!vlansEnabled}/>
            }
            </>
        }
      </RemotoTabs>
    </RemotoLayout>
  )
  /**
   * Actualiza el `site` y sus `vlansSettings`.
   */
  function handleRefresh() {
    if (site === undefined) {
      dispatch(sitesRead({id}))
    }
    dispatch(sitesReadVLANsSettings({id}))
  }
}
