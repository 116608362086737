import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotoSingleLanFormProps} from "./RemotoSingleLanForm"
import {RemotoSingleLanForm} from "./RemotoSingleLanForm"

export interface RemotoSingleLanModalFormProps extends RemotoSingleLanFormProps {
  onClose: () => void;
}

export const RemotoSingleLanModalForm: React.FC<RemotoSingleLanModalFormProps> = ({
  onClose,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title="Configuración de Red"
      showClose={true}
      onClose={onClose}
    >
      <RemotoSingleLanForm {...props} onCancel={onCancel || onClose} />
    </Modal>
  )
}