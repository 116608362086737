import React from "react"
import {Button} from "@patternfly/react-core"
import {Link} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {ClientesTable} from "@pages/Clientes/ClientesTable"
import {clientsList, clientsDestroy} from "@redux/api"
import {clientsSelector} from "@redux/state/clients"
import {Loader} from "@components/Loader"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useFlag} from "@hooks/useFlag"
import {useFuse} from "@hooks/useFuse"

const fuseOptions = {keys: ["id", "name"]}

export const Clientes: React.FC<{}> = () => {
  const [destroyId, setDestroyId] = React.useState<string|undefined>()
  const [filter, setFilter]       = React.useState<string>("")
  const clients                   = useSelector(clientsSelector)
  const clientsCreateFlag         = useFlag("clientsCreate")
  const clientsDestroyFlag        = useFlag("clientsDestroy")
  const clientsListFlag           = useFlag("clientsList")
  const clientsUpdateFlag         = useFlag("clientsUpdate")
  const dispatch                  = useDispatch()
  const fuse                      = useFuse(clients, filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (clientsListFlag && clients.length === 0) {
    return <Loader content="Obteniendo la lista de Clientes..." />
  }

  return (
    <>
      {destroyId &&
        <SmallModal
          onAccept={() => handleDelete()}
          onCancel={() => setDestroyId(undefined)}
          onAcceptButtonText="Si, quiero eliminar a este Cliente"
          href={`/clientes`}
        >
          <p>¿Esta seguro que quiere eliminar a este Cliente?</p>
        </SmallModal>
      }
      <Toolbar onChange={setFilter} value={filter}>
        <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={clientsListFlag} style={{marginRight: "1rem"}}>
          {clientsListFlag ? "Actualizando..." : "Actualizar" }
        </Button>
        <Link to="/clientes/nuevo">
          <Button icon={<PlusIcon />} iconPosition="left" type="button" variant="primary">
            Nuevo Cliente
          </Button>
        </Link>
      </Toolbar>
      {clientsDestroyFlag || clientsCreateFlag || clientsUpdateFlag
        ? <Loader content="Actualizando la lista de Clientes..." />
        : <ClientesTable
            loading={clients.length === 0 && clientsListFlag}
            items={filter === "" ? clients : fuse}
            onDelete={(id) => setDestroyId(id)}
          />
      }
    </>
  )
  /**
   * Actualiza la lista de `clients`.
   */
  function handleRefresh() {
    dispatch(clientsList())
  }
  /**
   * Lanza el `request` para eliminar el `client` y cierra el `modal`.
   */
  function handleDelete() {
    if (destroyId !== undefined) {
      dispatch(clientsDestroy({id: destroyId}))
    }
    setDestroyId(undefined)
  }
}