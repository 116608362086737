import React from "react"
import {Button} from "@patternfly/react-core"
import {Link} from "react-router-dom"
import {useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {Loader} from "@components/Loader"
import {RemotosErrorState} from "@pages/Remotos/RemotosErrorState"
import {RemotosTable} from "@pages/Remotos/RemotosTable"
import {sitesList, sitesDestroy} from "@redux/api"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useIsAdmin} from "@hooks/useIsAdmin"
import {useSites} from "@hooks/useSites"
import {useFuse} from "@hooks/useFuse"
import {useUserNetworks} from "@hooks/useUserNetworks"

const fuseOptions = {keys: ["id", "networkId", "mac", "model", "serial", "address"]}

export const Remotos: React.FC<{}> = () => {
  const [destroyId, setDestroyId] = React.useState<string|undefined>()
  const [filter, setFilter]       = React.useState<string>("")
  const dispatch                  = useDispatch()
  const isAdmin                   = useIsAdmin()
  const networks                  = useUserNetworks()
  const sites                     = useSites()
  const sitesCreateFlag           = useFlag("sitesCreate")
  const sitesDestroyFlag          = useFlag("sitesDestroy")
  const sitesListError            = useError("sitesList")
  const sitesListFlag             = useFlag("sitesList")
  const sitesUpdateFlag           = useFlag("sitesUpdate")
  const fuse                      = useFuse(sites, filter, fuseOptions)

  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (sitesListError !== undefined) {
    return <RemotosErrorState error={sitesListError} />
  }

  if (sites === undefined) {
    return <Loader content="Obteniendo la lista de Remotos..." />
  }

  return (
    <>
      {destroyId &&
        <SmallModal
          onAccept={handleDelete}
          onCancel={toggleModal}
          onAcceptButtonText="Si, quiero eliminar al Remoto"
        >
          <p>¿Esta seguro que quiere eliminar este remoto?</p>
        </SmallModal>
      }
      <Toolbar onChange={setFilter} value={filter}>
        <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={sitesListFlag} style={{marginRight: "1rem"}}>
          {sitesListFlag ? "Actualizando..." : "Actualizar" }
        </Button>
        {isAdmin &&
          <Link to="/remotos/nuevo">
            <Button icon={<PlusIcon />} iconPosition="left" type="button" variant="primary">
              Nuevo Remoto
            </Button>
          </Link>
        }
      </Toolbar>
      {sitesDestroyFlag || sitesCreateFlag || sitesUpdateFlag
        ? <Loader content="Actualizando la lista de Remotos" />
        : <RemotosTable
            showClientName= {true}
            showNetworkId={networks.length > 1}
            loading={sites.length === 0 && sitesListFlag}
            items={filter === "" ? sites : fuse}
            onDelete={toggleModal}
          />
      }
    </>
  )
  /**
   * Actualiza la lista de `sites`.
   */
  function handleRefresh() {
    dispatch(sitesList())
  }
  /**
   * Lanza el `request` para eliminar el `site` y cierra el `modal`.
   */
  function handleDelete() {
    if (destroyId !== undefined) {
      dispatch(sitesDestroy({id: destroyId}))
    }
    setDestroyId(undefined)
  }
  /**
   * Abre o cierra el `modal` para obtener la confirmación del usuario
   * previo a eliminar la `site`.
   * @param id - Identificador único de la `site`.
   */
  function toggleModal(id?: string) {
    setDestroyId(id)
  }
}