import React from "react"
import {useDispatch} from "react-redux"

import {useFlag} from "@hooks/useFlag"
import {IUser} from "@models"
import {usersCreate} from "@redux/api"
import {UsuariosForm} from "@pages/Usuarios/UsuariosForm"

export const UsuariosNuevo: React.FC<{}> = () => {
  const dispatch        = useDispatch()
  const usersCreateFlag = useFlag("usersCreate")

  return (
    <UsuariosForm
      isNew={true}
      isLoading={usersCreateFlag}
      onCreate={handleCreate}
    />
  )

  function handleCreate(body: Partial<IUser> & {isNew: boolean}) {
    dispatch(usersCreate(body))
  }
}