import React from "react"
import {Link, withRouter, RouteComponentProps} from "react-router-dom"
import {
  Brand,
  Page,
  PageHeader,
  PageSection,
  PageSectionVariants,
  SkipToContent,
  Flex,
  FlexItem,
} from "@patternfly/react-core"
import HeadsetIcon from "@patternfly/react-icons/dist/js/icons/headset-icon"
import {Scrollbars} from "react-custom-scrollbars"

import "./PageLayout.css"

import logo from "./logo.svg";
import {PageHeaderToolbar} from './PageHeaderToolbar'
import {PageBreadcrumb} from './PageBreadcrumb'
import {PageSidebar} from "./PageSidebar"
import {useUserNetworks} from "@hooks/useUserNetworks"
import {useIsAdmin} from "@hooks/useIsAdmin"

interface LayoutProps extends RouteComponentProps {
  children: React.ReactChild
}

export const PageLayout: React.FC<LayoutProps> = ({children, location}) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false)
  const networks = useUserNetworks()
  const isAdmin = useIsAdmin()
  const showNav = isAdmin || networks.length > 1

  return (
    <Page
      className="PageLayout"
      header={
        <PageHeader
          logo={<Brand src={logo} alt="ANTEL" />}
          headerTools={<PageHeaderToolbar />}
          showNavToggle={showNav}
          onNavToggle={onNavToggle}
        />
      }
      sidebar={showNav && <PageSidebar isNavOpen={isNavOpen} location={location} />}
      isManagedSidebar={false}
    >
      <PageBreadcrumb location={window.location} />
      <PageSection variant={PageSectionVariants.light} className="PageSection --section-principal">
        <Scrollbars autoHide style={{height: "calc(100vh - 9.5rem)"}}>{children}</Scrollbars>
      </PageSection>
      <Flex className="PageFooter" justifyContent={{default: "justifyContentSpaceBetween"}} alignItems={{default: "alignItemsCenter"}}>
        <FlexItem>
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem>
              <Link to="">
                <Flex alignItems={{default: "alignItemsCenter"}}>
                  <FlexItem>
                   {/*<HeadsetIcon></HeadsetIcon>*/} 
                  </FlexItem>
                  <FlexItem>
                  {/* <div>
                      <p><small>Soporte</small></p>
                    </div> */}
                  </FlexItem>
                </Flex>
              </Link>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </Page>
  )

  function onNavToggle() {
    setIsNavOpen(!isNavOpen)
  }
}

export const PageLayoutWithRouter = withRouter(PageLayout)