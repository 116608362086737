import "@patternfly/react-core/dist/styles/base.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {App} from "./App";
import {store} from "./services/redux";

ReactDOM.render(<App store={store}/>, document.getElementById("root"));
