import React from "react"
import {useDispatch} from "react-redux"

import {useFlag} from "@hooks/useFlag"
import {IClient} from "@models"
import {ClientesForm} from "@pages/Clientes/ClientesForm"
import {clientsCreate} from "@redux/api"

export const ClientesNuevo: React.FC<{}> = () => {
  const dispatch          = useDispatch()
  const clientsCreateFlag = useFlag("clientsCreate")

  return (
    <ClientesForm
      isNew={true}
      isLoading={clientsCreateFlag}
      onCreate={handleCreate}
    />
  )

  function handleCreate(body: Partial<IClient> & {isNew: boolean}) {
    dispatch(clientsCreate(body))
  }
}