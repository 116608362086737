import React from "react"
import {Button} from "@patternfly/react-core"
import {Link, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import PlusIcon from "@patternfly/react-icons/dist/js/icons/plus-icon"

import {ClienteLayout} from "@pages/Clientes/:id/ClienteLayout"
import {ClienteTabs} from "@pages/Clientes/:id/ClienteTabs"
import {clientsRead, clientsListNetworks, networksDestroy} from "@redux/api"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RedesTable} from "@pages/Redes/RedesTable"
import {SmallModal} from "@components/SmallModal"
import {Toolbar} from "@components/Toolbar"
import {useClient} from "@hooks/useClient"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import {useFuse} from "@hooks/useFuse"

const fuseOptions = {keys: ["id", "clientId", "status"]}

export const ClienteRedes: React.FC<{}> = () => {
  const [filter, setFilter]                   = React.useState<string>("")
  const [removeNetworkId, setRemoveNetworkId] = React.useState<string|undefined>(undefined)
  const {id}                                  = useParams<{id: string}>()
  const client                                = useClient(id)
  const clientsListNetworksFlag               = useFlag("clientsListNetworks")
  const clientsReadError                      = useError("clientsRead")
  const dispatch                              = useDispatch()
  const fuse                                  = useFuse(client && client.networks ? client.networks : [], filter, fuseOptions)
  const networksDestroyFlag                   = useFlag("networksDestroy")


  React.useEffect(() => {
    handleRefresh()
  }, [])

  if (clientsReadError !== undefined) {
    return (
      <EmptyState title="Cliente no encontrado">
        <div>
          <p>No se ha encontrado un Cliente con el id {id}.</p>
          <br/>
          <Link to="/clientes">{"<< Volver a la lista de Clientes"}</Link>
        </div>
      </EmptyState>
    )
  }

  if (client === undefined) {
    return <Loader content="Obteniendo información del Cliente..." />
  }

  return (
    <ClienteLayout item={client}>
      <ClienteTabs activeKey={"redes"}>
        {client.networks === undefined
          ? <Loader content={`Actualizando la lista de Redes del Cliente: ${id}`} />
          : <>
              {removeNetworkId !== undefined &&
                <SmallModal onAccept={handleRemoveNetwork} onCancel={toggleModal}>
                  <p>¿Esta seguro que desea eliminar la red {removeNetworkId}?</p>
                  <br/>
                  <p><small><b>* Perderá toda la información relacionada a esta red.</b></small></p>
                  <p><small><b>** Esta acción no puede revertirse.</b></small></p>
                </SmallModal>
              }
              <Toolbar onChange={setFilter} value={filter}>
                <Button type="button" variant="secondary" onClick={handleRefresh} isLoading={clientsListNetworksFlag || networksDestroyFlag} style={{marginRight: "1rem"}}>
                  {clientsListNetworksFlag || networksDestroyFlag ? "Actualizando..." : "Actualizar" }
                </Button>
                <Link to={`/clientes/${id}/nueva-red`}>
                  <Button icon={<PlusIcon />} type="button" variant="primary">Nueva red</Button>
                </Link>
              </Toolbar>
              <RedesTable
                items={filter === "" ? client.networks : fuse}
                loading={client.networks.length === 0 && clientsListNetworksFlag}
                showClientId={false}
                onDelete={toggleModal}
              />
            </>
        }
      </ClienteTabs>
    </ClienteLayout>
  )
  /**
   * Actualiza la `network` y lista de `sites`.
   */
  function handleRefresh() {
    dispatch(clientsRead({id}))
    dispatch(clientsListNetworks({id}))
  }
  /**
   * Abre o cierra el `modal` para confirmar la eliminación de la `network`.
   * @param id - Identificador único de la `network`.
   */
  function toggleModal(id?: string) {
    setRemoveNetworkId(id)
  }
  /**
   * Envía el `request` para eliminar la `network`.
   */
  function handleRemoveNetwork() {
    if (removeNetworkId !== undefined) {
      dispatch(networksDestroy({id: removeNetworkId}))
    }
  }
}