import React from "react"
import {Link, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"

import {clientsRead, networksCreate} from "@redux/api"
import {EmptyState} from "@components/EmptyState"
import {Loader} from "@components/Loader"
import {RedesForm} from "@pages/Redes/RedesForm"
import {useClient} from "@hooks/useClient"
import {useError} from "@hooks/useError"
import {useFlag} from "@hooks/useFlag"
import type {INetwork} from "@models"

export const ClienteNuevaRed: React.FC<{}> = () => {
  const dispatch           = useDispatch()
  const {id}               = useParams<{id: string}>()
  const client             = useClient(id)
  const clientsReadFlag    = useFlag("clientsRead")
  const networksCreateFlag = useFlag("networksCreate")
  const clientsReadError   = useError("clientsRead")

  React.useEffect(() => {
    if (client === undefined) {
      dispatch(clientsRead({id}))
    }
  }, [])

  if (clientsReadError !== undefined) {
    return (
      <EmptyState title="Cliente no encontrado">
        <div>
          <p>No se ha encontrado un Cliente con el id {id}.</p>
          <br/>
          <Link to="/clientes">{"<< Volver a la lista de Clientes"}</Link>
        </div>
      </EmptyState>
    )
  }

  if (clientsReadFlag || client === undefined) {
    return <Loader content="Obteniendo información del Cliente" />
  }

  return <RedesForm
    clientId={id}
    isNew={true}
    onCreate={handleCreate}
    isLoading={networksCreateFlag}
  />

  function handleCreate(body: Partial<INetwork> & {isNew: boolean;}) {
    dispatch(networksCreate(body))
  }
}