import React from "react"

import "./EmptyState.css"

import {
  EmptyState as PatternflyEmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  Title,
} from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/js/icons/search-icon';

export interface EmptyStateProps {
  animated?: boolean;
  body?    : string;
  icon?    : React.ComponentType<any>
  title    : string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  animated = false,
  body,
  children,
  icon=SearchIcon,
  title,
}) => (
  <PatternflyEmptyState className="EmptyState">
    <EmptyStateIcon icon={icon} className={animated ? "orbit" : ""}/>
    <Title headingLevel="h5" size="lg">
      {title}
    </Title>
    <EmptyStateBody>
      {body || children}
    </EmptyStateBody>
  </PatternflyEmptyState>
)