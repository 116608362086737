import {combineEpics} from "redux-observable"
import {concat, of, interval, race} from "rxjs"
import {createSlice} from "@reduxjs/toolkit"
import {filter, mergeMap, mapTo, take, map} from "rxjs/operators"
import get from "lodash/get"
import isString from "lodash/isString"
import last from "lodash/last"
import template from "lodash/template"
import uniqueId from "lodash/uniqueId"
import type {Observable} from "rxjs"
import type {PayloadAction} from "@reduxjs/toolkit"

import notifications from "./notifications.json"
import type {MetaPayloadAction} from "../types"
/**
 * Gestiona las notificaciones de la aplicación de forma centralizada.
 * Identifica los mensajes de error y crea las notificaciones pertinentes.
 */
export interface INotification {
  key: string;
  variant: "success"|"danger"|"warning"|"info"|"default";
  title: string;
  link?: string;
  message?: string;
  refresh?: boolean;
}

const slice = createSlice({
  name: "notifications",
  initialState: [] as INotification[],
  reducers: {
    push(state, action: PayloadAction<INotification>) {
      state.push(action.payload)
    },
    pop(state, action: PayloadAction<string>) {
      return state.filter((notification) => notification.key !== action.payload)
    }
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {push, pop} = slice.actions
/**
 * SELECTORS
 */
export const notificationsSelector = (state: any): INotification[] => state.notifications
/**
 * EPICS
 */
// Todas las acciones de tipo `SUCCESS` o `FAILURE`
const ACTION_REGEXP = /(.+)\/(.+)\/(SUCCESS|FAILURE)$/i
// Tiempo de vida de la notificación (30 segundos)
const NOTIFICATION_LIFE = 1000 * 30

export const requestNotifications = (action$: Observable<MetaPayloadAction>): Observable<PayloadAction<unknown>> => {
  return action$.pipe(
    filter(({type}) => ACTION_REGEXP.test(type)),
    map(({type, payload, meta}) => {
      // Buscamos el `id` en los metadatos del `request` si no lo encontramos buscamos en el `payload`.
      const id = (isString(meta) ? meta : get(meta, "id")) || get(payload, "id")
      const key = uniqueId("Notification/")

      let {title, message} = get(notifications, type.replace(/\//g, "."), {}) as {title: string, message?: string}

      if (message === undefined) {
        message = get(payload, "message", "Error inesperado")
      }

      const action: INotification = {
        key,
        variant: last(type.split("/")) === "SUCCESS" ? "success" : "danger",
        title: template(title)({id}),
        message: template(message)({id}),
      }

      return action
    }),
    // Filtramos aquellas acciones que no tienen un título definido.
    filter(action => action.title !== ""),
    mergeMap((action) => concat(
      of(push(action)),
      race(
        // Tomamos la acción del usuario para eliminar la notificación
        // o emitimos un evento para su eliminación despues de transcurrir
        // la vida de la notificación identificada por la constante
        // `NOTIFICATION_LIFE`.
        interval(NOTIFICATION_LIFE).pipe(take(1), mapTo(pop(action.key))),
        action$.pipe(
          filter(({type,  payload}) => type === pop.toString() && payload === action.key),
          mapTo({type: "NO_OP", payload: undefined})
        ),
      ).pipe(
        filter(({type}) => type !== "NO_OP")
      )
    ))
  )
}

export const epic = combineEpics(requestNotifications)
