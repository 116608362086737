import React from "react";
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";
import isObject from "lodash/isObject";
import { Pagination } from "@patternfly/react-core";
import {
  Table as PatternflyTable,
  TableVariant,
  TableHeader,
  TableBody,
  ICell,
  IActions,
  OnSelect,
  sortable,
  SortByDirection,
} from "@patternfly/react-table";

import { EmptyState } from "@components/EmptyState";


interface ColCustom {
  key: string;
  title: string;
}

interface TableProps {
  canSelectAll?: boolean;
  className?: string;
  columns: (string | ICell | ColCustom)[];
  items: any[];
  loading?: boolean;
  onDelete?: (id: any) => void;
  onEdit?: (id: any) => void;
  onSelect?: (id: string, value: boolean) => void;
  rowKey?: (rowData: { rowData: any }) => any;
  transformRows: (items: any[]) => { cells: any }[];
}



const PER_PAGE_OPTIONS = [
  { title: "5", value: 5 },
  { title: "10", value: 10 },
  { title: "20", value: 20 },
  { title: "50", value: 50 },
  { title: "100", value: 100 },
];

export const Table: React.FC<TableProps> = ({
  canSelectAll,
  className = "",
  columns,
  items,
  loading = false,
  onDelete,
  onEdit,
  onSelect,
  rowKey = defaultRowKey,
  transformRows,
}) => {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(PER_PAGE_OPTIONS[3].value);
  const [rows, setRows] = React.useState(
    items.slice((page - 1) * perPage, perPage * page)
  );


  const actions: IActions = [];

  if (onEdit !== undefined) {
    actions.push({
      title: "Editar",
      onClick: (_, __, rowData) => onEdit(defaultRowKey({ rowData })),
    });
  }

  if (onDelete !== undefined) {
    actions.push({
      title: "Eliminar",
      onClick: (_, __, rowData) => onDelete(defaultRowKey({ rowData })),
    });
  }

  const handleSelect: OnSelect = (_, isSelected, __, rowData) =>
    onSelect && onSelect(defaultRowKey({ rowData }), isSelected);

  React.useEffect(() => {
    setRows(items.slice((page - 1) * perPage, perPage * page));
  }, [items, page, perPage]);


  const [activeSortIndex, setActiveSortIndex] = React.useState(-1);
  // sort direction of the currently active column
  const [activeSortDirection, setActiveSortDirection] = React.useState('none');
  const onSort = (event: React.MouseEvent, index: number, direction: string) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
    // sorts the rows
    //console.log(columns)
    let sortCol = columns[index] as ColCustom;
    const keyName: string = sortCol.key
    const updatedRows = rows.sort((a, b) => {
      if (a[keyName] > b[keyName]) {
        return 1;
      }

      if (a[keyName] < b[keyName]) {
        return -1;
      }

      return 0;
    });
    if (activeSortDirection == 'asc') {
      setActiveSortDirection('desc')
      setRows(updatedRows.reverse());
    }
    else {
      setActiveSortDirection('asc')
      setRows(updatedRows);
    }

  };

  return (
    <>
      <Pagination
        isCompact
        itemCount={items.length}
        page={page}
        perPage={perPage}
        defaultToFullPage
        onSetPage={handleOnSetPage}
        onPerPageSelect={handlePerPageSelect}
        perPageOptions={PER_PAGE_OPTIONS}
        variant="top"
        titles={{ perPageSuffix: "por página" }}
        toggleTemplate={({ firstIndex, lastIndex, itemCount }) => (
          <span>{`${firstIndex} - ${lastIndex} de ${itemCount}`}</span>
        )}
      />
      <PatternflyTable
        className={className}
        aria-label="table"
        cells={columns}
        rows={transformRows(rows)}
        variant={TableVariant.compact}
        canSelectAll={!!canSelectAll}
        onSort={onSort}

        {...(onSelect && { onSelect: handleSelect })}
        {...(actions.length > 0 && { actions })}
      >
        <TableHeader />
        <TableBody rowKey={rowKey} />
      </PatternflyTable>
      {loading ? (
        <EmptyState animated title="Actualizando" body="" />
      ) : (
        items.length === 0 && (
          <EmptyState
            title="Tabla vacía"
            body="No se han encontrado elementos"
          />
        )
      )}
    </>
  );

  function handleOnSetPage(
    e: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPage: number,
    _perPage?: number,
    stardIdx?: number,
    endIdx?: number
  ) {
    setPage(newPage);
    setRows(items.slice(stardIdx, endIdx));
  }

  function handlePerPageSelect(
    _evt: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number,
    startIdx?: number,
    endIdx?: number
  ) {
    setPerPage(newPerPage);
    setPage(newPage);
    setRows(items.slice(startIdx, endIdx));
  }
};
/**
 * Obtiene un identificador único de la columna de acuerdo a la estructura interna
 * que utiliza `@patternfly/table`.
 */



export function defaultRowKey(entry: { rowData: any }, index: number = 0): any {
  const rowData = get(entry, "rowData", {});
  const cells = get(rowData, "cells", []);
  const cell = get(cells, `[${index}]`);
  if (cell === undefined) return uniqueId("cell");
  // Buscamos en la `cell` el valor de la primera columna, la cual debería ser la
  // columna de `id`. Esta `cell` puede ser un objeto o simplemente un `string`.
  // El valor de la `cell` cuando es un objeto puede estar en `cell.title` o
  // en `cell.key.title`.
  const result = isObject(cell)
    ? get(cell, "title.key") || get(cell, "title")
    : cell;
  // Si no podemos recuperar la información de esta celda volvemos a probar
  // en la columna siguiente.
  return result !== undefined ? result : defaultRowKey({ rowData }, index + 1);
}
