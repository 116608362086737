import React from "react"
import {Router} from "react-router-dom"
import {Provider} from "react-redux"

import {Keycloak} from "@components/Keycloak"
import history from "@history"
import {Root} from "@pages/Root"

export const App: React.FC<{store: any}> = ({store}) => (
  <Provider store={store}>
    <Keycloak>
      <Router history={history}>
        <Root />
      </Router>
    </Keycloak>
  </Provider>
)