import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button
} from '@patternfly/react-core';
import get from "lodash/get"

import type {IClient} from "../../utils/types/models"
import history from "../../utils/general/history"

export interface ClienteData extends IClient {
  isNew: boolean;
}

interface ClientesFormProps {
  id?: string;
  data?: IClient;
  onCreate?: (data: ClienteData) => void;
  onEdit?: (data: ClienteData) => void;
  isLoading?: boolean;
  isNew?: boolean;
}

export const ClientesForm: React.FC<ClientesFormProps> = ({data, id, isNew, isLoading, onCreate, onEdit}) => {
  return (
    <Form isHorizontal style={{padding: "1rem"}} onSubmit={handleSubmit}>
      <FormGroup label="ID del Cliente" isRequired fieldId="id" helperText="ID del cliente en ANTEL">
        <TextInput
          defaultValue={id || get(data, "id", "")}
          isRequired
          type="text"
          id="id"
          aria-describedby="id-helper"
          name="id"
          isDisabled={isNew === false || isLoading}
        />
      </FormGroup>

      <FormGroup label="Nombre" isRequired fieldId="name" helperText="Nombre del cliente">
        <TextInput defaultValue={get(data, "name", "")} isRequired type="text" id="name" aria-describedby="name-helper" name="name" isDisabled={isLoading}/>
      </FormGroup>

      <ActionGroup>
        <Button isLoading={isLoading} variant={isNew ? "primary" : "warning"} type="submit" isDisabled={isLoading}>Aceptar</Button>
        <Button variant="link" onClick={handleCancel} isDisabled={isLoading}>Cancelar</Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      id: {value: string};
      name: {value: string};
    }
    const id = target.id.value;
    const name = target.name.value
    isNew === true
      ? onCreate && onCreate({id, name, isNew: true})
      : onEdit && onEdit({id, name, isNew: false})
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    history.go(-1)
  }
}