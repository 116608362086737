import React from "react"
import {Link} from "react-router-dom"
import get from "lodash/get"
import {Badge} from "@patternfly/react-core"
import {textCenter, sortable} from "@patternfly/react-table"

import history from "../../utils/general/history"
import {IClient} from "../../utils/types/models"
import {Table} from "../../components/Table"


export const COLUMNS = [
  {key: "id", title: "Id", transforms: [sortable]},
  {key: "name", title: "Nombre", transforms: [sortable]},
  {key: "status", title: "Estado", transforms: [sortable]},
  {key: "lastModified", title: "Ultima Modificación", transforms: [sortable]},
]

export interface ClientesTableProps {
  items         : IClient[];
  loading?      : boolean;
  onDelete?     : (id: string) => void;
  transformRows?: (items: IClient[]) => {cells: any}[];
}

export const ClientesTable: React.FC<ClientesTableProps> = ({
  items = [],
  loading = false,
  onDelete,
  transformRows,
}) => {
  return (
    <Table
      columns={COLUMNS}
      items={items}
      transformRows={transformRows || defaultTransformRows}
      onEdit={handleOnEdit}
      onDelete={onDelete}
    />
  )

  function defaultTransformRows(items: IClient[]): {cells: any}[] {
    if (items === undefined) return []
    return items.map((item: IClient) => ({
      cells: COLUMNS.map((column) => {
        if (column.key === "id") {
          return {title: <Link to={`/clientes/${item.id}`} key={item.id}>{item.id}</Link>, }
        }
        if (column.key === "lastModified") {
          return get(item, "updatedAt") || get(item, "createdAt")
        }
        if (column.key === "status") {
          switch (item.status) {
            case "ok"   : return {title: <Badge className="green">Ok</Badge>}
            case "error": return {title: <Badge className="red">Error</Badge>}
            case "warning": return {title: <Badge className="yellow">Warning</Badge>}
            case "empty": return {title: <Badge className="grey">Empty</Badge>}
            case "missing": return {title: <Badge className="purple">Missing</Badge>}
                 default: return {title: <Badge className="grey">Offline</Badge>}
          }
        }
        return get(item, column.key)
      })
    }))
  }

  function handleOnEdit(id: string) {
    history.push(`/clientes/${id}/editar`)
  }
}