import React from "react"
import get from "lodash/get"

import {hidden} from "@utils/general/hidden"
import {Table} from "@components/Table"
import {textCenter, sortable} from "@patternfly/react-table"
import type {IUser} from "@models"

export const COLUMNS = [
  {key: "id", title: "Id", transforms: [hidden], cellTransforms: [hidden]},
  {key: "email", title: "Email", transforms: [sortable]},
  {key: "firstName", title: "Nombre", transforms: [sortable]},
  {key: "lastName", title: "Apellido", transforms: [sortable]},
]

export interface UsuariosTableProps {
  canSelectAll? : boolean;
  items         : IUser[];
  loading?      : boolean;
  onDelete?     : (id: string) => void;
  onEdit?       : (id: string) => void;
  onSelect?     : (id: string, value: boolean) => void;
  selectedUsers?: string[];
  transformRows?: (items: IUser[]) => {cells: any}[];
}

export const UsuariosTable: React.FC<UsuariosTableProps> = ({
  canSelectAll,
  items = [],
  loading = false,
  onDelete,
  onEdit,
  onSelect,
  selectedUsers = [],
  transformRows,
}) => {
  return (
    <Table
      columns={COLUMNS}
      items={items}
      transformRows={transformRows || defaultTransformRows}
      onEdit={onEdit}
      onDelete={onDelete}
      onSelect={onSelect}
      canSelectAll={canSelectAll}
      loading={loading}
    />
  )

  function defaultTransformRows(items: IUser[]): {cells: any}[] {
    if (items === undefined) return []
    return items.map((item: IUser) => {
      return {
        selected: selectedUsers && selectedUsers.includes(item.id),
        cells: COLUMNS.map((column) => {
          const value = get(item, column.key)
          if (column.key === "id") {
            return {title: value, formatters: [(id: string) => "..." + id.split("-").pop() as string]}
          }
          if (column.key === "lastModified") {
            return get(item, "updatedAt") || get(item, "createdAt")
          }
          return value
        })
      }
    })
  }
}