export const filtros = 

{
    "applicationCategories": [
        {
            "id": "meraki:layer7/category/1",
            "name": "Email",
            "applications": [
                {
                    "id": "meraki:layer7/application/4",
                    "name": "Gmail"
                },
                {
                    "id": "meraki:layer7/application/2",
                    "name": "Host-based email (POP3/IMAP/SMTP)"
                },
                {
                    "id": "meraki:layer7/application/39",
                    "name": "Hotmail"
                },
                {
                    "id": "meraki:layer7/application/7",
                    "name": "Rackspace Hosted Exchange"
                },
                {
                    "id": "meraki:layer7/application/158",
                    "name": "Web based email"
                },
                {
                    "id": "meraki:layer7/application/87",
                    "name": "Windows Live Hotmail and Outlook"
                },
                {
                    "id": "meraki:layer7/application/130",
                    "name": "Yahoo Mail"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/2",
            "name": "Blogging",
            "applications": [
                {
                    "id": "meraki:layer7/application/35",
                    "name": "Blogger"
                },
                {
                    "id": "meraki:layer7/application/94",
                    "name": "WordPress"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/3",
            "name": "File sharing",
            "applications": [
                {
                    "id": "meraki:layer7/application/106",
                    "name": "Apple file sharing"
                },
                {
                    "id": "meraki:layer7/application/171",
                    "name": "Box"
                },
                {
                    "id": "meraki:layer7/application/9",
                    "name": "Dropbox"
                },
                {
                    "id": "meraki:layer7/application/185",
                    "name": "Mega"
                },
                {
                    "id": "meraki:layer7/application/162",
                    "name": "Microsoft OneDrive"
                },
                {
                    "id": "meraki:layer7/application/168",
                    "name": "PutLocker.com"
                },
                {
                    "id": "meraki:layer7/application/107",
                    "name": "Windows file sharing"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/4",
            "name": "News",
            "applications": [
                {
                    "id": "meraki:layer7/application/184",
                    "name": "ABC News"
                },
                {
                    "id": "meraki:layer7/application/41",
                    "name": "CNN"
                },
                {
                    "id": "meraki:layer7/application/98",
                    "name": "Fox News"
                },
                {
                    "id": "meraki:layer7/application/100",
                    "name": "Gizmodo"
                },
                {
                    "id": "meraki:layer7/application/89",
                    "name": "MSN"
                },
                {
                    "id": "meraki:layer7/application/42",
                    "name": "New York Times"
                },
                {
                    "id": "meraki:layer7/application/99",
                    "name": "TechCrunch"
                },
                {
                    "id": "meraki:layer7/application/91",
                    "name": "sina.com.cn"
                },
                {
                    "id": "meraki:layer7/application/124",
                    "name": "turner.com"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/5",
            "name": "Sports",
            "applications": [
                {
                    "id": "meraki:layer7/application/101",
                    "name": "CBS Sports"
                },
                {
                    "id": "meraki:layer7/application/40",
                    "name": "ESPN"
                },
                {
                    "id": "meraki:layer7/application/96",
                    "name": "foxsports.com"
                },
                {
                    "id": "meraki:layer7/application/125",
                    "name": "mlb.com"
                },
                {
                    "id": "meraki:layer7/application/183",
                    "name": "nhl.com"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/6",
            "name": "Gaming",
            "applications": [
                {
                    "id": "meraki:layer7/application/133",
                    "name": "Battle.net"
                },
                {
                    "id": "meraki:layer7/application/64",
                    "name": "Big Fish Games"
                },
                {
                    "id": "meraki:layer7/application/189",
                    "name": "Blizzard"
                },
                {
                    "id": "meraki:layer7/application/63",
                    "name": "GameSpot"
                },
                {
                    "id": "meraki:layer7/application/61",
                    "name": "IGN"
                },
                {
                    "id": "meraki:layer7/application/62",
                    "name": "Miniclip"
                },
                {
                    "id": "meraki:layer7/application/68",
                    "name": "PlayStation"
                },
                {
                    "id": "meraki:layer7/application/173",
                    "name": "Steam"
                },
                {
                    "id": "meraki:layer7/application/150",
                    "name": "World of Warcraft"
                },
                {
                    "id": "meraki:layer7/application/67",
                    "name": "Xbox LIVE"
                },
                {
                    "id": "meraki:layer7/application/65",
                    "name": "Zynga"
                },
                {
                    "id": "meraki:layer7/application/66",
                    "name": "pogo.com"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/7",
            "name": "Online backup",
            "applications": [
                {
                    "id": "meraki:layer7/application/10",
                    "name": "Backblaze"
                },
                {
                    "id": "meraki:layer7/application/156",
                    "name": "Carbonite"
                },
                {
                    "id": "meraki:layer7/application/126",
                    "name": "IBackup"
                },
                {
                    "id": "meraki:layer7/application/11",
                    "name": "Mozy"
                },
                {
                    "id": "meraki:layer7/application/181",
                    "name": "iCloud"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/8",
            "name": "Peer-to-peer (P2P)",
            "applications": [
                {
                    "id": "meraki:layer7/application/110",
                    "name": "BitTorrent"
                },
                {
                    "id": "meraki:layer7/application/114",
                    "name": "DC++"
                },
                {
                    "id": "meraki:layer7/application/147",
                    "name": "Encrypted P2P"
                },
                {
                    "id": "meraki:layer7/application/112",
                    "name": "Gnutella"
                },
                {
                    "id": "meraki:layer7/application/111",
                    "name": "Kazaa"
                },
                {
                    "id": "meraki:layer7/application/113",
                    "name": "eDonkey"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/9",
            "name": "Social web & photo sharing",
            "applications": [
                {
                    "id": "meraki:layer7/application/33",
                    "name": "Facebook"
                },
                {
                    "id": "meraki:layer7/application/26",
                    "name": "Flickr"
                },
                {
                    "id": "meraki:layer7/application/84",
                    "name": "ImageShack"
                },
                {
                    "id": "meraki:layer7/application/152",
                    "name": "Instagram"
                },
                {
                    "id": "meraki:layer7/application/186",
                    "name": "Line"
                },
                {
                    "id": "meraki:layer7/application/23",
                    "name": "MySpace"
                },
                {
                    "id": "meraki:layer7/application/134",
                    "name": "Picasa"
                },
                {
                    "id": "meraki:layer7/application/159",
                    "name": "Pinterest"
                },
                {
                    "id": "meraki:layer7/application/180",
                    "name": "Snapchat"
                },
                {
                    "id": "meraki:layer7/application/44",
                    "name": "Twitter"
                },
                {
                    "id": "meraki:layer7/application/45",
                    "name": "Yelp"
                },
                {
                    "id": "meraki:layer7/application/151",
                    "name": "img.ly"
                },
                {
                    "id": "meraki:layer7/application/69",
                    "name": "imgur.com"
                },
                {
                    "id": "meraki:layer7/application/71",
                    "name": "photobucket.com"
                },
                {
                    "id": "meraki:layer7/application/135",
                    "name": "smugmug"
                },
                {
                    "id": "meraki:layer7/application/155",
                    "name": "twitpic"
                },
                {
                    "id": "meraki:layer7/application/153",
                    "name": "yfrog"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/12",
            "name": "Software & anti-virus updates",
            "applications": [
                {
                    "id": "meraki:layer7/application/14",
                    "name": "Antivirus updates"
                },
                {
                    "id": "meraki:layer7/application/13",
                    "name": "Software updates"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/13",
            "name": "Video & music",
            "applications": [
                {
                    "id": "meraki:layer7/application/172",
                    "name": "Amazon Instant Video"
                },
                {
                    "id": "meraki:layer7/application/188",
                    "name": "BBC iPlayer"
                },
                {
                    "id": "meraki:layer7/application/169",
                    "name": "Dailymotion"
                },
                {
                    "id": "meraki:layer7/application/178",
                    "name": "HBO GO"
                },
                {
                    "id": "meraki:layer7/application/128",
                    "name": "Miscellaneous audio"
                },
                {
                    "id": "meraki:layer7/application/102",
                    "name": "Miscellaneous video"
                },
                {
                    "id": "meraki:layer7/application/38",
                    "name": "Netflix"
                },
                {
                    "id": "meraki:layer7/application/187",
                    "name": "Niconico"
                },
                {
                    "id": "meraki:layer7/application/32",
                    "name": "Pandora"
                },
                {
                    "id": "meraki:layer7/application/136",
                    "name": "Rhapsody"
                },
                {
                    "id": "meraki:layer7/application/163",
                    "name": "Spotify"
                },
                {
                    "id": "meraki:layer7/application/37",
                    "name": "Vimeo"
                },
                {
                    "id": "meraki:layer7/application/170",
                    "name": "Xfinity TV"
                },
                {
                    "id": "meraki:layer7/application/19",
                    "name": "YouTube"
                },
                {
                    "id": "meraki:layer7/application/122",
                    "name": "blip.tv"
                },
                {
                    "id": "meraki:layer7/application/143",
                    "name": "grooveshark.com"
                },
                {
                    "id": "meraki:layer7/application/36",
                    "name": "hulu.com"
                },
                {
                    "id": "meraki:layer7/application/24",
                    "name": "iTunes"
                },
                {
                    "id": "meraki:layer7/application/70",
                    "name": "last.fm"
                },
                {
                    "id": "meraki:layer7/application/73",
                    "name": "megavideo.com"
                },
                {
                    "id": "meraki:layer7/application/141",
                    "name": "rdio.com"
                },
                {
                    "id": "meraki:layer7/application/142",
                    "name": "soundcloud.com"
                },
                {
                    "id": "meraki:layer7/application/72",
                    "name": "ustream.tv"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/16",
            "name": "VoIP & video conferencing",
            "applications": [
                {
                    "id": "meraki:layer7/application/165",
                    "name": "Dropcam"
                },
                {
                    "id": "meraki:layer7/application/160",
                    "name": "SCCP (Skinny Call Control Protocol)"
                },
                {
                    "id": "meraki:layer7/application/132",
                    "name": "SIP (Voice)"
                },
                {
                    "id": "meraki:layer7/application/31",
                    "name": "Skype"
                },
                {
                    "id": "meraki:layer7/application/140",
                    "name": "Vocera"
                },
                {
                    "id": "meraki:layer7/application/161",
                    "name": "WebEx"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/17",
            "name": "Web file sharing",
            "applications": [
                {
                    "id": "meraki:layer7/application/80",
                    "name": "4shared.com"
                },
                {
                    "id": "meraki:layer7/application/137",
                    "name": "Easynews"
                },
                {
                    "id": "meraki:layer7/application/78",
                    "name": "RapidShare"
                },
                {
                    "id": "meraki:layer7/application/58",
                    "name": "download.com"
                },
                {
                    "id": "meraki:layer7/application/48",
                    "name": "easyshare.com"
                },
                {
                    "id": "meraki:layer7/application/52",
                    "name": "filefactory.com"
                },
                {
                    "id": "meraki:layer7/application/53",
                    "name": "filefront.com"
                },
                {
                    "id": "meraki:layer7/application/56",
                    "name": "filer.com"
                },
                {
                    "id": "meraki:layer7/application/79",
                    "name": "filestube.com"
                },
                {
                    "id": "meraki:layer7/application/50",
                    "name": "gigeshare.com"
                },
                {
                    "id": "meraki:layer7/application/81",
                    "name": "hotfile.com"
                },
                {
                    "id": "meraki:layer7/application/55",
                    "name": "massmirror.com"
                },
                {
                    "id": "meraki:layer7/application/83",
                    "name": "mediafire.com"
                },
                {
                    "id": "meraki:layer7/application/49",
                    "name": "megashare.com"
                },
                {
                    "id": "meraki:layer7/application/74",
                    "name": "megaupload.com"
                },
                {
                    "id": "meraki:layer7/application/47",
                    "name": "rapidshare.com"
                },
                {
                    "id": "meraki:layer7/application/75",
                    "name": "thepiratebay.org"
                },
                {
                    "id": "meraki:layer7/application/77",
                    "name": "torrentz.com"
                },
                {
                    "id": "meraki:layer7/application/51",
                    "name": "ultrashare.de"
                },
                {
                    "id": "meraki:layer7/application/57",
                    "name": "upload.com"
                },
                {
                    "id": "meraki:layer7/application/82",
                    "name": "zshare.net"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/18",
            "name": "Security",
            "applications": [
                {
                    "id": "meraki:layer7/application/272",
                    "name": "DigiCert"
                },
                {
                    "id": "meraki:layer7/application/271",
                    "name": "avast"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/19",
            "name": "Productivity",
            "applications": [
                {
                    "id": "meraki:layer7/application/251",
                    "name": "Adobe Creative Cloud"
                },
                {
                    "id": "meraki:layer7/application/182",
                    "name": "Office 365"
                },
                {
                    "id": "meraki:layer7/application/253",
                    "name": "Sharepoint"
                },
                {
                    "id": "meraki:layer7/application/252",
                    "name": "Simphony.NET"
                },
                {
                    "id": "meraki:layer7/application/254",
                    "name": "Slack"
                },
                {
                    "id": "meraki:layer7/application/138",
                    "name": "Windows Live Office"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/20",
            "name": "Remote monitoring & management",
            "applications": [
                {
                    "id": "meraki:layer7/application/179",
                    "name": "Citrix ICA"
                },
                {
                    "id": "meraki:layer7/application/6",
                    "name": "Citrix Online"
                },
                {
                    "id": "meraki:layer7/application/232",
                    "name": "Marconet"
                },
                {
                    "id": "meraki:layer7/application/231",
                    "name": "MyRMM"
                },
                {
                    "id": "meraki:layer7/application/108",
                    "name": "Remote desktop"
                },
                {
                    "id": "meraki:layer7/application/233",
                    "name": "auvik"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/21",
            "name": "Business management",
            "applications": [
                {
                    "id": "meraki:layer7/application/293",
                    "name": "Deltek Axium"
                },
                {
                    "id": "meraki:layer7/application/297",
                    "name": "IFS"
                },
                {
                    "id": "meraki:layer7/application/292",
                    "name": "IQMS"
                },
                {
                    "id": "meraki:layer7/application/294",
                    "name": "Intacct"
                },
                {
                    "id": "meraki:layer7/application/296",
                    "name": "Microsoft Dynamics 365"
                },
                {
                    "id": "meraki:layer7/application/291",
                    "name": "NetSuite"
                },
                {
                    "id": "meraki:layer7/application/295",
                    "name": "Priority ERP"
                },
                {
                    "id": "meraki:layer7/application/299",
                    "name": "SICOM Restaurant Management"
                },
                {
                    "id": "meraki:layer7/application/298",
                    "name": "SugarCRM"
                },
                {
                    "id": "meraki:layer7/application/300",
                    "name": "Workday"
                },
                {
                    "id": "meraki:layer7/application/59",
                    "name": "salesforce.com"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/22",
            "name": "Health care",
            "applications": [
                {
                    "id": "meraki:layer7/application/374",
                    "name": "Allscripts"
                },
                {
                    "id": "meraki:layer7/application/376",
                    "name": "Cerner"
                },
                {
                    "id": "meraki:layer7/application/372",
                    "name": "CureMD"
                },
                {
                    "id": "meraki:layer7/application/371",
                    "name": "Epic EHR"
                },
                {
                    "id": "meraki:layer7/application/373",
                    "name": "GE Healthcare"
                },
                {
                    "id": "meraki:layer7/application/375",
                    "name": "McKesson"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/23",
            "name": "Web payments",
            "applications": [
                {
                    "id": "meraki:layer7/application/313",
                    "name": "Intuit"
                },
                {
                    "id": "meraki:layer7/application/316",
                    "name": "Lightspeed Retail POS"
                },
                {
                    "id": "meraki:layer7/application/317",
                    "name": "LivePOS"
                },
                {
                    "id": "meraki:layer7/application/314",
                    "name": "PayPal"
                },
                {
                    "id": "meraki:layer7/application/315",
                    "name": "PayU"
                },
                {
                    "id": "meraki:layer7/application/318",
                    "name": "ShopKeep"
                },
                {
                    "id": "meraki:layer7/application/312",
                    "name": "Square"
                },
                {
                    "id": "meraki:layer7/application/311",
                    "name": "Stripe"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/24",
            "name": "Databases & cloud services",
            "applications": [
                {
                    "id": "meraki:layer7/application/332",
                    "name": "Amazon RDS"
                },
                {
                    "id": "meraki:layer7/application/333",
                    "name": "Amazon Redshift"
                },
                {
                    "id": "meraki:layer7/application/351",
                    "name": "Azure"
                },
                {
                    "id": "meraki:layer7/application/352",
                    "name": "IBM Cloud"
                },
                {
                    "id": "meraki:layer7/application/353",
                    "name": "SAP Cloud Platform"
                },
                {
                    "id": "meraki:layer7/application/331",
                    "name": "SAP Hana"
                }
            ]
        },
        {
            "id": "meraki:layer7/category/27",
            "name": "Advertising",
            "applications": [
                {
                    "id": "meraki:layer7/application/205",
                    "name": "Advertising.com"
                },
                {
                    "id": "meraki:layer7/application/202",
                    "name": "AppNexus"
                },
                {
                    "id": "meraki:layer7/application/211",
                    "name": "Brightroll"
                },
                {
                    "id": "meraki:layer7/application/207",
                    "name": "DoubleVerify"
                },
                {
                    "id": "meraki:layer7/application/201",
                    "name": "Google advertising"
                },
                {
                    "id": "meraki:layer7/application/203",
                    "name": "Integral Ad Science"
                },
                {
                    "id": "meraki:layer7/application/208",
                    "name": "LKQD"
                },
                {
                    "id": "meraki:layer7/application/209",
                    "name": "OpenX"
                },
                {
                    "id": "meraki:layer7/application/212",
                    "name": "Pubmatic"
                },
                {
                    "id": "meraki:layer7/application/204",
                    "name": "SpringServe"
                },
                {
                    "id": "meraki:layer7/application/210",
                    "name": "moatads"
                },
                {
                    "id": "meraki:layer7/application/206",
                    "name": "mopub"
                }
            ]
        }
    ]
}

export default filtros;