import React from "react"
import {Modal, ModalVariant} from "@patternfly/react-core"

import type {RemotoManagementInterfaceFormProps} from "./RemotoManagementInterfaceForm"
import {RemotoManagementInterfaceForm} from "./RemotoManagementInterfaceForm"

export interface RemotoManagementInterfaceModalFormProps extends RemotoManagementInterfaceFormProps {
  onClose: () => void;
}

export const RemotoManagementInterfaceModalForm: React.FC<RemotoManagementInterfaceModalFormProps> = ({
  onClose,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title="Edición de Interface WAN"
      showClose={true}
      onClose={onClose}
    >
      <RemotoManagementInterfaceForm {...props} onCancel={onCancel || onClose} />
    </Modal>
  )
}