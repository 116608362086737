import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  Switch,
} from '@patternfly/react-core';
import get from "lodash/get"

import type {MerakiManagementInterface} from "@models"

export interface RemotoManagementInterfaceFormProps {
  data: MerakiManagementInterface,
  isLoading: boolean,
  onSubmit: (data: MerakiManagementInterface) => void,
  onCancel?: () => void,
}

export const RemotoManagementInterfaceForm: React.FC<RemotoManagementInterfaceFormProps> = ({
  data,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const [wanEnabled, setWanEnabled] = React.useState<boolean>(data.wanEnabled === "enabled")
  const [usingStaticIp, setUsingStaticIp] = React.useState<boolean>(!!data.usingStaticIp)

  return (
    <Form isHorizontal onSubmit={handleSubmit}>
      <FormGroup fieldId="wanEnabled" label="Estado" isRequired>
        <Switch
          id="wanEnabled"
          label="Habilitado"
          labelOff="Deshabilitado"
          isChecked={wanEnabled}
          onChange={setWanEnabled}
        />
      </FormGroup>

      <FormGroup
        fieldId="vlan"
        label="VLAN"
      >
        <TextInput
          aria-describedby="vlan-helper"
          defaultValue={get(data, "vlan", "")}
          id="vlan"
          name="vlan"
          type="text"
        />
      </FormGroup>

      <FormGroup fieldId="usingStaticIp" label="Modo" isRequired>
        <Switch
          id="usingStaticIp"
          label="IP Fija"
          labelOff="IP Variable"
          isChecked={usingStaticIp}
          onChange={setUsingStaticIp}
        />
      </FormGroup>

      <FormGroup
        fieldId="staticIp"
        label="IP Fija"
      >
        <TextInput
          aria-describedby="staticIp-helper"
          defaultValue={get(data, "staticIp", "")}
          id="staticIp"
          isDisabled={usingStaticIp === false}
          isRequired={usingStaticIp === true}
          name="staticIp"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="staticSubnetMask"
        label="Máscara"
      >
        <TextInput
          aria-describedby="staticSubnetMask-helper"
          defaultValue={get(data, "staticSubnetMask", "")}
          id="staticSubnetMask"
          isDisabled={usingStaticIp === false}
          isRequired={usingStaticIp === true}
          name="staticSubnetMask"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="staticGatewayIp"
        label="Default Gateway IP"
      >
        <TextInput
          aria-describedby="staticGatewayIp-helper"
          defaultValue={get(data, "staticGatewayIp", "")}
          id="staticGatewayIp"
          isDisabled={usingStaticIp === false}
          isRequired={usingStaticIp === true}
          name="staticGatewayIp"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="staticDnsPrimary"
        label="DNS Primario"
      >
        <TextInput
          aria-describedby="staticDnsPrimary-helper"
          defaultValue={get(data, "staticDns[0]", "")}
          id="staticDnsPrimary"
          isDisabled={usingStaticIp === false}
          isRequired={usingStaticIp === true}
          name="staticDnsPrimary"
          type="text"
        />
      </FormGroup>

      <FormGroup
        fieldId="staticDnsSecondary"
        label="DNS Secundario"
      >
        <TextInput
          aria-describedby="staticDnsSecondary-helper"
          defaultValue={get(data, "staticDns[1]", "")}
          id="staticDnsSecondary"
          isDisabled={usingStaticIp === false}
          isRequired={usingStaticIp === true}
          name="staticDnsSecondary"
          type="text"
        />
      </FormGroup>

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={"primary"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      staticIp: {value: string};
      staticSubnetMask: {value: string};
      staticGatewayIp: {value: string};
      staticDnsPrimary: {value: string};
      staticDnsSecondary: {value: string};
      vlan: {value: string};
    }
    const staticIp = target.staticIp.value
    const staticSubnetMask = target.staticSubnetMask.value
    const staticGatewayIp = target.staticGatewayIp.value
    const staticDnsPrimary = target.staticDnsPrimary.value
    const staticDnsSecondary = target.staticDnsSecondary.value
    const vlan = target.vlan.value
    onSubmit({
      wanEnabled: wanEnabled === true ? "enabled" : "disabled",
      usingStaticIp,
      vlan: vlan !== "" ? parseInt(vlan, 10) : undefined,
      staticIp: usingStaticIp === true ? staticIp : undefined,
      staticSubnetMask: usingStaticIp === true ? staticSubnetMask : undefined,
      staticGatewayIp: usingStaticIp === true ? staticGatewayIp : undefined,
      staticDns: usingStaticIp === true ? [staticDnsPrimary, staticDnsSecondary] : undefined,
    })
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    onCancel && onCancel()
  }
}