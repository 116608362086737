import React from "react"
import {Badge} from "@patternfly/react-core"

export const RemotoInterfacesStatusBadge: React.FC<{status: string}> = ({status}) => {
  switch (status) {
    case "active": return <Badge className="green">Online</Badge>
    case "not connected": return <Badge className="grey">Desconectado</Badge>
    case "offline": return <Badge className="grey">Offline</Badge>
    case "error": return <Badge className="red">Error</Badge>
    case "missing": return <Badge className="purple">Missing</Badge>
    default: return <Badge className="grey">{status}</Badge>
  }
}