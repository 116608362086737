import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Alert, AlertGroup, AlertActionCloseButton, AlertActionLink, AlertVariant} from '@patternfly/react-core';

import history from "@history"
import {notificationsSelector, pop} from "@redux/state/notifications"

export const Notifications: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const items = useSelector(notificationsSelector)

  return (
    <AlertGroup isToast>
      {items.map((item) => (
        <Alert
          className="Notification"
          isLiveRegion
          variant={AlertVariant[item.variant]}
          title={item.title}
          actionClose={
            <AlertActionCloseButton
              title={item.title}
              variantLabel={`${item.variant} alert`}
              onClose={() => handlePop(item.key)}
            />
          }
          actionLinks={
            <>
              {item.link && <AlertActionLink onClick={handleDetails(item.key, item.link)}>Ver detalles</AlertActionLink>}
              {item.refresh && <AlertActionLink onClick={refreshPage}>Reiniciar aplicación</AlertActionLink>}
            </>
          }
          key={item.key}
        >
          {item.message}
        </Alert>
      ))}
    </AlertGroup>
  )

  function handleDetails(key: string, link: string) {
    return () => {
      history.push(link)
      handlePop(key)
    }
  }

  function refreshPage() {
    window.location.reload()
  }

  function handlePop(key: string) {
    dispatch(pop(key))
  }
}

