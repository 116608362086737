import {createSlice, createSelector, createAction} from "@reduxjs/toolkit"
import type {PayloadAction, SliceCaseReducers, Action} from "@reduxjs/toolkit"
import {filter, map, switchMap, catchError} from "rxjs/operators"
import {from, of} from "rxjs"
import type {Observable} from "rxjs"
import {combineEpics, ofType} from "redux-observable"

import type {UserInfo} from "@models"
import {usersMe} from "@redux/api"

declare global {
	interface Window {
			Keycloak:any
			k: any
	}
}
/**
 * Almacena la información del usuario autenticado. Es inicializado por el
 * servicio `Keycloak` tras chequear el estado de la sesión del usuario.
 */
export interface AuthState {
  authenticated : boolean;
  roles        ?: string[];
  clients      ?: string[];
  email        ?: string;
  id           ?: string;
  isAdmin      ?: boolean;
  name         ?: string;
  networks     ?: string[];
  token        ?: string;
}
/**
 * SLICE
 */
const slice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
  name: "flags",
  initialState: {authenticated: false},
  reducers: {
    update(_, action: PayloadAction<AuthState>) {
      return action.payload
    }
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {update} = slice.actions
export const init = createAction<undefined>("auth/init")
/**
 * SELECTORS
 */
export const stateSelector           = (state: any): AuthState => state.auth
export const isAuthenticatedSelector = createSelector(stateSelector, (state: AuthState) => state.authenticated)
export const isAdminSelector         = createSelector(stateSelector, (state: AuthState) => Array.isArray(state.roles) && state.roles.includes("administrator"))
export const nameSelector            = createSelector(stateSelector, (state: AuthState) => state.name || "")
export const networksSelector        = createSelector(stateSelector, (state: AuthState) => state.networks || [])
export const networksLengthSelector  = createSelector(networksSelector, (networks: string[]) => networks.length)
/**
 * EPICS
 */
/**
 * Inicia el proceso de verificación de la sesión del usuario.
 */
export const init$ = (action$: Observable<Action>): Observable<Action|PayloadAction<AuthState>> => {
  return action$.pipe(
    filter(init.match),
    switchMap(() => {
      if (window.Keycloak === undefined) {
        throw new Error("Can't find the Keycloak global constructor")
      }

      // Initialize a Keycloak object
      const keycloak = new window.Keycloak({
        url     : process.env.REACT_APP_KEYCLOAK_URL       || '/auth',
        realm   : process.env.REACT_APP_KEYCLOAK_REALM     || 'sdwan',
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'dashboard'
      })

      window.k = keycloak

      return from(
        keycloak
          .init({onLoad: "login-required", timeSkew: 0})
          .catch((err: Error) => {throw err})
      )
    }),
    map((authenticated) => {
      if (authenticated === true) {
        return usersMe()
      }
      throw new Error("User was not authenticated")
    }),
    catchError((err: Error) => {
      console.group("Keycloak Initialize Error")
      console.error(err)
      console.groupEnd()
      return of({type: "KEYCLOAK_INIT_ERROR"})
    })
  )
}
/**
 * Actualiza la información del usuario en base a la respuesta del `request`
 * a `/users/me`.
 */
export const userInfo$ = (action$: Observable<PayloadAction<UserInfo>>): Observable<PayloadAction<AuthState>> => {
  return action$.pipe(
    ofType("users/me/SUCCESS"),
    map(({payload}) => update({...payload, authenticated: true}))
  )
}

export const epic = combineEpics(init$, userInfo$)