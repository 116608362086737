import React from "react"
import {useSelector} from "react-redux"

import {flagSelector} from "@redux/state/flags"

export const useFlag = (flagId: string) => {
  const callback = React.useCallback(state => flagSelector(state, flagId), [flagId])
  return useSelector(callback)
}

