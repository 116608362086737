import React from "react"
import {Link} from "react-router-dom"
import LinkIcon from "@patternfly/react-icons/dist/js/icons/link-icon"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Divider,
  Grid,
  GridItem,
} from "@patternfly/react-core";
import get from "lodash/get"

import type {INetwork} from "@models"
import {useIsAdmin} from "@hooks/useIsAdmin"

export interface RedProps {
  item: INetwork;
}

export const RedLayout: React.FC<RedProps> = ({item, children}) => {
  const isAdmin = useIsAdmin()

  return (
    <Grid hasGutter className="Page RedesLayout">
      <GridItem span={3} rowSpan={2}>
        <Card>
          <CardTitle>{item.id}</CardTitle>
          <CardBody>
            <dt>ID del Cliente:</dt>
            <dd>
              {isAdmin
                ? <Link to={`/clientes/${item.clientId}`}>{item.clientId}</Link>
                : item.clientId
              }
            </dd>
            <dt>Meraki Org. ID:</dt>
            <dd>{item.merakiOrganizationId}</dd>
            <dt>Última modificación</dt>
            <dd>{get(item, "updatedAt") || get(item, "createdAt")}</dd>
            <Divider />
            <Button type="button" variant="link" icon={<LinkIcon />} iconPosition="left" style={{padding: 0}}>
              <a href={item.merakiOrganizationUrl}>Meraki Dashboard</a>
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem span={9}>
      <Card>
        <CardBody>
          {children}
        </CardBody>
      </Card>
    </GridItem>
    </Grid>
  )
}

export default RedLayout