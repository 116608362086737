import React from "react"
import {Link} from "react-router-dom"
import {
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateVariant,
  Title,
  EmptyStateIcon
} from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/js/icons/search-icon';

export const NotFound: React.FC<{}> = () => (
  <EmptyState variant={EmptyStateVariant.xl}>
    <EmptyStateIcon icon={SearchIcon} />
    <Title headingLevel="h2" size="lg">
      404 - Not Found
    </Title>
    <EmptyStateBody>
      No se ha encontrado esta página. Veríique su URL o vuelva a la página principal.
    </EmptyStateBody>
    <Button variant="link"><Link to="/">Volver a la página principal</Link></Button>
  </EmptyState>
)