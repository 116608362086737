import React from "react"
import {Modal, ModalVariant, Button} from "@patternfly/react-core"

import history from "../utils/general/history"

export type SmallModalVariants = "default" | "danger" | "success" | "warning" | "info"
export type SmallModalButtonVariants = "danger" | "warning" | "link" | "primary" | "secondary" | "tertiary" | "plain" | "control" | undefined

export interface SmallModalProps {
  title?: string
  titleIconVariant?: SmallModalVariants,
  onAcceptButtonText?: string;
  onAcceptButtonVariant?: SmallModalButtonVariants,
  onCancelButtonText?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  href?: string;
}

export const SmallModal: React.FC<SmallModalProps> = ({
  title = "Atención",
  titleIconVariant = "danger",
  onAcceptButtonText = "Si, estoy seguro",
  onAcceptButtonVariant = "danger",
  onCancelButtonText = "Cancelar",
  children = <p>¿Esta seguro que desea continuar con esta acción?</p>,
  onAccept,
  onCancel,
  href,
}) => {
  return (
    <Modal
      isOpen={true}
      variant={ModalVariant.small}
      title={title}
      titleIconVariant={titleIconVariant}
      showClose={true}
      onClose={handleClose}
      actions={[
        <Button key="confirm" variant={onAcceptButtonVariant} onClick={handleAccept}>
          {onAcceptButtonText}
        </Button>,
        <Button key="cancel" variant="link" onClick={handleClose}>
          {onCancelButtonText}
        </Button>
      ]}
    >
      {children}
    </Modal>
  )

  function handleAccept(e: React.SyntheticEvent) {
    e.preventDefault()
    onAccept && onAccept()
    handleClose()
  }

  function handleClose() {
    if (onCancel !== undefined) return onCancel()
    href !== undefined ? history.push(href) : history.go(-1)
  }
}