import React from "react"
import {
  Form,
  FormGroup,
  ActionGroup,
  Button,
} from '@patternfly/react-core';

import type {FilterRules} from "@models"
import filtros from "./filtros.jsx";
import { useState } from 'react';


export interface RemotosFiltrosFormProps {
  rules: FilterRules[],
  isLoading: boolean,
  onSubmit: (data: FilterRules[]) => void,
  onClose: () => void,
}

export const RemotosFiltrosForm: React.FC<RemotosFiltrosFormProps> = ({
  rules,
  isLoading,
  onSubmit,
  onClose,
}) => {


  const applicationsList = ({
    id:"",
    name:""
  })

  const apps:typeof applicationsList[] = filtros.applicationCategories[0].applications;

  const [list, setApps] = useState({
    appList: apps
  }) 
  const [selection, setSelection] = useState({
    currentSelection: {value: filtros.applicationCategories[0].applications[0], type : "application", policy: "deny" } as FilterRules 
  }) 

  return (
    <Form isHorizontal onSubmit={handleSubmit}>
      <FormGroup
        fieldId="type"
        helperText="Tipo"
        label="Tipo"
      >
        <select onChange={handleCategorySelectionChange}> 
          {filtros.applicationCategories.map((r) => <option value={r.id.toString()}>{r.name.toString()}</option>)}
        </select>
      </FormGroup>

      <FormGroup
        fieldId="value"
        helperText="Valor"
        label="Valor"
      >
        <select onChange={handleApplicationSelectionChange}> 
          {list.appList.map((r) => <option value={r.id.toString()}>{r.name.toString()}</option>)}
        </select>
      </FormGroup>
      

      <ActionGroup>
        <Button
          isLoading={isLoading}
          variant={"primary"}
          type="submit"
          isDisabled={isLoading}
        >
          Aceptar
        </Button>
        <Button
          variant="link"
          onClick={handleClose}
          isDisabled={isLoading}
        >
          Cancelar
        </Button>
      </ActionGroup>
    </Form>
  )

  function handleApplicationSelectionChange(e:React.SyntheticEvent)
  {
    const target = e.target as typeof e.target & {
      value: string;
    }
    let aux = list.appList.filter(t => t.id == target.value)[0];
    setSelection({ currentSelection : {value: {id: aux.id, name: aux.name, }, type : "application", policy: "deny" } as FilterRules })

  }

  function handleCategorySelectionChange(e:React.SyntheticEvent) {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      value: string;
    }

    let aux = filtros.applicationCategories.filter(t => t.id == target.value)[0];
    setApps({appList : aux.applications});
    setSelection({ currentSelection : {value: {id: aux.applications[0].id, name: aux.applications[0].name  }, type : "application", policy: "deny" } as FilterRules })
  }

  function handleSubmit(e:React.SyntheticEvent) {
    e.preventDefault()
    if(rules.filter(t => t.value == selection.currentSelection.value).length == 0)
    {
      rules = [...rules, selection.currentSelection];
      onSubmit(rules);
    }
    else{
      //mensaje de error, ya existe filtro en la lista de filtros.
      onClose && onClose()
    }
     
  }

  function handleClose(e: React.SyntheticEvent) {
    e.preventDefault()
    onClose && onClose()
  }
}


