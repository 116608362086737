import React from "react"
import find from "lodash/find"
import sortBy from "lodash/sortBy"

export interface UseHandleListOptions<U, T> {
  initial?: T[]|undefined;
  idKey?: U;
  sortByKey?: string;
  onRemove?: (item: T|undefined) => void;
  onUpsert?: (item: T|undefined) => void;
  onSelect?: (item: T|undefined) => void;
}

export function useHandleList<V, U extends string, T extends {[key in U]: V}>({
  initial = undefined,
  idKey = "id" as U,
  sortByKey = "id",
  onUpsert = () => {},
  onRemove = () => {},
  onSelect = () => {},
}: UseHandleListOptions<U, T> = {}) {

  const [list, setList] = React.useState<T[]|undefined>(initial)
  const [item, setItem] = React.useState<T|undefined>(undefined)

  return {list, setList, item, setItem, upsertItem, removeItem, selectItem, deselectItem}

  /**
   * Selecciona un elemento de la lista.
   */
  function selectItem(value: V) {
    const i = find(list, item => item[idKey] === value)
    setItem(i)
    onSelect(i)
  }
  /**
   * Elimina el elemento seleccionado de la lista.
   */
  function removeItem(value?: V) {
    const i = item || find(list, ii => ii[idKey] === value)
    if (Array.isArray(list) && i !== undefined) {
      setList(sortBy(list.filter(iii => iii[idKey] !== i[idKey]), sortByKey))
    }
    onRemove(i)
  }
  /**
   * Agrega un nuevo elemento a la lista o actualiza un
   * elemento existente.
   */
  function upsertItem(data: T) {
    if (Array.isArray(list)) {
      setList(sortBy([...list.filter(i => item !== undefined && i[idKey] !== item[idKey]), data], sortByKey))
    }
    deselectItem()
    onUpsert(data)
  }
  /**
   * Deseleeciona un elemento de la lista.
   */
  function deselectItem() {
    setItem(undefined)
  }
}