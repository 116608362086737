import React from "react"
import {Bullseye, Spinner, Flex} from "@patternfly/react-core"

interface LoaderProps {
  content?: String;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const Loader: React.FC<LoaderProps> = ({
  content = "Aguarde por favor...",
  isLoading = false,
  children,
}) => {

  if (children && isLoading === false) return <>{children}</>

  return (
    <Bullseye>
      <Flex direction={{ default: "column" }} alignItems={{default: "alignItemsCenter"}} style={{padding: "1rem"}}>
        <Spinner size="xl"/>
        <p>{content}</p>
      </Flex>
    </Bullseye>
  )
}