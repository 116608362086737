import React from "react"
import {useDispatch} from "react-redux"

import {useFlag} from "@hooks/useFlag"
import {INetwork} from "@models"
import {RedesForm} from "@pages/Redes/RedesForm"
import {networksCreate} from "@redux/api"

export const RedesNueva: React.FC<{}> = () => {
  const dispatch           = useDispatch()
  const networksCreateFlag = useFlag("networksCreate")

  return (
    <RedesForm
      isNew={true}
      isLoading={networksCreateFlag}
      onCreate={handleCreate}
    />
  )

  function handleCreate(body: Partial<INetwork> & {isNew: boolean}) {
    dispatch(networksCreate(body))
  }
}