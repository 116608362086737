import _union from "lodash/union"
import {createSlice, createSelector, AnyAction} from "@reduxjs/toolkit"
import type {SliceCaseReducers} from "@reduxjs/toolkit"

import {merge, clientsSelector as entitiesSelector} from "@redux/state/entities"
import {networksSelector} from "@redux/state/networks"
import {usersSelector} from "@redux/state/users"
import type {MetaPayloadAction} from "@redux/types"

const slice = createSlice<string[], SliceCaseReducers<string[]>>({
  name: "clients",
  initialState: [],
  reducers: {
    union(state, action) {
      return _union(state, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(merge, (state, action) => {
        if (action.payload && action.payload.entities && action.payload.entities.clients) {
          return _union(state, Array.isArray(action.payload.result) ? action.payload.result : [action.payload.result])
        }
        return state
      })
      .addMatcher(isClientDestroySuccessAction, (state, action) => {
        return state.filter(id => id !== action.meta.id)
      })
  }
})
/**
 * REDUCER
 */
export default slice.reducer
/**
 * ACTIONS
 */
export const {union} = slice.actions
/**
 * SELECTORS
 */
// Selecciona la lista de `client ids`.
export const idsSelector = (state: {clients: string[]}) => state.clients
// Selecciona la lista de `clients`.
export const clientsSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ids.map(id => entities[id])
)
// Selecciona una única `client id`
export const clientIdSelector = (_: any, clientId: string) => clientId
// Selecciona una única `client`
export const clientSelector = createSelector(
  clientIdSelector,
  entitiesSelector,
  (clientId, entities) => entities[clientId]
)
// Selecciona las `networks` que corresponden a un `client`
export const clientNetworksSelector = createSelector(
  clientIdSelector,
  networksSelector,
  (clientId, networks) => networks.filter(network => network.clientId === clientId)
)
// Selecciona los `users` que corresponden a un `client`
export const clientUsersSelector = createSelector(
  clientSelector,
  usersSelector,
  (client, users) => users.filter(user => client !== undefined && Array.isArray(client.userIds) && client.userIds.includes(user.id))
)
/**
 * FUNCTIONS
 */
function isClientDestroySuccessAction(action: AnyAction): action is MetaPayloadAction<{id: string}> {
  return action.type === "clients/destroy/SUCCESS"
}
