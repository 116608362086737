import {ajax, AjaxRequest} from "rxjs/ajax"
import {catchError, filter, map, mapTo, mergeMap, take, switchMap, sample} from "rxjs/operators"
import {combineEpics} from "redux-observable"
import {createAction} from "@reduxjs/toolkit"
import {Observable, of, concat, race, from, timer, merge, interval, fromEvent} from "rxjs"
import {ofType} from "redux-observable"
import isFunction from "lodash/isFunction"
import template from "lodash/template"

import { MerakiFilter, MerakiPort } from "@models"
import {updateToken, logout} from "@keycloak"
import type {AnyAction} from "@reduxjs/toolkit"
import type {MetaPayloadAction} from "./types"
import type * as models from "@models"

/**
 * Cuerpo del `request` PUT `/clients/:id/users`.
 */
export interface ClientsUpdateUsersPayload extends models.Id {
  users: string[]
}
/**
 * Cuerpo del `request` PUT `/sites/:id/managementInterfaces`.
 */
export interface SitesUpdateManagementInterfacesPayload extends models.Id {
  wan1: models.MerakiManagementInterface;
  wan2: models.MerakiManagementInterface;
}
/**
 * Cuerpo del `request` PUT `/sites/:id/staticRoutes`.
 */
export interface SitesUpdateStaticRoutesPayload extends models.Id {
  staticRoutes: models.MerakiStaticRoute[]
}
/**
 * Cuerpo del `request` PUT `/sites/:id/vlansSettings`.
 */
export interface SitesUpdateVLANsSettingsPayload extends models.Id {
  vlansEnabled: boolean;
}
/**
 * Cuerpo del `request` PUT `/sites/:id/singleLan`.
 */
export interface SitesUpdateSingleLanPayload extends models.Id {
  applianceIp: string;
  subnet: string;
}
/**
 * Cuerpo del `request` PUT `/sites/:id/vlans`.
 */
export interface SitesUpdateVLANsPayload extends models.Id {
  vlans: models.MerakiVLAN[];
}
/**
 * Cuerpo del `request` PUT `/sites/:id/ports`.
 */
export interface SitesUpdatePortPayload extends models.Id, MerakiPort {}

/**
 * Cuerpo del `request` PUT `/sites/:id/filters`.
 */
 export interface SitesUpdateFiltersPayload extends models.Id {
  filters: MerakiFilter;
}
/**
 * Cuerpo del `request` PUT `/sites/:id/snmpEnabled`.
 */
export interface SitesUpdateSnmpEnabledPayload extends models.Id {
  snmpEnabled: boolean;
}
/**
 * CONSTANTS
 */
const ACTIVITY     = "@@ACTIVITY"
const INACTIVITY   = "@@INACTIVITY"
const LOGOUT       = "@@LOGOUT"
const REFRESH      = "@@REFRESH"
const ONE_MINUTE   = 1000 * 60
const FIVE_MINUTES = ONE_MINUTE * 5
/**
 * ACTIONS
 */
// CLIENTS
export const clientsList         = createAction<undefined>("clients/list/REQUEST")
export const clientsCreate       = createAction<Partial<models.IClient> & { isNew: boolean; }>("clients/create/REQUEST")
export const clientsRead         = createAction<models.Id>("clients/read/REQUEST")
export const clientsUpdate       = createAction<Partial<models.IClient> & {isNew: boolean}>("clients/update/REQUEST")
export const clientsDestroy      = createAction<models.Id>("clients/destroy/REQUEST")
export const clientsListUsers    = createAction<models.Id>("clients/listUsers/REQUEST")
export const clientsUpdateUsers  = createAction<ClientsUpdateUsersPayload>("clients/updateUsers/REQUEST")
export const clientsListNetworks = createAction<models.Id>("clients/listNetworks/REQUEST")
// NETWORKS
export const networksList      = createAction<undefined>("networks/list/REQUEST")
export const networksCreate    = createAction<Partial<models.INetwork> & {isNew: boolean;}>("networks/create/REQUEST")
export const networksRead      = createAction<models.Id>("networks/read/REQUEST")
export const networksUpdate    = createAction<models.INetwork>("networks/update/REQUEST")
export const networksDestroy   = createAction<models.Id>("networks/destroy/REQUEST")
export const networksListSites = createAction<models.Id>("networks/listSites/REQUEST")
// SITES
export const sitesList                          = createAction<undefined>("sites/list/REQUEST")
export const sitesCreate                        = createAction<Partial<models.ISite> & {isNew: boolean}>("sites/create/REQUEST")
export const sitesRead                          = createAction<models.Id>("sites/read/REQUEST")
export const sitesUpdate                        = createAction<models.ISite>("sites/update/REQUEST")
export const sitesDestroy                       = createAction<models.Id>("sites/destroy/REQUEST")
export const sitesReadManagementInterfaces      = createAction<models.Id>("sites/readManagementInterfaces/REQUEST")
export const sitesReadManagementInterfacesUsage = createAction<models.Id>("sites/readManagementInterfacesUsage/REQUEST")
export const sitesUpdateManagementInterfaces    = createAction<SitesUpdateManagementInterfacesPayload>("sites/updateManagementInterfaces/REQUEST")
export const sitesListStaticRoutes              = createAction<models.Id>("sites/listStaticRoutes/REQUEST")
export const sitesUpdateStaticRoutes            = createAction<SitesUpdateStaticRoutesPayload>("sites/updateStaticRoutes/REQUEST")
export const sitesReadVLANsSettings             = createAction<models.Id>("sites/readVLANsSettings/REQUEST")
export const sitesUpdateVLANsSettings           = createAction<SitesUpdateVLANsSettingsPayload>("sites/updateVLANsSettings/REQUEST")
export const sitesReadSingleLan                 = createAction<models.Id>("sites/readSingleLan/REQUEST")
export const sitesUpdateSingleLan               = createAction<SitesUpdateSingleLanPayload>("sites/updateSingleLan/REQUEST")
export const sitesListVLANs                     = createAction<models.Id>("sites/listVLANs/REQUEST")
export const sitesUpdateVLANs                   = createAction<SitesUpdateVLANsPayload>("sites/updateVLANs/REQUEST")
export const sitesListPorts                     = createAction<models.Id>("sites/listPorts/REQUEST")
export const sitesUpdatePort                    = createAction<SitesUpdatePortPayload>("sites/updatePort/REQUEST")
export const sitesListFilters                   = createAction<models.Id>("sites/listFilters/REQUEST")
export const sitesUpdateFilters                 = createAction<SitesUpdateFiltersPayload>("sites/updateFilters/REQUEST")

export const sitesUpdateSnmpEnabled             = createAction<SitesUpdateSnmpEnabledPayload>("sites/updateSnmpEnabled/REQUEST")
// USERS
export const usersList    = createAction<undefined>("users/list/REQUEST")
export const usersCreate  = createAction<Partial<models.IUser> & { isNew: boolean; }>("users/create/REQUEST")
export const usersRead    = createAction<models.Id>("users/read/REQUEST")
export const usersMe      = createAction<undefined>("users/me/REQUEST")
export const usersUpdate  = createAction<models.IUser>("users/update/REQUEST")
export const usersDestroy = createAction<models.Id>("users/destroy/REQUEST")
/**
 * EPICS
 */
export const epic = combineEpics(
  // REFRESH TOKEN
  inactivityEpic,
  // CLIENTS
  createRequestEpic({type: "clients/list",                        method: "GET",    url: "/api/clients"}),
  createRequestEpic({type: "clients/create",                      method: "POST",   url: "/api/clients"}),
  createRequestEpic({type: "clients/read",                        method: "GET",    url: "/api/clients/<%= id %>"}),
  createRequestEpic({type: "clients/update",                      method: "PUT",    url: "/api/clients/<%= id %>"}),
  createRequestEpic({type: "clients/destroy",                     method: "DELETE", url: "/api/clients/<%= id %>"}),
  createRequestEpic({type: "clients/listUsers",                   method: "GET",    url: "/api/clients/<%= id %>/users"}),
  createRequestEpic({type: "clients/updateUsers",                 method: "PUT",    url: "/api/clients/<%= id %>/users"}),
  createRequestEpic({type: "clients/listNetworks",                method: "GET",    url: "/api/clients/<%= id %>/networks"}),
  // NETWORKS
  createRequestEpic({type: "networks/list",                       method: "GET",    url: "/api/networks"}),
  createRequestEpic({type: "networks/create",                     method: "POST",   url: "/api/networks"}),
  createRequestEpic({type: "networks/read",                       method: "GET",    url: "/api/networks/<%= id %>"}),
  createRequestEpic({type: "networks/update",                     method: "PUT",    url: "/api/networks/<%= id %>"}),
  createRequestEpic({type: "networks/destroy",                    method: "DELETE", url: "/api/networks/<%= id %>"}),
  createRequestEpic({type: "networks/listSites",                  method: "GET",    url: "/api/networks/<%= id %>/sites"}),
  
  // SITES
  createRequestEpic({type: "sites/list",                          method: "GET",    url: "/api/sites"}),
  createRequestEpic({type: "sites/create",                        method: "POST",   url: "/api/sites"}),
  createRequestEpic({type: "sites/read",                          method: "GET",    url: "/api/sites/<%= id %>"}),
  createRequestEpic({type: "sites/update",                        method: "PUT",    url: "/api/sites/<%= id %>"}),
  createRequestEpic({type: "sites/destroy",                       method: "DELETE", url: "/api/sites/<%= id %>"}),
  createRequestEpic({type: "sites/readManagementInterfaces",      method: "GET",    url: "/api/sites/<%= id %>/managementInterfaces"}),
  createRequestEpic({type: "sites/readManagementInterfacesUsage", method: "GET",    url: "/api/sites/<%= id %>/managementInterfaces/usage"}),
  createRequestEpic({type: "sites/updateManagementInterfaces",    method: "PUT",    url: "/api/sites/<%= id %>/managementInterfaces"}),
  createRequestEpic({type: "sites/listStaticRoutes",              method: "GET",    url: "/api/sites/<%= id %>/staticRoutes"}),
  createRequestEpic({type: "sites/updateStaticRoutes",            method: "PUT",    url: "/api/sites/<%= id %>/staticRoutes"}),
  createRequestEpic({type: "sites/readVLANsSettings",             method: "GET",    url: "/api/sites/<%= id %>/vlansSettings"}),
  createRequestEpic({type: "sites/updateVLANsSettings",           method: "PUT",    url: "/api/sites/<%= id %>/vlansSettings"}),
  createRequestEpic({type: "sites/readSingleLan",                 method: "GET",    url: "/api/sites/<%= id %>/singleLan"}),
  createRequestEpic({type: "sites/updateSingleLan",               method: "PUT",    url: "/api/sites/<%= id %>/singleLan"}),
  createRequestEpic({type: "sites/listVLANs",                     method: "GET",    url: "/api/sites/<%= id %>/vlans"}),
  createRequestEpic({type: "sites/updateVLANs",                   method: "PUT",    url: "/api/sites/<%= id %>/vlans"}),
  createRequestEpic({type: "sites/listPorts",                     method: "GET",    url: "/api/sites/<%= id %>/ports"}),
  createRequestEpic({type: "sites/updatePort",                    method: "PUT",    url: "/api/sites/<%= id %>/ports/<%= number %>"}),
  createRequestEpic({type: "sites/updateSnmpEnabled",             method: "PUT",    url: "/api/sites/<%= id %>/snmpEnabled"}),
  createRequestEpic({type: "sites/updateFilters",                 method: "PUT",    url: "/api/sites/<%= id %>/filters"}),
  createRequestEpic({type: "sites/listFilters",                   method: "GET",    url: "/api/sites/<%= id %>/filters"}),
  

  // USERS
  createRequestEpic({type: "users/list",                          method: "GET",    url: "/api/users"}),
  createRequestEpic({type: "users/create",                        method: "POST",   url: "/api/users"}),
  createRequestEpic({type: "users/read",                          method: "GET",    url: "/api/users/<%= id %>"}),
  createRequestEpic({type: "users/me",                            method: "GET",    url: "/api/users/me"}),
  createRequestEpic({type: "users/update",                        method: "PUT",    url: "/api/users/<%= id %>"}),
  createRequestEpic({type: "users/destroy",                       method: "DELETE", url: "/api/users/<%= id %>"}),
)
/**
 * FUNCTIONS
 */
export interface RequestEpicCreatorConfig<T = any, V = any> {
  /**
   * Redux Action Type a utilizar como prefijo para el resto de
   * los mensajes.
   */
  type: string;
  /**
   * Método del `ajax request` a utilizar.
   */
  method: "GET" | "POST" | "PUT" | "DELETE";
  /**
   * Url del `ajax request`. Soporta la configuración de `templates`
   * compatibles con la función `templates` de `lodash`, la cual se
   * llamara con el `payload` de menaje como argumento de opciones.
   * Por ejemplo, si el valor es `"/api/networks/<%= id %>/sites"`
   * y en el `payload` del request el valor de `id` es igual a `123`,
   * a la hora de hacer el request el string sera sustituido por:
   * `"/api/networks/123/sites"`.
   */
  url: string;
  parse?: (response: T) => V
}
/**
 * Crea una epica capaz de realizar consultas a la API, gestionando
 * el ciclo de vida de los `requests`.
 *
 * @example
 *
 * ```javascript
 * const usersRequestEpic = requestEpicCreator<IUser, IUser>({
 *   type: "@networks/listSites",
 *   method: "GET",
 *   url: "/api/networks/<%= id %>/sites",
 *   parse: (response: IUser[]) => {
 *     // Se puede utilizar esta función para modificar la respuesta
 *     // Por defecto se devuelve la respuesta como es devuelta por
 *     // el servidor.
 *     return response
 *   }
 * })
 * ```
 */
export function createRequestEpic(config: RequestEpicCreatorConfig) {
  return (action$: Observable<MetaPayloadAction>): Observable<MetaPayloadAction> => (
    action$.pipe(
      ofType(`${config.type}/REQUEST`),
      mergeMap(({payload}) => {
        const sent$ = createSentObservable(config.type, payload)
        const blocker$ = createBlockerObservable(config.type, payload, action$)
        const ajax$ = createAjaxObservable(config, payload)
        return concat<MetaPayloadAction>(sent$, race(ajax$, blocker$))
      })
    )
  )
}
/**
 * Detecta inactividad por parte del usuario y cierra su sesión si
 * transcurren más de 10 minutos sin recibir ningún evento.
 */
export function inactivityEpic(action$: Observable<AnyAction>): Observable<AnyAction> {
  return action$.pipe(
    filter(({type}) => type !== LOGOUT && type !== INACTIVITY && type !== REFRESH),
    switchMap(() => createTimerObservable(FIVE_MINUTES)),
    map((action) => {
      if (action.type === LOGOUT) {
        logout()
      }
      return action
    })
  )
}
/**
 * Devuelve un Observable que manda dos mensajes separados
 * `time` tiempo que pueden utilizarse para medir la inactividad
 * de un usuario.
 * @param time - Tiempo entre acciones.
 */
function createTimerObservable(time: number) {
  return timer(time, time).pipe(
    map((number) => {
      return number < 5 ? {type: INACTIVITY} : {type: LOGOUT}
    }),
  )
}
/**
 * Verífica que la acción es de tipo `REQUEST`.
 */
function isRequestAction(action: AnyAction): action is MetaPayloadAction {
  return action.type.endsWith('REQUEST')
}
/**
 * Crea un Observable con una Redux Action que indica que el `request``
 * se envío.
 *
 * @param type - Tipo del Redux Action.
 * @param meta - Información adicional para incluir en la Acción.
 * @return - Un `Observable` que envía el
 * `sent` Redux Acton.
 */
export function createSentObservable(type: string, meta: any): Observable<MetaPayloadAction> {
  return of({
    type: `${type}/SENT`,
    payload: undefined,
    meta,
  })
}
/**
 * Crea un Observable con una Redux Action que indica que el `request``
 * se cancelo.
 *
 * @param type - Tipo del Redux Action.
 * @param meta - Información adicional para incluir en la Acción.
 * @param action$ - Stream de Redux Actions.
 * @return - Un `Observable` que envía el
 * `canceled` Redux Acton.
 */
const READ_OR_LIST_REGEXP = /list|read/gi
const HISTORY_TYPE = "@history"
export function createBlockerObservable(
  type: string,
  meta: any,
  action$: Observable<MetaPayloadAction>
): Observable<MetaPayloadAction> {
  return action$.pipe(
    filter((action) => action.type === `${type}/CANCEL` || (!!type.match(READ_OR_LIST_REGEXP) && action.type === HISTORY_TYPE)),
    take(1),
    mapTo({
      type: `${type}/CANCELED`,
      payload: undefined,
      meta,
    })
  )
}
/**
 * Crea un Observable que realiza un `request` a la URL indicada y
 * gestiona el éxito o la falla de la misma.
 *
 * @param config - Objeto de configuración del `RequestObservable`.
 * @param payload - Cuerpo del `request`.
 * @return - Un `Observable` que envía el `request` y luego los Redux
 * Actions correspondientes al éxito o la falla del `request`.
 */
export function createAjaxObservable(config: RequestEpicCreatorConfig, payload: any): Observable<MetaPayloadAction> {
  const ajaxConfig: AjaxRequest = {
    url: template(config.url)(payload),
    method: config.method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${window.k.token}`,
    },
  }
  if (config.method === "POST" || config.method === "PUT") {
    ajaxConfig.body = payload
  }
  return ajax(ajaxConfig).pipe(
    map(({response}) => ({
      type: `${config.type}/SUCCESS`,
      payload: isFunction(config.parse) ? config.parse(response) : response,
      meta: payload,
    })),
    catchError((err) => of({
      type: `${config.type}/FAILURE`,
      payload: err.response ? err.response : err,
      meta: payload
    }))
  )
}
/**
 * Subscribe un `Observable` que refresca los `tokens` del usuario
 * y detecta su actividad para evitar que se cierre su sesión si
 * simplemente se encuentra mirando la pantalla. Siempre y cuando
 * se encuentre moviendo el mouse o usando el teclado.
 * @param store - El `store` de Redux.
 * @param time - El tiempo por el cual cada tanto se actualizan los `tokens` del usuario.
 */
export function createRefreshTokenObservableSubscription(store: {dispatch: (action: AnyAction) => any}, time: number) {
  const refreshToken$       = createRefreshTokenObservable(time)
  const activityObservable$ = createActivityObservable(time)

  merge(refreshToken$, activityObservable$).subscribe(
    (action) => store.dispatch(action),
    (err)    => console.error(err),
    ()       => console.info("Refresh Token Observable - Done")
  )
}
/**
 * Crea un `Observable` que actualiza los `tokens` del usuario
 * cada `time` tiempo.
 * @param time - Tiempo entre actualizaciones de los `tokens`.
 */
function createRefreshTokenObservable(time: number) {
  return interval(time).pipe(
    switchMap(() => from(updateToken(time))),
    mapTo({type: REFRESH}),
  )
}
/**
 * Crea un `Observable` que emite una acción cada `time` tiempo,
 * siempre y cuando el usuario haya apretado una tecla o movido
 * el mouse dentro de un período de `time` tiempo.
 * @param time - Tiempo para muestrear eventos.
 */
function createActivityObservable(time: number) {
  const eachTime$ = interval(time)
  const mouseMove$ = fromEvent<MouseEvent>(document, "mousemove")
  const keyPress$ = fromEvent<KeyboardEvent>(document, "keydown")
  return merge(mouseMove$, keyPress$).pipe(
    sample(eachTime$),
    mapTo({type: ACTIVITY})
  )
}