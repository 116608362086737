import React from "react"
import {
  Table as PatternflyTable,
  TableVariant,
  TableHeader,
  TableBody,
  IActions,
  textCenter,
} from "@patternfly/react-table"
import {Badge} from "@patternfly/react-core"
import get from "lodash/get"

import type {MerakiFilter, FilterRules, FilterValue} from "@models"
import {EmptyState} from "@components/EmptyState"
import {defaultRowKey} from "@components/Table"
import { hidden } from "@utils/general/hidden"
import { AutoprefixerIcon } from "@patternfly/react-icons"


export const COLUMNS = [
  {key: "policy", title: "Regla", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "type", title: "Tipo", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "value", title: "Aplicación", transforms: [textCenter], cellTransforms: [textCenter]},
  {key: "id", title: "id", transforms: [hidden], cellTransforms: [hidden]},
]

export interface RemotosFiltersTableProps {
  rules:FilterRules[];
  onDelete?: (id: string) => void;
}

export const RemotosFiltrosTable: React.FC<RemotosFiltersTableProps> = ({onDelete, rules}) => {
  const actions: IActions = []

  if (onDelete !== undefined) {
    
    actions.push({title: "Eliminar", onClick: (_, __, rowData) => onDelete(rowData["id"].title)})
  }
  
  return (
    <>
      <PatternflyTable
        aria-label="table"
        cells={COLUMNS}
        rows={transformRows(rules)}
        variant={TableVariant.compact}
        {...(actions.length > 0 && { actions })}
      >
        <TableHeader />
        <TableBody
          rowKey={defaultRowKey}
        />
      </PatternflyTable>
      {rules.length === 0 && <EmptyState title="Tabla vacía" body="No existen Filtros de contenido configurados en este Remoto"/>}
    </>
  )

  function transformRows(rules:FilterRules[]): {cells: any}[] {
    if (rules && rules.length > 0)
    {
        //console.log(rules);
        return rules.map((item: FilterRules) => 
        
        ({ cells: COLUMNS.map((column) => {
        
            const value = get(item, column.key)
            const aux = item.value as FilterValue;

            if (column.key === "policy") {
              return { title: item.policy }
            }
          
            if (column.key === "type") {
              return { title: item.type }
            }

            if (column.key === "value") {
              return { title: aux.name }
            }

            if(column.key === "id"){
              return { title: aux.id }
            }

            return value

            })
        }))
    }
    else
    {
      return [];
    }   
    
    
  }
}
