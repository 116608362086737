import React from "react"
import {useParams} from "react-router-dom"
import {useDispatch} from "react-redux"

import {useFlag} from "@hooks/useFlag"
import {ISite} from "@models"
import {Loader} from "@components/Loader"
import {RemotosForm} from "@pages/Remotos/RemotosForm"
import {useNetwork} from "@hooks/useNetwork"
import {networksRead, sitesCreate} from "@redux/api"

export const RedNuevoRemoto: React.FC<{}> = () => {
  const dispatch         = useDispatch()
  const {id}             = useParams<{id: string}>()
  const networksReadFlag = useFlag("networksRead")
  const sitesCreateFlag  = useFlag("sitesCreate")
  const network          = useNetwork(id)

  React.useEffect(() => {
    if (network === undefined) {
      dispatch(networksRead({id}))
    }
  }, [])

  if (networksReadFlag) return <Loader content="Obteniendo información de la Red..." />

  return <RemotosForm
    networkId={id}
    isNew={true}
    onCreate={handleCreate}
    isLoading={sitesCreateFlag}
  />
  /**
   * Envía el `request` para crear el `site`.
   * @param body - Cuerpo del `request`.
   */
  function handleCreate(body: Partial<ISite> & {isNew: boolean}) {
    dispatch(sitesCreate(body))
  }
}