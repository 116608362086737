import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button
} from '@patternfly/react-core';
import get from "lodash/get"

import history from "@history"
import {INetwork} from "@models"

export interface RedData extends INetwork {
  isNew: boolean;
}

interface RedesFormProps {
  clientId?: string;
  data?: INetwork;
  onCreate?: (data: RedData) => void;
  onEdit?: (data: RedData) => void;
  isLoading?: boolean;
  isNew?: boolean;
}

export const RedesForm: React.FC<RedesFormProps> = ({data, clientId, isNew, isLoading, onCreate, onEdit}) => {
  return (
    <Form isHorizontal style={{padding: "1rem"}} onSubmit={handleSubmit}>
      <FormGroup label="ID del Red" isRequired fieldId="id" helperText="ID del contrato de ANTEL">
        <TextInput defaultValue={get(data, "id", "")} isRequired type="text" id="id" aria-describedby="id-helper" name="id" isDisabled={isNew === false || isLoading}/>
      </FormGroup>

      <FormGroup label="ID del Cliente" isRequired fieldId="clientId" helperText="ID del cliente en ANTEL">
        <TextInput defaultValue={clientId || get(data, "clientId", "")} isRequired type="text" id="clientId" aria-describedby="clientId-helper" name="clientId" isDisabled={!!clientId || isNew === false || isLoading}/>
      </FormGroup>

      <ActionGroup>
        <Button isLoading={isLoading} variant={isNew ? "primary" : "warning"} type="submit" isDisabled={isLoading}>Aceptar</Button>
        <Button variant="link" onClick={handleCancel} isDisabled={isLoading}>Cancelar</Button>
      </ActionGroup>
    </Form>
  )

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      id: {value: string};
      clientId: {value: string};
    }
    const id = target.id.value
    const clientId = target.clientId.value
    isNew === true
      ? onCreate && onCreate({id, clientId, isNew: true})
      : onEdit && onEdit({id, clientId, isNew: false})
  }

  function handleCancel(e: React.SyntheticEvent) {
    e.preventDefault()
    history.go(-1)
  }
}